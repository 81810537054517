define("boekdrukken/components/ui-block/sketch/inspector/section/size-warning/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':size-warning', 'showSizeWarning::hidden'],
    size: Ember.computed.readOnly('model.originalSize'),
    showSizeWarning: Ember.computed.readOnly('model.showSizeWarning'),
    actions: {
      ignore: function ignore() {
        this.update('sizeWarningDismissed', true);
      }
    }
  });

  _exports.default = _default;
});