define("boekdrukken/components/ui-block/product/book/step/cover/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-product-book-step-cover'],
    step: null,
    product: Ember.computed.readOnly('step.product'),
    cover: Ember.computed.readOnly('step.component'),
    subtype: Ember.computed.readOnly('cover.subtype'),
    isDesign: Ember.computed.equal('subtype', 'design'),
    isUpload: Ember.computed.equal('subtype', 'upload')
  });

  _exports.default = _default;
});