define("boekdrukken/components/ui-route/users/user/orders/order/index/component", ["exports", "boekdrukken/util/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var status = function status(value) {
    return Ember.computed.equal('status', value);
  };

  var order = function order(key) {
    return Ember.computed.readOnly("model.order.data.".concat(key));
  };

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-route-users-user-orders-order-index'],
    router: Ember.inject.service(),
    model: null,
    support: (0, _computed.config)(function (_ref) {
      var support = _ref.external.support;
      return support;
    }),
    status: order('status'),
    email: order('address.email'),
    delivery: order('pricing.delivery.date.dateTime'),
    identifier: order('identifier'),
    isPending: status('pending'),
    isSuccess: status('payment-success'),
    isFailed: status('payment-failed'),
    isError: status('error'),
    actions: {
      cart: function cart() {
        this.router.transitionTo('users.user.cart');
      },
      index: function index() {
        this.router.transitionTo('users.user.products');
      }
    }
  });

  _exports.default = _default;
});