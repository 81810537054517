define("boekdrukken/components/ui-block/sketch/inspector/section/size-warning/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Dliwydux",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showSizeWarning\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"warning\"],[8],[0,\"\\n    \"],[1,[28,\"ui-block/icon\",[\"exclamation-triangle\"],[[\"class\"],[\"icon\"]]],false],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[1,[28,\"t\",[\"block.sketch.inspector.section.size-warning.title\"],null],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"block.sketch.inspector.section.size-warning.description.main\"],[[\"size\",\"htmlSafe\"],[[28,\"t\",[\"block.sketch.inspector.section.size-warning.description.size\"],[[\"width\",\"height\",\"htmlSafe\"],[[24,[\"size\",\"width\"]],[24,[\"size\",\"height\"]],true]]],true]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n      \"],[1,[28,\"ui-input/button\",null,[[\"type\",\"color\",\"value\",\"size\",\"class\",\"action\"],[\"outline\",\"warning\",[28,\"t\",[\"block.sketch.inspector.section.size-warning.ignore\"],null],\"small\",\"button\",[28,\"action\",[[23,0,[]],\"ignore\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/inspector/section/size-warning/template.hbs"
    }
  });

  _exports.default = _default;
});