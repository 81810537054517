define("boekdrukken/components/ui-application/header/navigation/breadcrumb/admin/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-application-header-navigation-breadcrumb-admin'],
    item: null,
    email: Ember.computed.readOnly('item.component.opts.user.email')
  });

  _exports.default = _default;
});