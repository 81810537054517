define("boekdrukken/components/ui-route/backend/subscriptions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NGyncJ0Q",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ui-block/container\",null,[[\"type\",\"class\"],[\"margin\",\"content\"]],{\"statements\":[[0,\"\\n\"],[4,\"ui-block/card\",null,[[\"type\",\"class\"],[\"minimal\",\"card header\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"placeholder\"],[8],[1,[28,\"t\",[\"route.backend.subscriptions.loading\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"error\"],[8],[1,[24,[\"error\",\"message\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"response\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"ui-input/button\",null,[[\"value\",\"action\"],[[28,\"t\",[\"route.backend.subscriptions.download\"],null],[28,\"action\",[[23,0,[]],\"download\"],null]]]],false],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"response\"]]],null,{\"statements\":[[4,\"ui-block/card\",null,[[\"type\",\"class\"],[\"minimal\",\"card csv\"]],{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"string\"],[8],[1,[24,[\"response\",\"data\",\"csv\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-route/backend/subscriptions/template.hbs"
    }
  });

  _exports.default = _default;
});