define("boekdrukken/components/ui-application/header/navigation/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-application-header-navigation'],
    breadcrumbs: Ember.inject.service(),
    items: Ember.computed.readOnly('breadcrumbs.items'),
    actions: {
      select: function select(item) {
        item.action && item.action();
      }
    }
  });

  _exports.default = _default;
});