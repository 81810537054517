define("boekdrukken/components/ui-route/stylebook/dropdown/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-route-stylebook-page', ':ui-route-stylebook-dropdown'],
    items: Ember.computed(function () {
      return [{
        id: 'one',
        title: 'One'
      }, {
        id: 'two',
        title: 'Two'
      }, {
        id: 'three',
        title: 'Three'
      }];
    }).readOnly(),
    manyItems: Ember.computed(function () {
      var arr = [];

      for (var i = 0; i < 100; i++) {
        arr.push({
          id: "".concat(i),
          title: "#".concat(i)
        });
      }

      return arr;
    }).readOnly(),
    pairs: Ember.computed(function () {
      return [{
        id: 'one',
        title: 'One',
        description: 'This is one'
      }, {
        id: 'two',
        title: 'Two',
        description: 'This is two'
      }, {
        id: 'three',
        title: 'Three',
        description: 'This is three'
      }];
    }).readOnly(),
    tools: Ember.computed(function () {
      return [{
        id: 'default',
        title: 'More…',
        hidden: true,
        icon: 'coffee'
      }, {
        id: 'delete',
        title: 'Delete book',
        icon: 'television'
      }, {
        id: 'export',
        title: 'Export book',
        icon: 'commenting'
      }];
    }).readOnly(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var select = function select(arr, prop) {
        var selected = _this[arr].firstObject;

        _this.setProperties(_defineProperty({}, prop, selected));
      };

      select('items', 'selectedItem');
      select('tools', 'selectedTool');
      select('manyItems', 'selectedManyItem');
      select('pairs', 'selectedPair');
    },
    actions: {
      select: function select(key, value) {
        if (key === 'selectedTool') {
          return;
        }

        this.setProperties(_defineProperty({}, key, value));
      }
    }
  });

  _exports.default = _default;
});