define("boekdrukken/components/ui-block/sketch/inspector/selection/model/-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-sketch-inspector-selection-model', 'model.type'],
    sketch: null,
    model: null
  });

  _exports.default = _default;
});