define("boekdrukken/models/users/user/cart/-order", ["exports", "ember-cli-zuglet/lifecycle"], function (_exports, _lifecycle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    order: (0, _lifecycle.model)().named('users/user/cart/order').mapping(function (cart) {
      return {
        cart: cart
      };
    }),
    isCommitingOrder: Ember.computed.readOnly('order.isCommiting'),
    checkout: function checkout() {
      return this.order.checkout();
    }
  });

  _exports.default = _default;
});