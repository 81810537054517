define("boekdrukken/models/analytics/events/standalone", ["exports", "boekdrukken/models/analytics/events/-base", "boekdrukken/models/analytics/events"], function (_exports, _base, _events) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    category: 'standalone',
    calculator: (0, _events.events)('standalone/calculator'),
    download: (0, _events.events)('standalone/download')
  });

  _exports.default = _default;
});