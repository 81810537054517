define("boekdrukken/components/ui-block/cart/placeholder/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vTLzFAUf",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ui-block/container\",null,[[\"type\",\"class\"],[\"centered\",\"content\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"heading\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"block.cart.placeholder.title\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n    \"],[1,[28,\"ui-input/button\",null,[[\"class\",\"size\",\"value\",\"action\"],[\"action\",\"small\",[28,\"t\",[\"block.cart.placeholder.actions.new\"],null],[28,\"action\",[[23,0,[]],\"new\"],null]]]],false],[0,\"\\n    \"],[1,[28,\"ui-input/button\",null,[[\"class\",\"size\",\"value\",\"action\"],[\"action\",\"small\",[28,\"t\",[\"block.cart.placeholder.actions.products\"],null],[28,\"action\",[[23,0,[]],\"products\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/cart/placeholder/template.hbs"
    }
  });

  _exports.default = _default;
});