define("boekdrukken/util/computed", ["exports", "boekdrukken/util/string", "boekdrukken/util/paper-types", "boekdrukken/util/currency", "boekdrukken/util/is-development", "boekdrukken/util/round"], function (_exports, _string, _paperTypes, _currency, _isDevelopment, _round) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unitlessDimensionsString = _exports.trimboxString = _exports.trimbox = _exports.serialized = _exports.pluralize = _exports.notBlank = _exports.mapping = _exports.isTrue = _exports.isFastBoot = _exports.isDevelopmentEnvironment = _exports.isDevelopmentBuild = _exports.expiringStatus = _exports.equal = _exports.dimensionsString = _exports.currency = _exports.config = _exports.capitalize = _exports.arrayUnion = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var serialized = function serialized(keys) {
    var _Ember;

    return (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(keys).concat([function () {
      return this.getProperties.apply(this, _toConsumableArray(keys));
    }])).readOnly();
  };

  _exports.serialized = serialized;

  var mapping = function mapping(key, hash) {
    return Ember.computed(key, function () {
      var value = this.get(key);
      var result;

      if (value) {
        result = Ember.get(hash, value);
      }

      if (!result) {
        result = Ember.get(hash, 'default');
      }

      return result;
    }).readOnly();
  };

  _exports.mapping = mapping;

  var _dimensionsString = function _dimensionsString(key, unit, unitless, includePaperType) {
    var render = function render(owner, key) {
      var dimensions = owner.get(key);

      if (!dimensions) {
        return;
      }

      var width = Ember.get(dimensions, 'width');
      var height = Ember.get(dimensions, 'height');
      var base = (0, _string.dimensions)(width, height, unit);
      var paper;

      if (includePaperType) {
        paper = (0, _paperTypes.paperType)(width, height, unit);
      }

      if (paper) {
        return "".concat(paper, " (").concat(base, ")");
      }

      return base;
    };

    if (!unitless) {
      return Ember.computed("".concat(key, ".").concat(unit, ".{width,height}"), function () {
        return render(this, "".concat(key, ".").concat(unit));
      }).readOnly();
    } else {
      return Ember.computed("".concat(key), function () {
        return render(this, key);
      }).readOnly();
    }
  };

  var unitlessDimensionsString = function unitlessDimensionsString(key, unit, includePaperType) {
    return _dimensionsString(key, unit, true, includePaperType);
  };

  _exports.unitlessDimensionsString = unitlessDimensionsString;

  var dimensionsString = function dimensionsString(key, unit, includePaperType) {
    return _dimensionsString(key, unit, false, includePaperType);
  };

  _exports.dimensionsString = dimensionsString;

  var capitalize = function capitalize(key) {
    return Ember.computed(key, function () {
      var value = Ember.get(this, key);

      if (!value) {
        return;
      }

      return Ember.String.capitalize(value);
    }).readOnly();
  };

  _exports.capitalize = capitalize;

  var pluralize = function pluralize(key, singular, plural) {
    return Ember.computed(key, function () {
      var value = this.get(key);
      return (0, _string.pluralize)(value, singular, plural);
    }).readOnly();
  };

  _exports.pluralize = pluralize;

  var getConfig = function getConfig(owner) {
    return Ember.getOwner(owner).factoryFor('config:environment').class;
  };

  var config = function config(fn) {
    return Ember.computed(function () {
      var _getConfig = getConfig(this),
          boekdrukken = _getConfig.boekdrukken;

      return fn.call(this, boekdrukken);
    }).readOnly();
  };

  _exports.config = config;

  var isDevelopmentBuild = function isDevelopmentBuild() {
    return Ember.computed(function () {
      var env = getConfig(this);
      return (0, _isDevelopment.default)(env);
    }).readOnly();
  };

  _exports.isDevelopmentBuild = isDevelopmentBuild;

  var isDevelopmentEnvironment = function isDevelopmentEnvironment() {
    return Ember.computed(function () {
      var env = getConfig(this);
      return env.environment === 'development';
    }).readOnly();
  };

  _exports.isDevelopmentEnvironment = isDevelopmentEnvironment;

  var equal = function equal() {
    var _Ember2;

    for (var _len = arguments.length, keys = new Array(_len), _key = 0; _key < _len; _key++) {
      keys[_key] = arguments[_key];
    }

    return (_Ember2 = Ember).computed.apply(_Ember2, keys.concat([function () {
      var _this = this;

      var values = keys.map(function (key) {
        return _this.get(key);
      });
      return values.every(function (value) {
        return values[0] === value;
      });
    }])).readOnly();
  };

  _exports.equal = equal;

  var currency = function currency(key) {
    return Ember.computed(key, function () {
      var value = this.get(key);

      if (!value) {
        return;
      }

      return (0, _currency.default)(value);
    }).readOnly();
  };

  _exports.currency = currency;

  var arrayUnion = function arrayUnion(rootKey, secondaryKey) {
    return Ember.computed("".concat(rootKey, ".@each.").concat(secondaryKey), function () {
      var root = this.get(rootKey);

      if (!root) {
        return;
      }

      return root.reduce(function (array, item) {
        var value = item.get(secondaryKey);

        if (!value) {
          return;
        }

        array.addObjects(value);
        return array;
      }, Ember.A());
    }).readOnly();
  };

  _exports.arrayUnion = arrayUnion;

  var notBlank = function notBlank(key) {
    return Ember.computed(key, function () {
      var value = this.get(key);
      return (0, _string.notBlank)(value);
    }).readOnly();
  };

  _exports.notBlank = notBlank;
  var ExpiringStatus = Ember.Object.extend({
    opts: null,
    _value: Ember.computed.readOnly('opts.value'),
    timeout: Ember.computed.readOnly('opts.timeout'),
    isExpired: false,
    value: Ember.computed('_value', 'isExpired', function () {
      var _value = this._value,
          isExpired = this.isExpired;

      if (isExpired) {
        return;
      }

      return _value;
    }).readOnly(),
    init: function init() {
      this._super.apply(this, arguments);

      this.prepare();
    },
    prepare: function prepare() {
      var _this2 = this;

      var timeout = this.timeout;

      if (!timeout) {
        return;
      }

      Ember.run.later(function () {
        return _this2.set('isExpired', true);
      }, timeout);
    },
    toString: function toString() {
      return "<ExpiringStatus:".concat(this._value, ":").concat(this.timeout, ">");
    }
  });

  var expiringStatus = function expiringStatus() {
    var _Ember3;

    for (var _len2 = arguments.length, deps = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      deps[_key2] = arguments[_key2];
    }

    var fn = deps.pop();
    return (_Ember3 = Ember).computed.apply(_Ember3, deps.concat([function () {
      var opts = fn.call(this, this);
      return ExpiringStatus.create({
        opts: opts
      });
    }])).readOnly();
  };

  _exports.expiringStatus = expiringStatus;

  var isFastBoot = function isFastBoot() {
    return Ember.computed(function () {
      var service = Ember.getOwner(this).lookup('service:fastboot');
      return service && service.isFastBoot;
    }).readOnly();
  };

  _exports.isFastBoot = isFastBoot;

  var isTrue = function isTrue(key) {
    return Ember.computed(key, function () {
      return this.get(key) === true;
    }).readOnly();
  };

  _exports.isTrue = isTrue;

  var trimbox = function trimbox(boxesKey, unit, fn) {
    return Ember.computed(boxesKey, function () {
      var boxes = this.get(boxesKey);

      if (!boxes) {
        return;
      }

      var box = function box(key) {
        return Ember.get(boxes, "".concat(key, ".").concat(unit));
      };

      var media = box('media');
      var trim = box('trim');

      if (!media || !trim) {
        return;
      }

      var r = function r(value) {
        return (0, _round.default)(value, 2);
      };

      var top = r(trim.y - media.y);
      var right = r(media.width - trim.x - trim.width);
      var bottom = r(media.height - trim.y - trim.height);
      var left = r(trim.x - media.x);
      var uniform;

      if (top === right && right === bottom && bottom === left) {
        uniform = top;
      }

      var result = {
        top: top,
        right: right,
        bottom: bottom,
        left: left,
        uniform: uniform
      };

      if (fn) {
        result = fn.call(this, result);
      }

      return result;
    }).readOnly();
  };

  _exports.trimbox = trimbox;

  var trimboxString = function trimboxString(boxesKey, unit) {
    return trimbox(boxesKey, unit, function (result) {
      if (!result) {
        return;
      }

      var s = function s(key) {
        var value = result[key];

        if (value === undefined) {
          return;
        }

        return "".concat(result[key]).concat(unit);
      };

      return {
        top: s('top'),
        right: s('right'),
        bottom: s('bottom'),
        left: s('left'),
        uniform: s('uniform')
      };
    });
  };

  _exports.trimboxString = trimboxString;
});