define("boekdrukken/models/product/definition/custom", ["exports", "boekdrukken/models/product/definition/-definition", "design/flow"], function (_exports, _definition, _flow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var types = [{
    type: 'book'
  }, {
    type: 'poster'
  }, {
    type: 'sticker'
  }];

  var _default = _definition.default.extend({
    title: 'Custom',
    description: 'Create a custom product',
    flow: (0, _flow.flow)({
      summary: (0, _flow.step)({
        title: 'model.users.user.products.custom.flow.summary',
        enabled: false
      })
    }),
    types: types,
    type: types[0]
  });

  _exports.default = _default;
});