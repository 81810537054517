define("boekdrukken/components/ui-block/sketch/inspector/section/align/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y+uTZN8W",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ui-block/sketch/inspector/section/align/column\",null,[[\"label\",\"key\",\"update\",\"model\",\"choices\"],[\"block.sketch.inspector.section.align.horizontal.label\",\"align\",[24,[\"update\"]],[24,[\"model\"]],[24,[\"choices\",\"align\"]]]]],false],[0,\"\\n\\n\"],[1,[28,\"ui-block/sketch/inspector/section/align/column\",null,[[\"label\",\"key\",\"update\",\"model\",\"choices\"],[\"block.sketch.inspector.section.align.vertical.label\",\"verticalAlign\",[24,[\"update\"]],[24,[\"model\"]],[24,[\"choices\",\"vertical\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/inspector/section/align/template.hbs"
    }
  });

  _exports.default = _default;
});