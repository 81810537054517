define("boekdrukken/models/users/user/products/parameters/book/-parameter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.doc = _exports.default = _exports.changed = void 0;

  var doc = function doc(key) {
    return Ember.computed.readOnly("doc.data.".concat(key));
  };

  _exports.doc = doc;

  var changed = function changed(key) {
    var docKey = "doc.data.".concat(key);
    var pristineKey = "pristine.data.".concat(key);
    return Ember.computed(docKey, pristineKey, function () {
      var doc = this.get(docKey);
      var pristine = this.get(pristineKey);
      return doc !== pristine;
    }).readOnly();
  };

  _exports.changed = changed;

  var _default = Ember.Object.extend({
    parameters: null,
    pristine: Ember.computed.readOnly('parameters.pristine'),
    doc: Ember.computed.readOnly('parameters.doc'),
    lockable: false,
    locked: Ember.computed.readOnly('parameters.locked'),
    disabled: Ember.computed.and('lockable', 'locked'),
    unlock: function unlock() {
      this.parameters.unlock();
    }
  });

  _exports.default = _default;
});