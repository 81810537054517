define("boekdrukken/components/ui-route/error/component", ["exports", "boekdrukken/util/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-route-error'],
    details: (0, _computed.isDevelopmentBuild)(),
    stack: Ember.computed.and('model.stack', 'details')
  });

  _exports.default = _default;
});