define("boekdrukken/components/ui-application/header/navigation/breadcrumb/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kBvGgEjR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"item\",\"component\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[28,\"concat\",[\"ui-application/header/navigation/breadcrumb/\",[24,[\"item\",\"component\",\"name\"]]],null]],[[\"item\"],[[24,[\"item\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"item\",\"title\",\"nt\"]]],null,{\"statements\":[[0,\"    \"],[1,[24,[\"item\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"t\",[[28,\"concat\",[\"application.header.navigation.breadcrumb.label.\",[24,[\"item\",\"title\"]]],null]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-application/header/navigation/breadcrumb/template.hbs"
    }
  });

  _exports.default = _default;
});