define("boekdrukken/components/ui-block/sketch/inspector/selection/model/text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3e2Oi/US",
    "block": "{\"symbols\":[\"section\"],\"statements\":[[4,\"ui-block/sketch/inspector/section\",null,[[\"sketch\",\"model\"],[[24,[\"sketch\"]],[24,[\"model\"]]]],{\"statements\":[[0,\"\\n  \"],[1,[23,1,[\"header\"]],false],[0,\"\\n\\n  \"],[1,[23,1,[\"autosizing\"]],false],[0,\"\\n\\n  \"],[1,[23,1,[\"position\"]],false],[0,\"\\n  \"],[1,[23,1,[\"size\"]],false],[0,\"\\n\\n  \"],[1,[23,1,[\"text\"]],false],[0,\"\\n\\n  \"],[1,[23,1,[\"color\"]],false],[0,\"\\n  \"],[1,[23,1,[\"font\"]],false],[0,\"\\n  \"],[1,[23,1,[\"font-style\"]],false],[0,\"\\n  \"],[1,[23,1,[\"align\"]],false],[0,\"\\n  \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"opacity-rotation\"]],\"expected `section.opacity-rotation` to be a contextual component but found a string. Did you mean `(component section.opacity-rotation)`? ('boekdrukken/components/ui-block/sketch/inspector/selection/model/text/template.hbs' @ L16:C4) \"],null]],[[\"rotation\"],[15]]],false],[0,\"\\n\\n  \"],[1,[23,1,[\"move\"]],false],[0,\"\\n  \"],[1,[23,1,[\"remove\"]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/inspector/selection/model/text/template.hbs"
    }
  });

  _exports.default = _default;
});