define("boekdrukken/components/ui-block/sketch/inspector/section/position/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "46DSv0BA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[28,\"t\",[\"block.sketch.inspector.section.position.x\"],null],false],[9],[0,\"\\n  \"],[1,[28,\"ui-input/text\",null,[[\"size\",\"value\",\"update\"],[\"small\",[24,[\"model\",\"x\"]],[28,\"action\",[[23,0,[]],[24,[\"update\"]],\"x\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[28,\"t\",[\"block.sketch.inspector.section.position.y\"],null],false],[9],[0,\"\\n  \"],[1,[28,\"ui-input/text\",null,[[\"size\",\"value\",\"update\"],[\"small\",[24,[\"model\",\"y\"]],[28,\"action\",[[23,0,[]],[24,[\"update\"]],\"y\"],null]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/inspector/section/position/template.hbs"
    }
  });

  _exports.default = _default;
});