define("boekdrukken/components/ui-block/product/book/step/cover/templates/content/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-product-book-step-cover-templates-content', 'disabled:disabled'],
    component: null,
    disabled: false,
    templates: Ember.computed.readOnly('component.templates.templates'),
    current: Ember.computed.readOnly('component.design.template'),
    actions: {
      select: function select() {
        if (this.disabled) {
          return;
        }

        this.select.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});