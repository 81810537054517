define("boekdrukken/models/analytics/events/standalone/download", ["exports", "boekdrukken/models/analytics/events/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var action = function action(_action) {
    return (0, _base.event)(function (type) {
      return {
        action: "".concat(_action, "-").concat(type)
      };
    });
  };

  var _default = _base.default.extend({
    category: 'standalone-download',
    create: action('create')
  });

  _exports.default = _default;
});