define("boekdrukken/components/ui-route/users/user/products/product/step/index/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-route-users-user-products-product-step-index'],
    model: null,
    step: Ember.computed.readOnly('model.step'),
    product: Ember.computed.readOnly('step.product')
  });

  _exports.default = _default;
});