define("boekdrukken/templates/backend/xml", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cTCq1nVp",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"ui-route/backend/xml\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/templates/backend/xml.hbs"
    }
  });

  _exports.default = _default;
});