define("boekdrukken/components/ui-block/sketch/inspector/selection/model/area/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oNTc1mvr",
    "block": "{\"symbols\":[\"section\"],\"statements\":[[4,\"ui-block/sketch/inspector/section\",null,[[\"sketch\",\"model\"],[[24,[\"sketch\"]],[24,[\"model\"]]]],{\"statements\":[[0,\"\\n  \"],[1,[23,1,[\"header\"]],false],[0,\"\\n\\n  \"],[1,[23,1,[\"position\"]],false],[0,\"\\n  \"],[1,[23,1,[\"size\"]],false],[0,\"\\n\\n  \"],[1,[23,1,[\"identifier\"]],false],[0,\"\\n  \"],[1,[23,1,[\"fill\"]],false],[0,\"\\n\\n  \"],[1,[23,1,[\"move\"]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/inspector/selection/model/area/template.hbs"
    }
  });

  _exports.default = _default;
});