define("boekdrukken/models/upload/book-cover", ["exports", "boekdrukken/models/upload/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    owner: null,
    product: Ember.computed.readOnly('owner.product'),
    coverDimensions: Ember.computed.readOnly('product.cover.dimensions'),
    requiredDimensions: Ember.computed('coverDimensions.{crop,total}', function () {
      var _this$coverDimensions = this.coverDimensions,
          crop = _this$coverDimensions.crop,
          total = _this$coverDimensions.total;
      return {
        crop: crop,
        total: total
      };
    }).readOnly()
  });

  _exports.default = _default;
});