define("boekdrukken/components/ui-route/stylebook/slider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hincag+R",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"stylebook.slider\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"section\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"stylebook.regular\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n      \"],[1,[28,\"ui-input/slider\",null,[[\"min\",\"max\",\"value\",\"update\"],[0,100,[24,[\"value\"]],[28,\"action\",[[23,0,[]],\"update\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-route/stylebook/slider/template.hbs"
    }
  });

  _exports.default = _default;
});