define("boekdrukken/components/ui-block/product/step/product/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uwS7iAEw",
    "block": "{\"symbols\":[\"component\"],\"statements\":[[4,\"ui-block/card\",null,[[\"class\",\"type\"],[\"card\",\"minimal\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[24,[\"product\",\"title\"]],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[0,\"\\n      \"],[1,[24,[\"product\",\"dimensionsString\"]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"product\",\"sizeString\"]]],null,{\"statements\":[[0,\"        (\"],[1,[28,\"t\",[[24,[\"product\",\"sizeString\"]]],null],false],[0,\")\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"components\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"product\",\"sortedComponents\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"ui-block/product/step/product/component\",null,[[\"component\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"product\",\"pricing\",\"isSuccess\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"ui-block/product/step/product/price\",null,[[\"product\"],[[24,[\"product\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/product/step/product/template.hbs"
    }
  });

  _exports.default = _default;
});