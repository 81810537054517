define("boekdrukken/models/users/user/products/settings/book/paper/cover", ["exports", "boekdrukken/models/users/user/products/settings/book/-paper"], function (_exports, _paper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var data = function data(key) {
    return Ember.computed.readOnly("doc.data.".concat(key));
  };

  var _default = _paper.default.extend({
    type: data('type'),
    // paperback / hardcover
    state: data('state') // active / deprecated

  });

  _exports.default = _default;
});