define("boekdrukken/components/ui-block/cookie-consent/component", ["exports", "boekdrukken/util/computed", "js-cookie"], function (_exports, _computed, _jsCookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var name = 'boekdrukken-cookie-consent';

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-cookie-consent', 'accepted', 'isAnalyticsSuppressed:hidden'],
    accepted: false,
    isFastBoot: (0, _computed.isFastBoot)(),
    analytics: Ember.inject.service(),
    isAnalyticsSuppressed: Ember.computed.reads('analytics.suppressed'),
    init: function init() {
      this._super.apply(this, arguments);

      this.update();
    },
    update: function update() {
      var accepted;

      if (this.isFastBoot) {
        accepted = true;
      } else {
        accepted = _jsCookie.default.get(name) === 'true';
      }

      this.setProperties({
        accepted: accepted
      });
    },
    actions: {
      accept: function accept() {
        _jsCookie.default.set(name, 'true', {
          expires: 30 * 12,
          path: '/'
        });

        this.update();
      }
    }
  });

  _exports.default = _default;
});