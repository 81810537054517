define("boekdrukken/components/ui-route/account/link/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-route-account-link'],
    actions: {
      commit: function commit() {
        this.model.commit();
      }
    }
  });

  _exports.default = _default;
});