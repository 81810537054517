define("boekdrukken/models/analytics/events/order/checkout", ["exports", "boekdrukken/models/analytics/events/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var action = function action(type) {
    return (0, _base.event)(function (amount) {
      return {
        action: "checkout-".concat(type),
        value: amount
      };
    });
  };

  var _default = _base.default.extend({
    category: 'order',
    started: action('started'),
    succeeded: action('succeeded'),
    failed: action('failed')
  });

  _exports.default = _default;
});