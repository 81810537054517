define("boekdrukken/components/ui-route/backend/templates/type/template/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A8Cho+t2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ui-block/container\",null,[[\"type\",\"class\"],[\"margin\",\"container\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"state\"],[8],[0,\"\\n      \"],[1,[28,\"ui-block/pill\",null,[[\"class\",\"size\",\"color\",\"value\"],[\"pill\",\"small\",[24,[\"template\",\"stateColor\"]],[24,[\"template\",\"state\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[24,[\"template\",\"title\"]],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"subtitle\"],[8],[1,[24,[\"template\",\"description\"]],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n      \"],[1,[28,\"ui-input/button\",null,[[\"size\",\"type\",\"value\",\"action\"],[\"small\",\"outline\",\"Edit\",[28,\"action\",[[23,0,[]],\"edit\"],null]]]],false],[0,\"\\n      \"],[1,[28,\"ui-input/button\",null,[[\"size\",\"type\",\"value\",\"action\"],[\"small\",\"outline\",\"Design Layout\",[28,\"action\",[[23,0,[]],\"design\"],null]]]],false],[0,\"\\n      \"],[1,[28,\"ui-input/button\",null,[[\"size\",\"type\",\"value\",\"action\"],[\"small\",\"outline\",\"Preview Layout\",[28,\"action\",[[23,0,[]],\"preview\"],null]]]],false],[0,\"\\n      \"],[1,[28,\"ui-input/button\",null,[[\"size\",\"type\",\"color\",\"value\",\"action\"],[\"small\",\"outline\",\"warning\",\"Delete\",[28,\"action\",[[23,0,[]],\"delete\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"template\",\"thumbnail\",\"url\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"thumbnail\"],[8],[0,\"\\n    \"],[1,[28,\"ui-block/background-image\",null,[[\"url\",\"class\"],[[24,[\"template\",\"thumbnail\",\"url\"]],\"image\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-route/backend/templates/type/template/index/template.hbs"
    }
  });

  _exports.default = _default;
});