define("boekdrukken/models/alerts/notification", ["exports", "boekdrukken/models/alerts/-alert"], function (_exports, _alert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _alert.default.extend({
    dismissable: true,
    timeout: 3000,
    message: null,
    description: Ember.computed('intl.locale', 'message', function () {
      var intl = this.intl,
          message = this.message;
      return [{
        type: 'strong',
        value: intl.t(message)
      }];
    }).readOnly()
  });

  _exports.default = _default;
});