define("boekdrukken/components/ui-block/sketch/inspector/section/align/column/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var icons = Object.freeze({
    'align-left': 'align-left',
    'align-center': 'align-center',
    'align-right': 'align-right',
    'align-justify': 'align-justify',
    'verticalAlign-top': 'align-text-top',
    'verticalAlign-middle': 'align-text-center',
    'verticalAlign-bottom': 'align-text-bottom'
  });

  var _default = Ember.Component.extend({
    classNameBindings: [':column'],
    icons: icons
  });

  _exports.default = _default;
});