define("boekdrukken/components/ui-block/product/book/step/parameters/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-product-parameters-book'],
    step: null
  });

  _exports.default = _default;
});