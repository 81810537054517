define("boekdrukken/components/ui-block/product/book/step/content/spread/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ivgx/qdh",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ui-block/container\",null,null,{\"statements\":[[4,\"ui-block/left-right\",null,[[\"left\",\"right\",\"class\"],[[28,\"if\",[[24,[\"previous\"]],[28,\"action\",[[23,0,[]],\"select\",[24,[\"previous\"]]],null]],null],[28,\"if\",[[24,[\"next\"]],[28,\"action\",[[23,0,[]],\"select\",[24,[\"next\"]]],null]],null],\"left-right\"]],{\"statements\":[[0,\"    \"],[1,[28,\"ui-block/pdf/spread\",null,[[\"pdf\",\"pair\"],[[24,[\"pdf\"]],[24,[\"selection\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/product/book/step/content/spread/template.hbs"
    }
  });

  _exports.default = _default;
});