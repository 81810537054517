define("boekdrukken/components/ui-block/stripe/selection/type/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':type', 'isSelected:selected'],
    isSelected: Ember.computed.readOnly('type.isSelected'),
    click: function click() {
      this.select();
    }
  });

  _exports.default = _default;
});