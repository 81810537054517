define("boekdrukken/components/ui-block/product/book/step/cover/upload/upload/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':upload'],
    isBusy: Ember.computed.readOnly('component.upload.isBusy'),
    actions: {
      upload: function upload(file) {
        this.component.upload.create(file);
      }
    }
  });

  _exports.default = _default;
});