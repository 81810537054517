define("boekdrukken/components/ui-route/stylebook/validation/component", ["exports", "design/validation"], function (_exports, _validation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-route-stylebook-page', ':ui-route-stylebook-validation'],
    firstName: 'Zeeba',
    lastName: 'Neighba',
    email: null,
    validation: (0, _validation.validation)({
      firstName: (0, _validation.string)(),
      lastName: (0, _validation.string)(),
      email: (0, _validation.email)(),
      optionalEmail: (0, _validation.email)({
        required: false
      })
    })
  });

  _exports.default = _default;
});