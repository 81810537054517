define("boekdrukken/components/ui-block/sketch/inspector/section/font-style/column/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':column'],
    selected: Ember.computed('choices', 'value', function () {
      var value = this.value;
      return this.choices.findBy('value', value);
    }).readOnly(),
    actions: {
      select: function select(choice) {
        this.update(this.key, choice.value);
      }
    }
  });

  _exports.default = _default;
});