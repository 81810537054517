define("boekdrukken/routes/stylebook/flow/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      this.modelFor('stylebook.flow').flow.initial.transitionTo();
    }
  });

  _exports.default = _default;
});