define("boekdrukken/components/ui-block/cart/content/items/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PwExsgef",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"each\",[[24,[\"items\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"ui-block/cart/content/items/item\",null,[[\"item\",\"edit\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"edit\",[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/cart/content/items/template.hbs"
    }
  });

  _exports.default = _default;
});