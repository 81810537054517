define("boekdrukken/routes/backend/exports", ["exports", "boekdrukken/util/set-global"], function (_exports, _setGlobal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var registry = Ember.getOwner(this);
      var store = this.store;
      (0, _setGlobal.default)({
        store: store,
        registry: registry
      });
      this.transitionTo('index');
    }
  });

  _exports.default = _default;
});