define("boekdrukken/components/ui-block/cart/content/newsletter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-cart-content-newsletter', 'isEditable:visible:hidden'],
    isEditable: Ember.computed.readOnly('newsletter.isEditable'),
    actions: {
      update: function update(value) {
        this.newsletter.update(value);
      }
    }
  });

  _exports.default = _default;
});