define("boekdrukken/components/ui-block/cart/content/actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Oq3MX5sK",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isBusy\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"spinner\"],[8],[0,\"\\n    \"],[1,[28,\"ui-block/icon\",[\"refresh\"],[[\"spin\"],[true]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"unless\",[[24,[\"cart\",\"isIdle\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"spinner\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[28,\"t\",[\"block.cart.content.actions.status.busy\"],null],false],[9],[0,\"\\n    \"],[1,[28,\"ui-block/icon\",[\"refresh\"],[[\"spin\"],[true]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"ui-input/button\",null,[[\"value\",\"color\",\"disabled\",\"action\"],[[28,\"t\",[\"block.cart.content.actions.actions.checkout\"],null],\"success\",[24,[\"isDisabled\"]],[28,\"action\",[[23,0,[]],\"checkout\",[24,[\"highlight\"]]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/cart/content/actions/template.hbs"
    }
  });

  _exports.default = _default;
});