define("boekdrukken/components/ui-block/stripe/types/-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':type', 'identifier', 'isSelected:visible:hidden'],
    identifier: Ember.computed.readOnly('type.identifier'),
    isSelected: Ember.computed.readOnly('type.isSelected'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.unmount = this.type.mount(this.element.querySelector('.content'));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.unmount && this.unmount();
    }
  });

  _exports.default = _default;
});