define("boekdrukken/routes/backend/templates/type/template/design", ["exports", "ember-cli-zuglet/lifecycle", "boekdrukken/routes/-breadcrumb"], function (_exports, _lifecycle, _breadcrumb) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_breadcrumb.default, {
    model: (0, _lifecycle.route)().inline({
      breadcrumb: (0, _breadcrumb.breadcrumb)({
        title: 'backend.templates.type.design',
        action: function action(transitionTo) {
          return transitionTo('backend.templates.type.template.design');
        }
      }),
      parent: null,
      template: Ember.computed.readOnly('parent.template'),
      prepare: function prepare(route) {
        var parent = route.modelFor('backend.templates.type.template');
        this.setProperties({
          parent: parent
        });
      },
      willDestroy: function willDestroy() {
        this.template.createThumbnail();

        this._super.apply(this, arguments);
      }
    })
  });

  _exports.default = _default;
});