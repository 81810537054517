define("boekdrukken/components/ui-application/header/component", ["exports", "design/util/computed", "design/mixins/component-owner"], function (_exports, _computed, _componentOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentOwner.default, {
    classNameBindings: [':ui-application-header', '_type'],
    attributeBindings: ['style'],
    _type: (0, _computed.className)({
      key: 'type',
      value: 'regular'
    }),
    isOverType: Ember.computed.equal('type', 'over'),
    isBlankType: Ember.computed.equal('type', 'blank'),
    skipStyle: Ember.computed.or('isOverType', 'isBlankType'),
    registeredChildComponentsHeight: Ember.computed('registeredChildComponents.@each.componentHeight', function () {
      return this.registeredChildComponents.reduce(function (sum, component) {
        sum += component.componentHeight;
        return sum;
      }, 0);
    }).readOnly(),
    style: Ember.computed('registeredChildComponentsHeight', 'skipStyle', function () {
      if (this.skipStyle) {
        return;
      }

      var height = 60 + this.registeredChildComponentsHeight;
      return Ember.String.htmlSafe("height: ".concat(height, "px"));
    }).readOnly()
  });

  _exports.default = _default;
});