define("boekdrukken/components/ui-block/localization/editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-localization-editor'],
    actions: {
      filter: function filter(value) {
        this.localization.setFilterString(value);
      },
      select: function select(choice) {
        this.localization.setFilter(choice);
      }
    }
  });

  _exports.default = _default;
});