define("boekdrukken/components/ui-route/definitions/definition/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EViiBKdH",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"component\",[[28,\"concat\",[\"ui-block/definition/\",[24,[\"definition\",\"id\"]]],null]],[[\"definition\"],[[24,[\"definition\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-route/definitions/definition/index/template.hbs"
    }
  });

  _exports.default = _default;
});