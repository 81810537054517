define("boekdrukken/routes/onboarding/index", ["exports", "ember-cli-zuglet/lifecycle"], function (_exports, _lifecycle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: (0, _lifecycle.route)().inline({
      prepare: function prepare() {
        this.router.transitionTo('onboarding.process');
      }
    })
  });

  _exports.default = _default;
});