define("boekdrukken/components/ui-block/stripe/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-stripe', 'isLoaded:loaded:loading'],
    isLoaded: Ember.computed.readOnly('payment.isLoaded')
  });

  _exports.default = _default;
});