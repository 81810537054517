define("boekdrukken/models/stripe/payment/bancontact", ["exports", "boekdrukken/models/stripe/payment/-type", "boekdrukken/util/computed"], function (_exports, _type, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _type.default.extend({
    error: null,
    name: '',
    isAdditionalFieldsValid: (0, _computed.notBlank)('name'),
    _confirm: function _confirm(_ref) {
      var stripe = _ref.stripe,
          intent = _ref.intent,
          url = _ref.url;
      var name = this.name;
      return stripe.confirmBancontactPayment(intent.client_secret, {
        payment_method: {
          billing_details: {
            name: name
          }
        },
        return_url: url
      });
    },
    updateName: function updateName(name) {
      this.setProperties({
        name: name
      });
    }
  });

  _exports.default = _default;
});