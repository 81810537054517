define("boekdrukken/components/ui-block/product/book/step/parameters/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TAAee2qL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"key\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"lockable\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"lockable\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"locked\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",false],[12,\"class\",\"locked\"],[3,\"action\",[[23,0,[]],\"unlock\"]],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[28,\"t\",[\"block.product.book.step.parameters.row.unlock\"],null],false],[9],[0,\"\\n        \"],[1,[28,\"ui-block/icon\",[\"lock\"],[[\"class\"],[\"icon\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"value\"],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"disabled\"],[[24,[\"disabled\"]]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/product/book/step/parameters/row/template.hbs"
    }
  });

  _exports.default = _default;
});