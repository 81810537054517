define("boekdrukken/models/product/definition/book", ["exports", "boekdrukken/models/product/definition/-definition", "design/flow"], function (_exports, _definition, _flow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _definition.default.extend({
    title: 'Book',
    description: 'Create a book from PDF',
    flow: (0, _flow.flow)({
      pdf: (0, _flow.step)({
        title: 'model.users.user.products.book.flow.source',
        current: true
      }),
      settings: (0, _flow.step)({
        title: 'model.users.user.products.book.flow.parameters',
        enabled: false
      }),
      content: (0, _flow.step)({
        title: 'model.users.user.products.book.flow.content',
        enabled: false
      }),
      cover: (0, _flow.step)({
        title: 'model.users.user.products.book.flow.cover',
        enabled: false
      }),
      summary: (0, _flow.step)({
        title: 'model.users.user.products.book.flow.summary',
        enabled: false
      })
    }),
    operations: Ember.inject.service(),
    createOrderOperation: function createOrderOperation(file) {
      var definition = this;
      return this.operations.create('create-book-product', {
        definition: definition,
        file: file
      });
    }
  });

  _exports.default = _default;
});