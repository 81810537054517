define("boekdrukken/models/alerts/mobile", ["exports", "boekdrukken/models/alerts/-alert"], function (_exports, _alert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _alert.default.extend({
    dismissable: true,
    icon: 'times-circle-o',
    color: 'warning',
    description: Ember.computed('intl.locale', function () {
      return [{
        type: 'strong',
        value: this.intl.t('model.alerts.mobile.label')
      }];
    }).readOnly()
  });

  _exports.default = _default;
});