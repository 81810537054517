define("boekdrukken/components/ui-block/sketch/inspector/section/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oiRwqrez",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"name\"],[8],[1,[28,\"t\",[[24,[\"model\",\"name\"]]],null],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/inspector/section/header/template.hbs"
    }
  });

  _exports.default = _default;
});