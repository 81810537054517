define("boekdrukken/components/ui-block/cart/content/address/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-cart-content-address'],
    cart: null,
    address: null,
    actions: {
      update: function update(field, key, value) {
        field.touch();
        this.address.update(_defineProperty({}, key, value));
      },
      updateCountry: function updateCountry(field, value) {
        field.touch();
        this.address.update({
          country: value.id
        });
      }
    }
  });

  _exports.default = _default;
});