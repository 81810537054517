define("boekdrukken/components/ui-route/backend/templates/type/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hf38ioKH",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ui-block/container\",null,[[\"class\"],[\"container\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"key\"],[8],[1,[28,\"t\",[\"route.backend.templates.type.new.label.title\"],null],false],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"value\"],[8],[1,[28,\"ui-input/text\",null,[[\"value\",\"update\"],[[24,[\"model\",\"title\"]],[28,\"action\",[[23,0,[]],\"update\",\"title\"],null]]]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"key\"],[8],[1,[28,\"t\",[\"route.backend.templates.type.new.label.description\"],null],false],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"value\"],[8],[1,[28,\"ui-input/text\",null,[[\"value\",\"update\"],[[24,[\"model\",\"description\"]],[28,\"action\",[[23,0,[]],\"update\",\"description\"],null]]]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row actions\"],[8],[0,\"\\n      \"],[1,[28,\"ui-input/button\",null,[[\"value\",\"color\",\"action\"],[[28,\"t\",[\"route.backend.templates.type.new.actions.save\"],null],\"success\",[28,\"action\",[[23,0,[]],\"save\"],null]]]],false],[0,\"\\n      \"],[1,[28,\"ui-input/button\",null,[[\"value\",\"color\",\"action\"],[[28,\"t\",[\"route.backend.templates.type.new.actions.cancel\"],null],\"neutral\",[28,\"action\",[[23,0,[]],\"cancel\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-route/backend/templates/type/new/template.hbs"
    }
  });

  _exports.default = _default;
});