define("boekdrukken/components/ui-application/header/navigation/breadcrumb/admin/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F3HEr0gX",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"email\"]]],null,{\"statements\":[[0,\"  \"],[1,[22,\"email\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"t\",[\"application.header.navigation.breadcrumb.admin.anonymous\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-application/header/navigation/breadcrumb/admin/template.hbs"
    }
  });

  _exports.default = _default;
});