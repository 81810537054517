define("boekdrukken/routes/definitions/definition", ["exports", "ember-cli-zuglet/lifecycle", "boekdrukken/routes/-breadcrumb", "boekdrukken/routes/-accessory"], function (_exports, _lifecycle, _breadcrumb, _accessory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_breadcrumb.default, _accessory.default, {
    model: (0, _lifecycle.route)().inline({
      productDefinitions: Ember.inject.service(),
      breadcrumb: (0, _breadcrumb.breadcrumb)({
        title: 'definitions.definition'
      }),
      accessories: (0, _accessory.accessories)({
        wide: (0, _accessory.accessory)('flow', {
          component: 'ui-block/accessory/flow/steps',
          model: function model(_ref) {
            var flow = _ref.flow;
            return flow;
          }
        })
      }),
      definition: null,
      flow: Ember.computed.readOnly('definition.flow'),
      prepare: function prepare(route, _ref2) {
        var id = _ref2.definition_id;
        var definition = this.productDefinitions.availableById(id, {
          optional: true
        });

        if (!definition) {
          this.router.transitionTo('index');
          return;
        }

        this.setProperties({
          definition: definition
        });
      }
    })
  });

  _exports.default = _default;
});