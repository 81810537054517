define("boekdrukken/components/ui-application/alerts/alert/component", ["exports", "design/util/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-application-alerts-alert', 'dismissable:dismissable', '_color'],
    dismissable: Ember.computed.readOnly('model.dismissable'),
    color: Ember.computed.readOnly('model.color'),
    _color: (0, _computed.className)({
      key: 'color',
      value: 'primary'
    }),
    click: function click() {
      this.dismiss();
    },
    dismiss: function dismiss() {
      var dismissable = this.dismissable,
          model = this.model;

      if (!dismissable) {
        return;
      }

      model.dismiss();
    }
  });

  _exports.default = _default;
});