define("boekdrukken/components/ui-block/sketch/tree/model/accessory/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    classNameBindings: [':accessory', 'faded:faded'],
    model: null,
    key: null,
    click: function click(e) {
      e.stopPropagation();
      var key = this.key,
          model = this.model;

      if (!model) {
        return;
      }

      model.update(_defineProperty({}, key, !model.get(key)));
    }
  });

  _exports.default = _default;
});