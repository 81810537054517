define("boekdrukken/util/object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reduce = _exports.pick = _exports.omit = void 0;

  var pick = function pick(hash, keys) {
    var result = {};
    keys.forEach(function (key) {
      var value = hash[key];

      if (value !== undefined) {
        result[key] = value;
      }
    });
    return result;
  };

  _exports.pick = pick;

  var omit = function omit(hash, keys) {
    return Object.keys(hash).reduce(function (ret, key) {
      if (!keys.includes(key)) {
        ret[key] = hash[key];
      }

      return ret;
    }, {});
  };

  _exports.omit = omit;

  var reduce = function reduce(hash, fn) {
    return Object.keys(hash).reduce(function (ret, key) {
      var value = fn(hash[key], key);

      if (value !== undefined) {
        ret[key] = value;
      }

      return ret;
    }, {});
  };

  _exports.reduce = reduce;
});