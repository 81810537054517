define("boekdrukken/components/ui-application/component", ["exports", "design/util/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-application', '_type'],
    breadcrumbs: Ember.inject.service(),
    analytics: Ember.inject.service(),
    type: Ember.computed('breadcrumbs.items.@each.layout', function () {
      return Ember.A(Ember.A(this.breadcrumbs.items.map(function (item) {
        return item.layout;
      })).compact()).lastObject;
    }).readOnly(),
    _type: (0, _computed.className)({
      key: 'type',
      value: 'regular'
    }),
    isBlankType: Ember.computed.equal('type', 'blank')
  });

  _exports.default = _default;
});