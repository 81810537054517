define("boekdrukken/components/ui-block/cart/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-cart'],
    cart: null,
    actions: {
      new: function _new() {
        this.router.transitionTo('index');
      },
      products: function products() {
        this.router.transitionTo('users.user.products');
      }
    }
  });

  _exports.default = _default;
});