define("boekdrukken/components/ui-application/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xR0iwxec",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\\n\"],[4,\"ui-block/container\",null,[[\"class\"],[\"container\"]],{\"statements\":[[0,\"    \"],[1,[28,\"ui-application/header/logo\",null,[[\"class\"],[\"logo\"]]],false],[0,\"\\n    \"],[1,[28,\"ui-application/header/back\",null,[[\"class\"],[\"back\"]]],false],[0,\"\\n    \"],[1,[28,\"ui-application/header/accessory\",null,[[\"position\",\"class\"],[\"left\",\"accessory left\"]]],false],[0,\"\\n    \"],[1,[28,\"ui-application/header/navigation\",null,[[\"class\"],[\"navigation\"]]],false],[0,\"\\n    \"],[1,[28,\"ui-application/header/auth\",null,[[\"class\"],[\"auth\"]]],false],[0,\"\\n    \"],[1,[28,\"ui-application/header/cart\",null,[[\"class\"],[\"cart\"]]],false],[0,\"\\n    \"],[1,[28,\"ui-application/header/accessory\",null,[[\"position\",\"class\"],[\"right\",\"accessory right\"]]],false],[0,\"\\n    \"],[1,[28,\"ui-application/header/locales\",null,[[\"class\"],[\"locales\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[28,\"ui-application/header/accessory\",null,[[\"position\",\"class\",\"componentOwner\"],[\"wide\",\"accessory wide\",[23,0,[]]]]],false],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-application/header/template.hbs"
    }
  });

  _exports.default = _default;
});