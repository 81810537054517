define("boekdrukken/components/ui-block/sketch/inspector/section/opacity-rotation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XYXMxl76",
    "block": "{\"symbols\":[\"attribute\",\"attribute\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[28,\"t\",[\"block.sketch.inspector.section.labels.opacity\"],null],false],[9],[0,\"\\n\"],[4,\"let\",[[28,\"get\",[[24,[\"model\",\"node\",\"attributes\"]],\"opacity\"],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"ui-input/slider\",null,[[\"min\",\"max\",\"step\",\"value\",\"update\"],[[23,2,[\"min\"]],[23,2,[\"max\"]],[24,[\"opacity\"]],[24,[\"model\",\"opacity\"]],[28,\"action\",[[23,0,[]],[24,[\"update\"]],\"opacity\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[28,\"t\",[\"block.sketch.inspector.section.labels.rotation\"],null],false],[9],[0,\"\\n\"],[4,\"let\",[[28,\"get\",[[24,[\"model\",\"node\",\"attributes\"]],\"rotation\"],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"ui-input/slider\",null,[[\"min\",\"max\",\"step\",\"value\",\"update\"],[[23,1,[\"min\"]],[23,1,[\"max\"]],[24,[\"rotation\"]],[24,[\"model\",\"rotation\"]],[28,\"action\",[[23,0,[]],[24,[\"update\"]],\"rotation\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/inspector/section/opacity-rotation/template.hbs"
    }
  });

  _exports.default = _default;
});