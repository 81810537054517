define("boekdrukken/components/ui-dialog/color-pages/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LDonY378",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[1,[28,\"t\",[\"dialog.color-pages.header\"],null],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"dialog.color-pages.description\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[1,[28,\"ui-input/button\",null,[[\"size\",\"color\",\"value\",\"action\",\"class\"],[\"small\",\"secondary\",[28,\"t\",[\"dialog.color-pages.actions.cancel\"],null],[28,\"action\",[[23,0,[]],\"reject\"],null],\"action\"]]],false],[0,\"\\n  \"],[1,[28,\"ui-input/button\",null,[[\"size\",\"value\",\"action\",\"class\"],[\"small\",[28,\"t\",[\"dialog.color-pages.actions.mark\"],null],[28,\"action\",[[23,0,[]],\"confirm\"],null],\"action\"]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-dialog/color-pages/template.hbs"
    }
  });

  _exports.default = _default;
});