define("boekdrukken/util/set-global", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(hash) {
    for (var key in hash) {
      var value = hash[key];
      console.log("window.".concat(key, " = ").concat(value));
      window[key] = value;
    }
  };

  _exports.default = _default;
});