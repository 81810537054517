define("boekdrukken/components/ui-route/users/user/products/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XgGFEA7R",
    "block": "{\"symbols\":[\"tabs\"],\"statements\":[[4,\"ui-block/container\",null,[[\"type\",\"class\"],[\"centered-wide\",\"container\"]],{\"statements\":[[0,\"\\n\"],[4,\"ui-block/tabs\",null,[[\"class\",\"selected\",\"select\"],[\"tabs\",[24,[\"selected\"]],[28,\"action\",[[23,0,[]],\"select\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"tab\"]],\"expected `tabs.tab` to be a contextual component but found a string. Did you mean `(component tabs.tab)`? ('boekdrukken/components/ui-route/users/user/products/index/template.hbs' @ L4:C6) \"],null]],[[\"label\",\"id\",\"component\"],[[28,\"t\",[\"route.users.user.products.index.tabs.products\"],null],\"products\",[28,\"component\",[\"ui-block/products/list\"],[[\"products\",\"select\",\"delete\",\"new\",\"custom\"],[[24,[\"model\",\"products\"]],[28,\"action\",[[23,0,[]],\"product\"],null],[28,\"action\",[[23,0,[]],\"delete\"],null],[28,\"action\",[[23,0,[]],\"newBook\"],null],[28,\"action\",[[23,0,[]],\"customProduct\"],null]]]]]]],false],[0,\"\\n    \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"tab\"]],\"expected `tabs.tab` to be a contextual component but found a string. Did you mean `(component tabs.tab)`? ('boekdrukken/components/ui-route/users/user/products/index/template.hbs' @ L9:C6) \"],null]],[[\"label\",\"id\",\"component\"],[[28,\"t\",[\"route.users.user.products.index.tabs.profile\"],null],\"profile\",[28,\"component\",[\"ui-block/user/profile\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-route/users/user/products/index/template.hbs"
    }
  });

  _exports.default = _default;
});