define("boekdrukken/translations/nl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "application": {
      "header": {
        "auth": {
          "anonymous": "Anonieme gebruiker",
          "domain": "@{domain}",
          "sign-in": "Log in"
        },
        "back": {
          "label": "Terug naar de website"
        },
        "cart": {
          "label": "Winkelwagen"
        },
        "locales": {
          "keys": "Sleutels"
        },
        "navigation": {
          "breadcrumb": {
            "admin": {
              "anonymous": "Anonieme gebruiker"
            },
            "label": {
              "backend": {
                "index": "Backend",
                "localization": {
                  "index": "Taalinstellingen"
                },
                "orders": "Bestellingen",
                "subscriptions": "Emails",
                "templates": {
                  "index": "Sjablonen",
                  "type": {
                    "design": "Design",
                    "edit": "Bewerken",
                    "new": "Nieuw sjabloon",
                    "outlet": "Product",
                    "preview": {
                      "design": "Design",
                      "index": "Voorbeeld"
                    }
                  }
                },
                "xml": {
                  "index": "XML Export"
                }
              },
              "definitions": {
                "definition": "PDF uploaden",
                "index": "Kies een product type"
              },
              "index": "Upload jouw PDF",
              "onboarding": {
                "process": "PDF wordt geüpload..."
              },
              "stylebook": {
                "index": "Design system"
              },
              "users": {
                "user": {
                  "cart": {
                    "index": "Je winkelwagen"
                  },
                  "orders": {
                    "order": {
                      "index": "Je bestelling"
                    }
                  },
                  "products": {
                    "index": "Producten",
                    "product": {
                      "delete": "Verwijderen"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "block": {
      "cart": {
        "content": {
          "actions": {
            "actions": {
              "checkout": "Bestellen"
            },
            "status": {
              "busy": "Prijs wordt berekend..."
            }
          },
          "address": {
            "header": {
              "title": "Adresgegevens"
            },
            "label": {
              "city": "Stad",
              "country": "Land",
              "email": "Email",
              "first-name": "Voornaam",
              "last-name": "Achternaam",
              "number": "Huisnummer",
              "postal-code": "Postcode",
              "street": "Straatnaam"
            }
          },
          "anonymous": {
            "actions": {
              "link": "Account aanmaken"
            },
            "header": {
              "description": "Beveilig je anonieme account met je emailadres en een wachtwoord zodat je later makkelijk verder kunt.",
              "title": "Anonieme gebruiker"
            }
          },
          "coupon": {
            "actions": {
              "apply": "Toepassen"
            },
            "header": {
              "title": "Kortingscode"
            },
            "status": {
              "applied": "Kortingscode toegepast",
              "checking": "Kortingscode wordt geverifieerd",
              "conditions": {
                "reason": {
                  "default": "De vereisten voor de coupon zijn niet voldaan."
                }
              },
              "invalid": {
                "description": "Kortingscodes zijn hoofdletter gevoelig. Check de kortingscode en probeer het opnieuw.",
                "title": "Het lijkt erop dat je een ongeldige code hebt ingevoerd."
              },
              "removing": "Verwijdert kortingscode..."
            }
          },
          "highlight": {
            "address": "Zorg ervoor dat alle adresgegevens ingevuld zijn",
            "anonymous": "Maak een account aan",
            "legal": "Accepteren van het privacy beleid en de algemene voorwaarden is vereist",
            "payment": "Kies een betaalwijze"
          },
          "items": {
            "edit": {
              "actions": {
                "cancel": "Annuleren",
                "edit": "Product bewerken"
              },
              "message": "Als je het product bewerkt wordt het uit het winkelwagentje verwijdert"
            },
            "item": {
              "actions": {
                "default": "Acties...",
                "delete": "Verwijderen",
                "edit": "Bewerken"
              },
              "count": {
                "edit": "Bewerken",
                "save": "Opslaan"
              },
              "delete": {
                "cancel": "Annuleren",
                "message": "Weet je zeker dat je dit product wilt verwijderen uit het winkelwagentje?",
                "remove": "Product verwijderen"
              },
              "discount": "Inclusief {total} korting",
              "times": "×",
              "total": {
                "label": "Totaal"
              }
            }
          },
          "legal": {
            "header": {
              "title": "Juridische zaken"
            },
            "label": {
              "privacy": "Privacybeleid accepteren",
              "terms": "Algemene voorwaarden accepteren"
            }
          },
          "newsletter": {
            "header": {
              "title": "Nieuwsbrief"
            },
            "label": "Inschrijven voor nieuwsbrief"
          },
          "payment": {
            "header": {
              "title": "Betaalwijze"
            }
          },
          "signup": {
            "actions": {
              "or": "of",
              "skip": "Ga anoniem verder"
            },
            "header": {
              "description": "Beveilig je anonieme account met je emailadres en een wachtwoord zodat je later makkelijk verder kunt.",
              "title": "Maak een account aan"
            }
          },
          "total": {
            "discount": {
              "value": "Inclusief {total} korting"
            },
            "header": {
              "title": "Totaal"
            },
            "shipping": {
              "label": "Verzending"
            },
            "vat": {
              "value": "incl BTW {percent}% van {total}"
            }
          }
        },
        "header": {
          "actions": {
            "new": "Boek maken",
            "products": "Bestaand product toevoegen"
          },
          "title": "Winkelwagen"
        },
        "placeholder": {
          "actions": {
            "new": "Begin een nieuw boek",
            "products": "Naar producten"
          },
          "title": "Je winkelwagen is leeg"
        }
      },
      "definition": {
        "bookmark": {
          "create": "Boekenlegger maken",
          "name": "Productnaam",
          "title": "NIeuwe boekenlegger maken"
        },
        "custom": {
          "create": "Product maken",
          "name": "Productnaam",
          "title": "Custom product maken",
          "type": "Custom type"
        }
      },
      "dev": {
        "index": {
          "actions": {
            "backend": "Backend",
            "sign-in": "Inloggen",
            "sign-out": "Uitloggen",
            "stylebook": "Design system"
          },
          "admin": "Beheerder",
          "client": "Klant",
          "css": "CSS",
          "devops": "Devops",
          "environment": "Omgeving",
          "functions": "boekdrukken-functies",
          "loading": "Aan het laden...",
          "locales": "Locales",
          "none": "Geen",
          "not-logged-in": "Niet ingelogd",
          "version": "v{version}"
        }
      },
      "flow": {
        "navigation": {
          "continue": "Volgende",
          "label": "Stap {number} van {total}",
          "previous": "Terug"
        }
      },
      "localization": {
        "editor": {
          "items": {
            "placeholder": "Geen items aanwezig"
          },
          "readonly": "Deze vertaling is niet aanpasbaar"
        }
      },
      "product": {
        "book": {
          "step": {
            "content": {
              "loading": "Pagina wordt geladen...",
              "sidebar": {
                "alert": {
                  "cancel": "Annuleren",
                  "mark": "Alle pagina's markeren als {type}",
                  "message": "Weet je zeker dat je alle pagina's als {type} wil markeren?"
                },
                "color-type": {
                  "mark-all": "Markeer alle"
                },
                "filter": {
                  "all": "Alle"
                },
                "total-pages": "Totaal aantal pagina's"
              },
              "toolbar": {
                "reset": "Herstellen"
              }
            },
            "cover": {
              "design": {
                "back": {
                  "label": "Terug naar type selectie"
                },
                "dev": {
                  "actions": {
                    "remove-design": "Verwijder design"
                  },
                  "title": "Development tools"
                },
                "pdf": {
                  "create": "Creëer PDF",
                  "error": "Sorry, er is iets verkeerd gegaan",
                  "open": {
                    "pdf": "Open PDF bestand",
                    "preview": "Voorbeeld openen"
                  },
                  "pending": "In de wachtrij...",
                  "recreate": "Nieuw voorbeeld maken",
                  "running": "PDF wordt gemaakt",
                  "title": "Omslag bekijken"
                }
              },
              "templates": {
                "content": {
                  "selected": {
                    "placeholder": "Selecteer een sjabloon voor de boekomslag uit de lijst"
                  }
                },
                "label": "Kies sjabloon",
                "standalone": {
                  "actions": {
                    "cancel": "Annuleren",
                    "choose": "Kies een sjabloon om te beginnen"
                  },
                  "creating": "Boek omslag aan het maken...",
                  "description": "De boekomslag heeft nog geen ontwerp. Begin door een sjabloon te selecteren.",
                  "title": "Kies een sjabloon voor de boekomslag"
                }
              },
              "types": {
                "header": {
                  "description": "Hoe wil je je boekomslag ontwerpen?",
                  "title": "Maak omslag"
                },
                "online": {
                  "actions": {
                    "blank": "Begin blanco",
                    "continue": "Ga door met online ontwerpen",
                    "template": "Gebruik een sjabloon"
                  },
                  "header": "Onze online ontwerper"
                },
                "upload": {
                  "actions": {
                    "use": "Gebruik je eigen software"
                  },
                  "header": "Je eigen tools"
                }
              },
              "upload": {
                "actions": {
                  "back": "Terug naar ontwerpselectie",
                  "download": {
                    "default": "Sjabloon downloaden",
                    "download": "PDF sjabloon downloaden",
                    "in-progress": "Sjabloon aan het maken..."
                  },
                  "upload": {
                    "default": "Voltooid design uploaden",
                    "in-progress": "Design wordt geüpload",
                    "replace": "Voltooid ontwerp vervangen"
                  }
                },
                "details": {
                  "info": "{pageCount} {pageCount, plural, one {pagina} other {pagina's}} met een afgewerkt formaat van {dimensions}",
                  "multiple-pages": "De PDF bevat meer dan één pagina. We gebruiken alleen de eerste pagina.",
                  "trimbox": {
                    "uniform": "De trimbox is ingesteld op {uniform} voor alle zijden",
                    "variable": "De trimbox is ingesteld op {top} boven, {right} rechts, {bottom} onder, {left} links"
                  }
                },
                "error": {
                  "internal": "Sorry er is iets mis gegaan",
                  "pdf-load": "De geüploade PDF is niet geldig"
                },
                "header": "Gebruik je eigen software",
                "processing": {
                  "title": "Voltooid design wordt verwerkt..."
                },
                "status": {
                  "valid": "De geüploade PDF is and will be used as a cover design"
                }
              }
            },
            "parameters": {
              "content": {
                "paper-type": {
                  "label": "Papiersoort"
                },
                "title": "Binnenwerk"
              },
              "cover": {
                "cover-type": {
                  "label": "Omslagtype",
                  "placeholder": "Paperback"
                },
                "description": "Wordt altijd full color gedrukt",
                "paper-finish": {
                  "label": "Afwerking",
                  "type": {
                    "glossy": "Glanzend",
                    "matte": "Mat"
                  }
                },
                "paper-type": {
                  "label": "Papiersoort"
                },
                "title": "Omslag"
              },
              "row": {
                "unlock": "Klik om te ontgrendelen"
              }
            },
            "source": {
              "tooltip": "Voor meer informatie om het formaat van je boek aan te passen bekijk de <a href=\"{url}\" target=\"top\">Checklist</a>"
            },
            "summary": {
              "actions": {
                "add-to-cart": "Toevoegen aan winkelwagen"
              },
              "busy": "PDF omslag wordt gemaakt...",
              "header": "Samenvatting"
            }
          }
        },
        "bookmark": {
          "step": {
            "parameters": {
              "cover": {
                "dimensions": {
                  "label": "Formaat"
                },
                "paper-finish": {
                  "label": "Afwerking",
                  "type": {
                    "glossy": "Glanzend",
                    "matte": "Mat"
                  }
                },
                "paper-type": {
                  "label": "Papier type"
                },
                "sides": {
                  "label": "Zijdes",
                  "type": {
                    "double": "Dubbelzijdig",
                    "single": "Enkelzijdig"
                  }
                },
                "size": {
                  "label": "Formaat"
                },
                "title": "Boekenlegger"
              }
            }
          }
        },
        "custom": {
          "step": {
            "summary": {
              "actions": {
                "add-to-cart": "Toevoegen aan winkelwagen"
              },
              "details": {
                "header": "Details",
                "identifier": "Identifier",
                "name": "Productnaam",
                "price": "Product prijs",
                "shipping": "Verzendkosten",
                "type": "Type",
                "types": {
                  "book": "Custom boek",
                  "poster": "Poster",
                  "sticker": "Stickers"
                }
              },
              "header": "Samenvatting",
              "thumbnail": {
                "header": "Thumbnail",
                "upload": "Upload"
              }
            }
          }
        },
        "pages": {
          "step": {
            "parameters": {
              "content": {
                "paper-type": {
                  "label": "Papiertype"
                },
                "title": "Losse pagina's"
              }
            }
          }
        },
        "step": {
          "content": {
            "loading": "Pagina's laden...",
            "sidebar": {
              "alert": {
                "cancel": "Annuleren",
                "mark": "Markeer alle pagina's {type}",
                "message": "Weet ej zeker dat je alle pagina's {type} wilt markeren?"
              },
              "color-type": {
                "mark-all": "markeer alle"
              },
              "filter": {
                "all": "Alle"
              },
              "total-pages": "Pagina's totaal"
            },
            "toolbar": {
              "reset": "Herstellen"
            }
          },
          "errors": {
            "action": {
              "show-step": "Oplossen",
              "start-again": "Begin opnieuw",
              "try-again": "Probeer opnieuw"
            },
            "error": {
              "content-size": {
                "fallback": "Sorry, boeken van dit formaat kunnen we momenteel helaas niet drukken",
                "max": "Sorry, het maximale boekformaat is {width} × {height}",
                "min": "Sorry, het minimale boekformaat is {width} × {height}"
              },
              "default": "Sorry, er is iets mis gegaan. Neem contact op met ons op.",
              "dimensions-mismatch": "Sorry, alle pagina's moeten exact hetzelfde formaat hebben",
              "spine": {
                "max": "Sorry, maximale aantal pagina's is {max}",
                "min": "Sorry, minimale aantal pagina's is {min}"
              }
            }
          },
          "notifications": {
            "notification": {
              "trimbox": "We hebben snijlijnen in het document gedetecteerd. Het afgewerkte formaat wordt {width}{nbsp}×{nbsp}{height}"
            }
          },
          "product": {
            "price": {
              "total": "Totaal"
            }
          },
          "summary": {
            "actions": {
              "add-to-cart": "Aan winkelwagen toevoegen"
            },
            "busy": "Maakt omslag PDF...",
            "header": "Samenvatting"
          }
        }
      },
      "products": {
        "list": {
          "actions": {
            "custom": "Nieuw custom product",
            "new": "Begin een nieuw boek"
          },
          "header": {
            "title": "Jouw boeken"
          },
          "placeholder": "Je hebt nog geen boeken",
          "product": {
            "actions": {
              "edit": "Boek bewerken"
            },
            "details": {
              "accessed-at": "Laatst aangepast op",
              "created-at": "Gemaakt op",
              "dimensions": "Formaat",
              "pages": "Totaal aantal pagina's",
              "source": "Gebaseerd op"
            },
            "tools": {
              "default": "Meer...",
              "delete": "Verwijder boek"
            }
          }
        }
      },
      "sketch": {
        "inspector": {
          "section": {
            "align": {
              "horizontal": {
                "choices": {
                  "center": "Midden",
                  "justify": "Uitvullen",
                  "left": "Links",
                  "right": "Rechts"
                },
                "label": "Horizontaal uitlijnen"
              },
              "vertical": {
                "choices": {
                  "bottom": "Onder",
                  "middle": "Midden",
                  "top": "Boven"
                },
                "label": "Verticaal uitlijnen"
              }
            },
            "fit": {
              "choices": {
                "contain": "Begrenzen",
                "fill": "Opvullen"
              }
            },
            "font": {
              "family": "Lettertype",
              "line-height": "Hoogte",
              "size": "Grootte"
            },
            "font-style": {
              "style": {
                "choices": {
                  "italic": "Cursief",
                  "normal": "Normaal"
                },
                "label": "Stijl"
              },
              "weight": {
                "choices": {
                  "black": "Zwart",
                  "bold": "Dikgedrukt",
                  "extra-bold": "Extra dikgedrukt",
                  "extra-light": "Extra dungedrukt",
                  "light": "Dungedrukt",
                  "medium": "Gemiddeld",
                  "regular": "Normaal",
                  "semi-bold": "Semi-vetdrukt",
                  "thin": "Dun"
                },
                "label": "Gewicht"
              }
            },
            "labels": {
              "color": "Kleur",
              "delete": "Verwijderen",
              "fill": "Kleur achtergrond",
              "fit": "Afbeeldingsmodus",
              "group": "Groep",
              "identifier": "Identificatie",
              "opacity": "Dekking",
              "rotation": "Rotatie",
              "text": "Tekst"
            },
            "move": {
              "backward": "Naar achteren brengen",
              "forward": "Naar voren brengen"
            },
            "position": {
              "x": "X",
              "y": "Y"
            },
            "size": {
              "height": "Hoogte",
              "width": "Breedte"
            },
            "size-warning": {
              "description": {
                "main": "Afbeelding van {size} is te klein. Upload een grotere om kwaliteit te garanderen.",
                "size": "<span class=\"size\"><span class=\"px\">{width}</span><span class=\"times\"></span><span class=\"px\">{height}</span></span>"
              },
              "ignore": "Negeren",
              "title": "Afbeelding te klein"
            }
          },
          "selection": {
            "model": {
              "image": {
                "upload": "Afbeelding selecteren"
              }
            }
          }
        },
        "nodes": {
          "types": {
            "default": "Toevoegen...",
            "image": "Afbeelding",
            "rect": "Rechthoek",
            "text": "Tekst"
          }
        },
        "stage": {
          "node": {
            "area": {
              "accessories": {
                "book-crop-marks": {
                  "label": {
                    "back": "Achterzijde omslag",
                    "front": "Voorzijde omslag",
                    "spine": "Rug"
                  }
                }
              }
            }
          }
        },
        "zoom": {
          "fit": "Passend"
        }
      },
      "standalone": {
        "calculator": {
          "book": {
            "actions": {
              "calculate": "Berekenen"
            },
            "field": {
              "count": {
                "highlight": "Geef het aantal boeken in",
                "label": "Aantal boeken",
                "placeholder": "bijv. 50"
              },
              "country": {
                "label": "Verzenden naar"
              },
              "height": {
                "highlight": "Voer de hoogte van het boek in in millimeters",
                "label": "Hoogte",
                "placeholder": "Wetenschappelijk (bijv. 240 mm)"
              },
              "pages": {
                "highlight": "Voer het totale aantal pagina's in",
                "label": "Totaal aantal pagina's",
                "placeholder": "bijv. 100"
              },
              "pages-in-color": {
                "highlight": "Voer het aantal pagina's in kleur in",
                "label": "Pagina's in kleur",
                "placeholder": "bijv. 20"
              },
              "width": {
                "highlight": "Voer de breedte van het boek in in millimeters",
                "label": "Breedte",
                "placeholder": "Wetenschappelijk (bijv. 170 mm)"
              }
            },
            "result": {
              "discount": "Inclusief {total} korting",
              "price": "Prijs voor {count} {count, plural, one {boek} other {boeken}}",
              "price-per-book": "Prijs per boek",
              "shipping": "Transport",
              "total": "Totaal",
              "vat": "Inclusief {percent}% BTW op {total}"
            },
            "status": {
              "busy": "Prijs wordt berekend...",
              "error": {
                "content-size": "Sorry, dit formaat boek ondersteunen we nog niet",
                "default": "Sorry, probeer het opnieuw",
                "spine": "Sorry, dit aantal pagina's ondersteunen we op dit moment niet"
              },
              "loading": "Wordt geladen..."
            }
          }
        },
        "download": {
          "book": {
            "actions": {
              "create": "Maak sjabloon",
              "download": "Download sjabloon"
            },
            "field": {
              "content": {
                "highlight": "Selecteer een papiersoort voor het binnenwerk",
                "label": "Papiersoort binnenwerk"
              },
              "cover": {
                "highlight": "Selecteer de omslagpapiersoort",
                "label": "Papiersoort omslag"
              },
              "height": {
                "highlight": "Voer de boekhoogte in millimeters in",
                "label": "Hoogte",
                "placeholder": "Wetenschappelijk (bijv. 240 mm)"
              },
              "pages": {
                "highlight": "Voer het aantal pagina's van het boek in",
                "label": "Aantal pagina's",
                "placeholder": "bijv. 100"
              },
              "width": {
                "highlight": "Voer de breedte van het boek in",
                "label": "Breedte",
                "placeholder": "Wetenschappelijk (bijv. 170 mm)"
              }
            },
            "status": {
              "busy": "Maakt PDF sjabloon...",
              "error": "Sorry, probeer het opnieuw"
            }
          }
        }
      },
      "stripe": {
        "loading": "Aan het laden...",
        "type": {
          "bancontact": {
            "identifier": "Bancontact",
            "name": {
              "placeholder": "Voor en achternaam"
            }
          },
          "card": {
            "identifier": "Credit Card",
            "name": {
              "placeholder": "Naam kaarthouder"
            }
          },
          "ideal": {
            "identifier": "iDEAL"
          }
        }
      },
      "user": {
        "profile": {
          "actions": {
            "reset-password": "Wachtwoord opnieuw instellen",
            "sign-out": "Uitloggen"
          },
          "anonymous": {
            "actions": {
              "link": "Account aanmaken"
            },
            "description": "Beveilig je anonieme account met je emailadres en een wachtwoord zodat je later makkelijk verder kunt.",
            "header": "Anonieme gebruiker"
          },
          "email": "Email",
          "header": {
            "title": "Je profiel"
          },
          "password": "Wachtwoord",
          "reset-password": {
            "sent": "Email om je wachtwoord opnieuw in te stellen is verzonden"
          },
          "subscriptions": {
            "alerts": {
              "subscribed": "Bedankt voor het inschrijven voor onze nieuwsbief"
            },
            "dialogs": {
              "sign-out": {
                "cancel": "Annuleren",
                "confirm": "Uitloggen",
                "message": "Weet je zeker dat je wilt uitloggen?"
              },
              "unsubscribe": {
                "cancel": "Annuleren",
                "confirm": "Uitschrijven",
                "message": "Weet je zeker dat je je uit wilt schrijven van de nieuwsbrief?"
              }
            },
            "state": {
              "subscribed": "Ingeschreven",
              "unsubscribed": "Inschrijven"
            },
            "subscription": {
              "newsletter": "Nieuwsbrief"
            },
            "title": "Emails"
          }
        }
      }
    },
    "dialog": {
      "apply-product-parameters": {
        "actions": {
          "apply": "Veranderingen toepassen",
          "cancel": "Verwerpen"
        },
        "content": "Je hebt wijzigingen gemaakt die je omslagontwerp beinvloeden",
        "header": "Wijzigingen doorvoeren?"
      },
      "color-pages": {
        "actions": {
          "cancel": "Print alle pagina's in zwart wit",
          "mark": "Markeer pagina's"
        },
        "description": "Klik <span class=\"color\">kleur</span> om pagina's in kleur te drukken, of sla deze stap over om alle pagina's zwart wit af te drukken",
        "header": "Wil je pagina's in kleur drukken?"
      },
      "component-templates": {
        "actions": {
          "cancel": "Annuleren",
          "choose": "Kies sjabloon"
        },
        "header": "Kies een sjabloon voor de omslag"
      },
      "prefilled-color-pages": {
        "actions": {
          "cancel": "Naar de volgende stap",
          "mark": "Markeer pagina's"
        },
        "content": "Markeer pagina's om ze in <span class=\"color\">zwart wit</span> of <span class=\"color\">kleur</span> te drukken, of sla deze stap over om ze zo te drukken als ze geïdentificeerd zijn",
        "header": "We hebben {count} {count, plural, one {pagina} other {pagina's}} met kleur geïdentificeerd"
      },
      "unlock-product-parameters": {
        "actions": {
          "cancel": "Annuleren",
          "unlock": "Parameters ontgrendelen"
        },
        "content": {
          "description": "Je probeert een belangrijke parameter van je boek te bewerken. Deze parameter is cruciaal voor het definiëren van het omslagformaat. Je zult je omslagontwerp moeten aanpassen als je deze instelling wijzigt.",
          "help": "Ondersteuningssectie",
          "more": "Meer informatie over de belangrijkste parameters van je boek kun je vinden in onze <a href={externalChecklistURL} target=\"top\">{help}</a> sectie."
        },
        "header": "Parameters ontgrendelen?"
      },
      "upload-image": {
        "actions": {
          "cancel": "Annuleren",
          "upload": "Uploaden"
        },
        "header": "Afbeelding uploaden",
        "select": "Afbeelding selecteren",
        "uploading": "Upload gaande..."
      }
    },
    "email": {
      "order": {
        "client": {
          "description": "Fijn dat je {appname} hebt gekozen om jouw boek te drukken. We gaan direct voor je aan de slag om te zorgen dat je bestelling op tijd aankomt. Mocht je vragen hebben neem dan gerust contact met ons op!",
          "order": {
            "address": {
              "description": "We versturen de bestelling naar het volgende adres",
              "header": "Details verzending"
            },
            "assets": {
              "components": {
                "content": "Binnenwerk",
                "cover": "Omslag"
              },
              "description": "We drukken het boek op basis van de volgende bestanden",
              "download-pdf": "Download PDF",
              "header": "Jouw bestanden"
            },
            "date": "Datum bestelling",
            "delivery-date": "Verwachte leverdatum",
            "discount": "Inclusief korting van",
            "header": {
              "item": "Item",
              "price": "Prijs"
            },
            "id": "Bestelnummer",
            "payment-method": {
              "header": "Betaalwijze",
              "methods": {
                "bancontact": "Bancontact",
                "card": "Credit Card",
                "ideal": "iDEAL"
              }
            },
            "questions": {
              "description": "Mocht je vragen hebben, voel je dan vrij contact met ons op te nemen op {link}.",
              "header": "Vragen"
            },
            "shipping": "Verzending",
            "title": "Jouw bestelling",
            "total": "Totaal",
            "vat": "Inclusief {percent}% BTW van"
          },
          "product": {
            "book": {
              "components": {
                "content": {
                  "header": "Binnenzijde",
                  "pages": {
                    "color": {
                      "indexes": "Pagina's in kleur",
                      "total": "Pagina's in kleur: {count} {count, plural, one {pagina} other {pagina's}}"
                    },
                    "total": "Pagina's"
                  },
                  "paper": "Materiaal",
                  "size": "Formaat"
                },
                "cover": {
                  "finish": "Afwerking",
                  "header": "Omslag",
                  "paper": "Materiaal"
                }
              },
              "count": "Bestelde hoeveelheid",
              "header": "Boek"
            },
            "bookmark": {
              "components": {
                "cover": {
                  "finish": "Afwerking",
                  "header": "Design",
                  "paper": "Materiaal"
                }
              },
              "count": "Bestelde hoeveelheid",
              "header": "Boekenlegger"
            },
            "custom": {
              "book": {
                "header": "Boek"
              },
              "count": "Besteld aantal",
              "poster": {
                "header": "Poster"
              },
              "sticker": {
                "header": "Sticker"
              }
            },
            "pages": {
              "components": {
                "content": {
                  "header": "Inhoud",
                  "pages": {
                    "color": {
                      "indexes": "Pagina's in kleur",
                      "total": "Pagina's in kleur: {count} {count, plural, one {pagina} other {pagina's}}"
                    },
                    "total": "pagina's"
                  },
                  "paper": "Materiaal",
                  "size": "Formaat"
                }
              },
              "count": "Bestelde hoeveelheid",
              "header": "Losse pagina's"
            }
          },
          "subject": "Bedankt voor jouw bestelling",
          "title": "Bedankt"
        }
      },
      "password-reset": {
        "action": "Wachtwoord opnieuw instellen",
        "directions": "Klik op 'Wachtwoord opnieuw instellen' om jouw %APP_NAME% wachtwoord {link} opnieuw in te stellen.",
        "hello": "Hi,",
        "ignore": "Als je geen wachtwoord herstel hebt aangevraagd kun je deze email negeren.",
        "subject": "Stel jouw wachtwoord opnieuw in voor for %APP_NAME%",
        "team": "Het %APP_NAME% team",
        "thanks": "Bedankt,"
      },
      "welcome": {
        "appname": "Welkom bij {appname}!",
        "hello": "Hi,",
        "products": "Je kunt je boeken altijd terugvinden op {products}.",
        "subject": "Welkom bij {appname}"
      }
    },
    "input": {
      "color-picker": {
        "reset": "Herstellen"
      },
      "file": {
        "drag": "Of sleep het hier naartoe",
        "selected": "Geselecteerd",
        "title": "Bestand kiezen"
      }
    },
    "locale": {
      "en-us": "en",
      "nl": "nl"
    },
    "model": {
      "alerts": {
        "mobile": {
          "label": "Deze tool werkt het best op desktops"
        }
      },
      "assets": {
        "pdf": {
          "runtime": {
            "color-types": {
              "bw": {
                "description": "Pagina's in zwart wit",
                "label": "Zwart wit",
                "short": "ZW"
              },
              "color": {
                "description": "Pagina's in kleur",
                "label": "Kleur",
                "short": "kleur"
              }
            }
          }
        }
      },
      "product": {
        "definition": {
          "book": {
            "onboarding": {
              "message": "Maakt een boek van \"{name}\""
            }
          },
          "pages": {
            "onboarding": {
              "message": "Maakt losse pagina's van \"{name}\""
            }
          }
        }
      },
      "sketch": {
        "base": {
          "stage": {
            "name": "Ontwerp",
            "node": {
              "area": {
                "identifier": {
                  "back": "Achterzijde omslag",
                  "back-background": "Achter de achtergrond",
                  "back-content": "Achter content",
                  "background": "Achtergrond",
                  "content": "Binnenwerk",
                  "front": "Voorzijde omslag",
                  "front-background": "Voor achtergrond",
                  "front-content": "Voor content",
                  "spine": "Rug"
                },
                "name": "Veld"
              },
              "image": {
                "name": "Afbeelding"
              },
              "rect": {
                "name": "Rechthoek"
              },
              "text": {
                "name": "Tekst"
              }
            }
          }
        },
        "strings": {
          "edit": "Bewerken",
          "loading-font": "Lettertype aan het laden..."
        }
      },
      "templates": {
        "book-cover": {
          "title": "Omslag"
        },
        "bookmark-cover": {
          "title": "Boekenlegger"
        }
      },
      "upload": {
        "errors": {
          "dimensions": "De geüploade PDF moet de volgende dimensies hebben:  {dimensions}",
          "page-count": "De geüploade PDF moet ten minste een pagina hebben",
          "trim-distance": "De geüploade PDF moet {dimensions} met {crop} ruimte hebben aan alle zijden rondom de trimbox"
        }
      },
      "users": {
        "user": {
          "cart": {
            "coupon": {
              "count": "Deze coupon geldt alleen voor bestellingen met 1 boek",
              "internal": "Deze coupon is ongeldig",
              "items-count": "Deze coupon geldt alleen voor bestellingen die bestaan uit 1 product",
              "pages-250": "Deze coupon geldt alleen voor boeken van maximaal 250 paginas",
              "pages-400": "Deze coupon geldt alleen voor boeken van maximaal 400 paginas",
              "pages-color": "Deze coupon geldt alleen voor boeken die volledig in zwart wit gedrukt worden",
              "pages-color-30": "Deze coupon geldt alleen voor boeken met maximaal 30 pagina's in kleur"
            },
            "items": {
              "item": {
                "types": {
                  "book": {
                    "count": {
                      "description": "Aantal boeken",
                      "value": "{count} {count, plural, one {Boek} other {Boeken}}"
                    },
                    "plural": "Boeken",
                    "singular": "Boek"
                  },
                  "bookmark": {
                    "count": {
                      "description": "Aantal boekenleggers",
                      "value": "{count} {count, plural, one {Boekenlegger} other {Boekenleggers}}"
                    },
                    "plural": "Boekenleggers",
                    "singular": "Boekenleggers"
                  },
                  "pages": {
                    "count": {
                      "description": "Aantal losse pagina producten",
                      "value": "{count} {count, plural, one {losse pagina's} other {losse pagina's}}"
                    },
                    "plural": "Losse pagina's",
                    "singular": "Losse pagina's"
                  },
                  "poster": {
                    "count": {
                      "description": "Aantal posters",
                      "value": "{count} {count, plural, one {Poster} other {Posters}}"
                    },
                    "plural": "Posters",
                    "singular": "Poster"
                  },
                  "sticker": {
                    "count": {
                      "description": "Aantal stickers",
                      "value": "{count} {count, plural, one {Sticker} other {Stickers}}"
                    },
                    "plural": "Stickers",
                    "singular": "Sticker"
                  }
                }
              }
            }
          },
          "products": {
            "book": {
              "flow": {
                "content": "Kleurinstellingen",
                "cover": "Omslag ontwerpen",
                "parameters": "Specificaties",
                "source": "PDF uploaden",
                "summary": "Samenvatting"
              },
              "title": "Boek"
            },
            "bookmark": {
              "flow": {
                "cover": "Ontwerpen",
                "parameters": "Specificaties",
                "summary": "Samenvatting"
              },
              "title": "Boekenlegger"
            },
            "components": {
              "book": {
                "content": {
                  "page-count": "{pageCount} {pageCount, plural, one {pagina} other {pagina's}}",
                  "page-count-summary": "{pageCount} {pageCount, plural, one {pagina} other {pagina's}} in {colorType, select, bw {zwart wit} color {kleur} other {unknown}}",
                  "title": "Inhoud"
                },
                "cover": {
                  "description": {
                    "design": "Omslagontwerp gemaakt",
                    "invalid-upload": "Check je omslagontwerp",
                    "missing": "Maak je eigen omslagontwerp",
                    "missing-upload": "Upload je omslagontwerp",
                    "valid-upload": "Omslagontwerp geüpload"
                  },
                  "title": "Omslag"
                }
              },
              "pages": {
                "content": {
                  "page-count": "{pageCount} {pageCount, plural, one {pagina} other {pagina's}}",
                  "page-count-summary": "{pageCount} {pageCount, plural, one {pagina} other {pagina's}} in {colorType, select, bw {zwart wit} color {full color} other {unknown}}",
                  "title": "Content"
                }
              }
            },
            "custom": {
              "flow": {
                "summary": "Samenvatting"
              }
            },
            "pages": {
              "flow": {
                "content": "Markeer pagina's",
                "parameters": "Specificaties",
                "source": "PDF Uploaden",
                "summary": "Samenvatting"
              },
              "title": "Losse pagina's"
            },
            "product": {
              "pricing": {
                "description": {
                  "attention": "Attentie",
                  "loading": "Aan het laden..."
                }
              }
            }
          }
        }
      }
    },
    "route": {
      "account": {
        "errors": {
          "email-already-in-use": "Dit emailadres wordt al gebruikt voor een andere account.",
          "user-not-found": "We hebben geen gebruiker met deze gegevens gevonden. Mogelijk is de gebruiker verwijderd.",
          "weak-password": "Wachtwoorden moeten in ieder geval 6 tekens bevatten",
          "wrong-password": "Het wachtwoord is ongeldig"
        },
        "link": {
          "action": "Account beveiligen",
          "description": "Bevelig je anonieme account met je emailadres en wachtwoord om je boeken later makkelijk terug te vinden.",
          "placeholder": {
            "email": "Je emailadres",
            "password": "Kies een wachtwoord"
          },
          "title": "Account aanmaken"
        },
        "new": {
          "action": {
            "sign-in": "Log in",
            "sign-up": "Aanmelden"
          },
          "anonymous": "Anoniem verder gaan",
          "description": "Log in of maak een account aan om te zorgen dat je werk doorlopend opgeslagen wordt en het altijd makkelijk toegankelijk is. Accounts zijn altijd gratis.",
          "forgot": "Wachtwoord vergeten?",
          "or": "of",
          "placeholder": {
            "email": "Je emailadres",
            "password": "Kies een wachtwoord"
          },
          "title": "Aanmelden voor een account"
        },
        "reset": {
          "action": "Wachtwoord opnieuw instellen",
          "cancel": "Annuleren",
          "or": "of",
          "placeholder": {
            "email": "Je emailadres"
          },
          "title": "Wachtwoord opnieuw instellen"
        },
        "restore": {
          "action": "Inloggen",
          "forgot": "Wachtwoord vergeten?",
          "no-account": "Nog geen account?",
          "placeholder": {
            "email": "Je emailadres",
            "password": "Kies een wachtwoord"
          },
          "title": "Inloggen"
        }
      },
      "backend": {
        "index": {
          "actions": {
            "localization": "Taalinstellingen",
            "orders": "Bestellingen",
            "stylebook": "Stijlen",
            "subscriptions": "Emails",
            "templates": "Sjablonen",
            "xml": "XML"
          },
          "title": "Backend"
        },
        "orders": {
          "range": {
            "index": {
              "header": "Bestellingen",
              "orders": {
                "placeholder": "Geen bestellingen"
              },
              "summary": {
                "products": {
                  "count": "{count} besteld",
                  "custom": "Custom {type}"
                },
                "total": {
                  "orders": "Totaal # bestellingen",
                  "products": "Totaal # producten verkocht",
                  "total": "Totaal (incl. BTW en verzendkosten)"
                }
              }
            }
          }
        },
        "subscriptions": {
          "download": "Download",
          "loading": "CSV wordt gegenereerd..."
        },
        "templates": {
          "index": {
            "actions": {
              "new": "Nieuw sjabloon maken"
            },
            "header": {
              "title": "Template typen"
            },
            "placeholder": "Er zijn nog geen sjablonen gemaakt voor dit type"
          },
          "type": {
            "index": {
              "actions": {
                "new": "Nieuwe template maken"
              },
              "placeholder": "Er zijn nog geen templates voor dit type product"
            },
            "new": {
              "actions": {
                "cancel": "Annuleren",
                "save": "Opslaan"
              },
              "label": {
                "description": "Omschrijving",
                "title": "Titel"
              }
            },
            "template": {
              "edit": {
                "actions": {
                  "cancel": "Annuleren",
                  "save": "Opslaan"
                },
                "header": "Sjabloondetails",
                "label": {
                  "description": "Omschrijving",
                  "position": "Positie",
                  "state": "Status",
                  "title": "Titel"
                }
              },
              "preview": {
                "edit": {
                  "actions": {
                    "cancel": "Annuleren",
                    "preview": "Voorbeeld"
                  },
                  "label": {
                    "crop-size": "Positie snijlijnen (mm)",
                    "page-height": "Hoogte pagina (mm)",
                    "page-width": "Breedte pagina (mm)",
                    "spine-width": "Breedte rug (mm)"
                  },
                  "title": "Sjabloon voorbeeld"
                }
              }
            }
          }
        },
        "xml": {
          "actions": {
            "fetch": "Haal op",
            "upload": "Upload"
          },
          "identifier": "Bestelnummer",
          "title": "XML Export",
          "upload": {
            "confirmation": {
              "cancel": "Annuleren",
              "commit": "Order plaatsen",
              "message": "Weet je zeker dat je de order wilt plaatsen?"
            },
            "status": "Upload status"
          }
        }
      },
      "definitions": {
        "index": {
          "header": {
            "title": "Selecteer het type product"
          }
        }
      },
      "error": {
        "title": "Er is iets verkeerd gegaan"
      },
      "index": {
        "upload": {
          "create-book": "Maak jouw boek",
          "select-pdf": "Selecteer een PDF bestand",
          "title": "Upload het binnenwerk (.pdf bestand) om te beginnen"
        }
      },
      "maintenance": {
        "title": "Sorry, over een paar minuten zijn we weer online"
      },
      "missing": {
        "title": "Pagina niet gevonden"
      },
      "onboarding": {
        "process": {
          "error": {
            "retry": "Probeer opnieuw",
            "title": "Sorry, er is iets mis gegaan"
          },
          "step": {
            "processing": "PDF paginas worden verwerkt. Dit kan enkele minuten duren.",
            "uploading": "PDF wordt geupload..."
          },
          "title": "{message}…"
        }
      },
      "standalone": {
        "add": {
          "token": {
            "error": "Sorry, er is iets mis gegaan",
            "loading": "Producten worden aan het winkelwagentje toegevoegd..."
          }
        }
      },
      "users": {
        "user": {
          "cart": {
            "loading": "Winkelwagen aan het laden"
          },
          "orders": {
            "order": {
              "index": {
                "done": "Voltooid",
                "error": {
                  "support": "Neem contact op met support via: <a href=\"mailto:{support}\">{support}</a>."
                },
                "header": {
                  "error": "Er is iets verkeerd gegaan.",
                  "failed": "De betaling is mislukt. Probeer het opnieuw. Er is geen geld van je rekening afgeschreven.",
                  "pending": "Even geduld. We verwerken je betaling...",
                  "success": "Gelukt!"
                },
                "retry": "Probeer opnieuw",
                "success": {
                  "delivery": "Levering staat gepland voor: <span class=\"date\">{date}</span>",
                  "email": "Je ontvangt over enkele minuten een email met een bevestiging en alle informatie van je bestelling op <span class=\"email\">{email}</span>",
                  "enjoy": "Veel plezier met je boeken!",
                  "identifier": "Je order id is: <span class=\"identifier\">{identifier}</span>",
                  "questions": "Als je vragen hebt kun je ons bereiken op: <a href=\"mailto:{support}\">{support}</a>"
                }
              }
            }
          },
          "products": {
            "index": {
              "delete": {
                "cancel": "Annuleren",
                "delete": "Dit boek verwijderen",
                "message": "Weet je zeker dat je het boek '{name}' wilt verwijderen?"
              },
              "tabs": {
                "products": "Producten",
                "profile": "Profiel"
              }
            },
            "loading": "Producten aan het laden",
            "product": {
              "delete": {
                "title": "<span class=\"strong\">\"{name}\"</span> wordt verwijdert"
              },
              "index": {
                "actions": {
                  "delete": "Verwijder dit product"
                },
                "errors": {
                  "pdf-load": "Fout tijdens het laden van de PDF"
                },
                "title": "Sorry er is iets mis gegaan"
              },
              "loading": "Product aan het laden",
              "step": {
                "loading": "Stap aan het laden"
              }
            }
          }
        }
      }
    },
    "settings": {
      "book": {
        "paper": {
          "content": {
            "hvo-wit-100": {
              "description": "Premium",
              "name": "Houtvrij, Wit, 100 grs"
            },
            "hvo-wit-120": {
              "description": "Extra zwaar",
              "name": "Houtvrij, Wit, 120 grs"
            },
            "hvo-wit-80": {
              "description": "Standaard",
              "name": "Houtvrij, Wit, 80 grs"
            },
            "hvo-wit-90": {
              "description": "Standaard",
              "name": "Houtvrij, Wit, 90 grs"
            },
            "machine-coated-115": {
              "description": "Zwaar",
              "name": "Machine Coated, 115 grs"
            },
            "roman-90": {
              "description": "Standaard",
              "name": "Romandruk, 90 grs"
            }
          },
          "cover": {
            "houtvrij-machine-coated-170": {
              "description": "Flexibel",
              "name": "Houtvrij Machine gecoat, 170 gr"
            },
            "machine-coated-300": {
              "description": "Stevig",
              "name": "Machine Coated, 300 gr"
            },
            "sulfaatkarton-250": {
              "description": "Stevig",
              "name": "Sulfaatkarton, 250 gr"
            }
          }
        },
        "size": {
          "large": "Groot",
          "medium": "Middel",
          "small": "Klein",
          "xl": "XL"
        }
      },
      "bookmark": {
        "paper": {
          "cover": {
            "machine-coated-200": {
              "description": "Standaard",
              "name": "Machine Coated, 200 gr"
            },
            "machine-coated-300": {
              "description": "Standaard",
              "name": "Machine Coated, 300 gr"
            },
            "sulfaatkarton-250": {
              "description": "Standaard",
              "name": "Sulfate Cardboard, 250 gr"
            }
          }
        }
      },
      "countries": {
        "be": "België",
        "de": "Duitsland",
        "nl": "Nederland"
      },
      "pages": {
        "paper": {
          "content": {
            "hvo-wit-100": {
              "description": "Premium",
              "name": "Wood Free, White, 100 grs"
            },
            "hvo-wit-120": {
              "description": "Extra zwaar",
              "name": "Wood Free, White, 120 grs"
            },
            "hvo-wit-80": {
              "description": "Standaard",
              "name": "Wood Free, White, 80 grs"
            }
          }
        }
      }
    },
    "stylebook": {
      "block": "Block",
      "button": "Knop",
      "card": "Kaart",
      "cats-craddle": "Kattenbox",
      "checkbox": "Checkbox",
      "color-picker": "Kleurpalet",
      "colors": "Kleuren",
      "container": "Container",
      "content": "inhoud",
      "cover": "omslag",
      "custom": "Uniek",
      "default": "Standaard",
      "disabled": "Uitgeschakeld",
      "display-titles": "Titels weergeven",
      "dropdown": "Dropdown",
      "dummy": "NL - Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      "file": "Bestand",
      "font-awesome": "Font Awesome",
      "heading": "Kop",
      "headings": "Koppen",
      "icons": "Icoontjes",
      "info": "Info",
      "input": "Invoer",
      "invalid": "Ongeldig",
      "lead": "Leiding",
      "many-items": "Veel items",
      "medium": "Gemiddeld",
      "minimal": "Minimaal",
      "normal": "Normaal",
      "outline": "Omtrek",
      "padded": "Met ruimte",
      "pair": "Paar",
      "pill": "Pil",
      "placeholder": "Tijdelijke aanduiding",
      "progress-bar": "Voortgangsbalk",
      "prominent": "Prominent",
      "regular": "Regulier",
      "regular-with-icons": "Regulier met iconen",
      "segmented-button": "Gesegmenteerde knop",
      "shape": "Vorm",
      "sizes": "Maten",
      "sizes-with-icons": "Maten met iconen",
      "slider": "Schuif",
      "small": "Klein",
      "small-with-icons": "Klein met iconen",
      "summary": "samenvatting",
      "textarea": "Tekstvlak",
      "this-is-boekdrukken": "Dit is Boekdrukken",
      "tiniest": "Kleinste",
      "tiniest-with-icons": "Kleinste met icoon",
      "tiny": "Klein",
      "tiny-with-icons": "Klein met iconen",
      "title": "Titel",
      "tools": "Hulpmiddelen",
      "typography": "Typografie",
      "upload": "upload",
      "validation": "Validatie",
      "value": "Waarde",
      "warning": "Regulier",
      "wip": "WIP"
    }
  };
  _exports.default = _default;
});