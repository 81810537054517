define("boekdrukken/components/ui-block/cart/content/total/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-cart-content-total'],
    cart: null,
    items: Ember.computed.readOnly('cart.items.ordered')
  });

  _exports.default = _default;
});