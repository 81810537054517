define("boekdrukken/components/ui-block/json/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-json'],
    string: Ember.computed('value', function () {
      var value = this.get('value');

      try {
        return JSON.stringify(value, null, 2);
      } catch (err) {
        return err.message;
      }
    })
  }).reopenClass({
    positionalParams: ['value']
  });

  _exports.default = _default;
});