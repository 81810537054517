define("boekdrukken/services/storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    object: Ember.computed(function () {
      return Object.create(null);
    }).readOnly(),
    store: function store(key, value) {
      var _this = this;

      this.delete(key);
      this.object[key] = value;
      return function () {
        return _this.delete(key);
      };
    },
    retrieve: function retrieve(key) {
      return this.object[key];
    },
    delete: function _delete(key) {
      var value = this.object[key];

      if (value) {
        delete this.object[key];
        return true;
      }

      return false;
    }
  });

  _exports.default = _default;
});