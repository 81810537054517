define("boekdrukken/components/ui-route/missing/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yjLN02XG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[1,[28,\"t\",[\"route.missing.title\"],null],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"path\"],[8],[1,[22,\"path\"],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-route/missing/template.hbs"
    }
  });

  _exports.default = _default;
});