define("boekdrukken/util/is-development", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.inDevelopment = _exports.default = void 0;

  var byEnvironment = function byEnvironment(env) {
    return env.environment === 'development' || env.boekdrukken.environment === 'development';
  };

  var inDevelopment = function inDevelopment(fn) {
    return function (app) {
      var dev = byEnvironment(app.factoryFor('config:environment').class);

      if (!dev) {
        return;
      }

      fn(app);
    };
  };

  _exports.inDevelopment = inDevelopment;
  var _default = byEnvironment;
  _exports.default = _default;
});