define("boekdrukken/components/ui-route/internal/render/pdf/component", ["exports", "ember-cli-sketch/util/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-route-internal-render-pdf'],
    size: Ember.computed('model.dimensions', function () {
      var dimensions = this.model.dimensions;
      return (0, _object.millimetersToPixels)(dimensions);
    }).readOnly(),
    actions: {
      ready: function ready(stage) {
        stage.fit({
          type: 'containers',
          offset: 0
        });
      }
    }
  });

  _exports.default = _default;
});