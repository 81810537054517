define("boekdrukken/util/book-cover", ["exports", "mathjs"], function (_exports, _mathjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.crop = _exports.calculateDimensions = void 0;

  var round = function round(value) {
    return _mathjs.default.round(value, 2);
  };

  var crop = 5; // dimensions are in mm
  // paper: users/user/products/settings/book/paper/content
  // thickness: users/user/products/settings/book/thickness

  _exports.crop = crop;

  var calculateDimensions = function calculateDimensions(_ref) {
    var _ref$dimensions = _ref.dimensions,
        width = _ref$dimensions.width,
        height = _ref$dimensions.height,
        pageCount = _ref.pageCount,
        thickness = _ref.paper.thickness,
        base = _ref.thickness.base;
    var roundedPageCount = pageCount % 2 === 1 ? pageCount + 1 : pageCount;
    var spine = round(_mathjs.default.evaluate('(thickness * roundedPageCount) + base', {
      thickness: thickness,
      roundedPageCount: roundedPageCount,
      base: base
    }));
    return {
      page: {
        width: width,
        height: height
      },
      spine: spine,
      crop: crop,
      total: {
        width: crop + width + spine + width + crop,
        height: crop + height + crop
      }
    };
  };

  _exports.calculateDimensions = calculateDimensions;
});