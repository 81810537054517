define("boekdrukken/components/ui-block/product/book/step/content/grid/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I37cnWgH",
    "block": "{\"symbols\":[\"fill\"],\"statements\":[[4,\"ui-block/fill\",null,[[\"class\",\"padding\"],[\"fill\",[28,\"hash\",null,[[\"width\",\"height\"],[320,187]]]]],{\"statements\":[[0,\"  \"],[1,[28,\"ui-block/pdf/grid\",null,[[\"pdf\",\"filter\",\"container\",\"columns\"],[[24,[\"pdf\"]],[24,[\"filter\"]],[23,1,[\"size\"]],[24,[\"columns\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/product/book/step/content/grid/template.hbs"
    }
  });

  _exports.default = _default;
});