define("boekdrukken/components/ui-block/sketch/inspector/section/fit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var label = function label(key) {
    return "block.sketch.inspector.section.fit.choices.".concat(key);
  };

  var items = [{
    label: label('fill'),
    value: false
  }, {
    label: label('contain'),
    value: true
  }];

  var _default = Ember.Component.extend({
    classNameBindings: [':fit'],
    items: items,
    selected: Ember.computed('items', 'model.fit', function () {
      var value = this.model.fit;
      return this.items.findBy('value', value);
    }).readOnly(),
    actions: {
      updateFit: function updateFit(_ref) {
        var value = _ref.value;
        this.update('fit', value);
      }
    }
  });

  _exports.default = _default;
});