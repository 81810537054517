define("boekdrukken/models/users/user/products/parameters/book/cover", ["exports", "boekdrukken/models/users/user/products/parameters/book/-parameters"], function (_exports, _parameters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _parameters.default.extend({
    finish: (0, _parameters.parameter)('cover/finish'),
    paper: (0, _parameters.parameter)('cover/paper'),
    changed: (0, _parameters.changed)('finish', 'paper')
  });

  _exports.default = _default;
});