define("boekdrukken/components/ui-application/alerts/component", ["exports", "design/util/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-application-alerts', '_type'],
    _type: (0, _computed.className)({
      key: 'type',
      value: 'regular'
    }),
    alerts: Ember.inject.service(),
    visible: Ember.computed.readOnly('alerts.visible'),
    alert: Ember.computed.readOnly('visible.firstObject')
  });

  _exports.default = _default;
});