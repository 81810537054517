define("boekdrukken/components/ui-application/header/accessory/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-application-header-accessory', 'accessory:visible:hidden'],
    accessories: Ember.inject.service(),
    items: Ember.computed.readOnly('accessories.items'),
    position: null,
    accessory: Ember.computed('position', 'items.@each.{left,right,wide}', function () {
      var items = this.items,
          position = this.position;
      var last = Ember.A(items.filter(function (item) {
        var accessory = item[position];
        return accessory === null || !!accessory;
      })).lastObject;
      return last && last[position];
    }).readOnly()
  });

  _exports.default = _default;
});