define("boekdrukken/components/ui-block/definition/custom/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1d8HJ17P",
    "block": "{\"symbols\":[\"hash\"],\"statements\":[[4,\"ui-block/container\",null,[[\"type\",\"class\"],[\"centered\",\"master\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"block.definition.custom.title\"],null],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[28,\"t\",[\"block.definition.custom.type\"],null],false],[9],[0,\"\\n\"],[4,\"ui-input/dropdown\",null,[[\"items\",\"selected\",\"select\"],[[24,[\"types\"]],[24,[\"type\"]],[28,\"action\",[[23,0,[]],\"selectType\"],null]]],{\"statements\":[[0,\"        \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"component\"]],\"expected `hash.component` to be a contextual component but found a string. Did you mean `(component hash.component)`? ('boekdrukken/components/ui-block/definition/custom/template.hbs' @ L7:C10) \"],null]],[[\"value\"],[[28,\"t\",[[28,\"concat\",[\"block.product.custom.step.summary.details.types.\",[23,1,[\"item\",\"type\"]]],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[28,\"t\",[\"block.definition.custom.name\"],null],false],[9],[0,\"\\n      \"],[1,[28,\"ui-input/text\",null,[[\"value\",\"update\"],[[24,[\"name\"]],[28,\"action\",[[23,0,[]],\"updateName\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n      \"],[1,[28,\"ui-input/button\",null,[[\"value\",\"action\",\"disabled\"],[[28,\"t\",[\"block.definition.custom.create\"],null],[28,\"action\",[[23,0,[]],\"create\"],null],[24,[\"isActionDisabled\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/definition/custom/template.hbs"
    }
  });

  _exports.default = _default;
});