define("boekdrukken/components/ui-route/backend/templates/type/template/design/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var switchChoices = [{
    label: 'Background',
    identifiers: ['background'],
    visible: true
  }, {
    label: 'Content',
    identifiers: ['back', 'spine', 'front']
  }];

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-route-backend-templates-type-template-design'],
    template: null,
    // models/templates/<type>/template
    sketch: Ember.computed.readOnly('template.design'),
    stage: Ember.computed.readOnly('sketch.stage'),
    switchChoices: switchChoices,
    actions: {
      ready: function ready(stage) {
        stage.fit();
      }
    }
  });

  _exports.default = _default;
});