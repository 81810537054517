define("boekdrukken/components/ui-block/stripe/types/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xdVW0yzE",
    "block": "{\"symbols\":[\"type\"],\"statements\":[[4,\"each\",[[24,[\"payment\",\"types\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[28,\"concat\",[\"ui-block/stripe/types/\",[23,1,[\"identifier\"]]],null]],[[\"payment\",\"type\"],[[24,[\"payment\"]],[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/stripe/types/template.hbs"
    }
  });

  _exports.default = _default;
});