define("boekdrukken/components/ui-block/sketch/inspector/section/font/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2kKb7vrS",
    "block": "{\"symbols\":[\"hash\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[28,\"t\",[\"block.sketch.inspector.section.font.family\"],null],false],[9],[0,\"\\n\"],[4,\"ui-input/dropdown\",null,[[\"size\",\"items\",\"selected\",\"select\"],[\"small\",[24,[\"fonts\"]],[24,[\"selected\"]],[28,\"action\",[[23,0,[]],\"updateFontFamily\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"component\"]],\"expected `hash.component` to be a contextual component but found a string. Did you mean `(component hash.component)`? ('boekdrukken/components/ui-block/sketch/inspector/section/font/template.hbs' @ L4:C6) \"],null]],[[\"value\"],[[23,1,[\"item\",\"value\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[28,\"t\",[\"block.sketch.inspector.section.font.size\"],null],false],[9],[0,\"\\n  \"],[1,[28,\"ui-input/text\",null,[[\"size\",\"value\",\"update\"],[\"small\",[24,[\"model\",\"fontSize\"]],[28,\"action\",[[23,0,[]],\"updateFontSize\",[24,[\"update\"]],\"fontSize\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[28,\"t\",[\"block.sketch.inspector.section.font.line-height\"],null],false],[9],[0,\"\\n  \"],[1,[28,\"ui-input/text\",null,[[\"size\",\"value\",\"update\"],[\"small\",[24,[\"model\",\"lineHeight\"]],[28,\"action\",[[23,0,[]],\"updateLineHeight\",[24,[\"update\"]],\"lineHeight\"],null]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/inspector/section/font/template.hbs"
    }
  });

  _exports.default = _default;
});