define("boekdrukken/routes/stylebook/flow", ["exports", "ember-cli-zuglet/lifecycle", "design/flow", "boekdrukken/routes/-breadcrumb", "boekdrukken/routes/-accessory"], function (_exports, _lifecycle, _flow, _breadcrumb, _accessory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_breadcrumb.default, _accessory.default, {
    model: (0, _lifecycle.route)().inline({
      breadcrumb: (0, _breadcrumb.breadcrumb)({
        title: 'Flow',
        action: function action(transitionTo) {
          return transitionTo('stylebook.flow');
        }
      }),
      accessories: (0, _accessory.accessories)({
        wide: (0, _accessory.accessory)('flow', {
          component: 'ui-block/accessory/flow/steps',
          model: function model(_ref) {
            var flow = _ref.flow;
            return flow;
          }
        })
      }),
      flow: (0, _flow.flow)({
        upload: (0, _flow.step)({
          title: 'Upload PDF',
          // also computed
          route: {
            path: 'stylebook.flow.upload'
          }
        }),
        parameters: (0, _flow.step)({
          title: 'Required Parameters',
          // badge: null,
          enabled: true,
          route: {
            path: 'stylebook.flow.params'
          }
        }),
        content: (0, _flow.step)({
          title: 'Mark Pages',
          // badge: null,
          enabled: Ember.computed.readOnly('owner.areStepsEnabled'),
          route: {
            path: 'stylebook.flow.content'
          }
        }),
        cover: (0, _flow.step)({
          title: 'Create Cover',
          // badge: null,
          enabled: Ember.computed.readOnly('owner.areStepsEnabled'),
          route: {
            path: 'stylebook.flow.cover'
          }
        }),
        summary: (0, _flow.step)({
          title: 'Summary',
          badge: Ember.computed.readOnly('owner.price'),
          enabled: Ember.computed.readOnly('owner.areStepsEnabled'),
          route: {
            path: 'stylebook.flow.summary'
          }
        })
      }),
      areStepsEnabled: false,
      price: "\u20AC 20.00",
      prepare: function prepare() {}
    })
  });

  _exports.default = _default;
});