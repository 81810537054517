define("boekdrukken/components/ui-block/sketch/inspector/section/align/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var h = function h(key) {
    return "block.sketch.inspector.section.align.horizontal.choices.".concat(key);
  };

  var v = function v(key) {
    return "block.sketch.inspector.section.align.vertical.choices.".concat(key);
  };

  var choices = {
    align: [{
      label: h('left'),
      value: 'left'
    }, {
      label: h('center'),
      value: 'center'
    }, {
      label: h('right'),
      value: 'right'
    }, {
      label: h('justify'),
      value: 'justify'
    }],
    vertical: [{
      label: v('top'),
      value: 'top'
    }, {
      label: v('middle'),
      value: 'middle'
    }, {
      label: v('bottom'),
      value: 'bottom'
    }]
  };

  var _default = Ember.Component.extend({
    classNameBindings: [':align'],
    choices: choices
  });

  _exports.default = _default;
});