define("boekdrukken/util/string", ["exports", "boekdrukken/util/round"], function (_exports, _round) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toInteger = _exports.titleify = _exports.pluralize = _exports.notBlank = _exports.nbsp = _exports.isString = _exports.dimensions = _exports.cm = _exports.capitalize = void 0;

  var capitalize = function capitalize(string) {
    var first = string.slice(0, 1).toUpperCase();
    var remaining = string.slice(1);
    return "".concat(first).concat(remaining);
  };

  _exports.capitalize = capitalize;

  var titleify = function titleify(string) {
    string = string.replace(/\.\w+$/, '');
    string = string.replace(/_|\./g, ' ');
    string = capitalize(string);
    return string;
  };

  _exports.titleify = titleify;

  var pluralize = function pluralize(count, singular, plural) {
    if (Ember.isNone(count)) {
      return;
    }

    if (count === 1) {
      return singular;
    }

    return plural;
  };

  _exports.pluralize = pluralize;

  var nbsp = function nbsp(string) {
    return string.replace(/ /g, "\xA0");
  };

  _exports.nbsp = nbsp;

  var toInteger = function toInteger(string) {
    if (!string) {
      return;
    }

    var int = parseInt(string);

    if (isNaN(int)) {
      return;
    }

    return int;
  };

  _exports.toInteger = toInteger;

  var dimension = function dimension(value, unit) {
    if (unit === 'mm') {
      value = (0, _round.default)(value / 10, 2);
      unit = 'cm';
    }

    return "".concat(value).concat(unit);
  };

  var dimensions = function dimensions(width, height, unit) {
    if (Ember.isNone(width) || Ember.isNone(height)) {
      return;
    }

    return nbsp("".concat(dimension(width, unit), " \xD7 ").concat(dimension(height, unit)));
  };

  _exports.dimensions = dimensions;

  var isString = function isString(value) {
    return typeof value === 'string';
  };

  _exports.isString = isString;

  var notBlank = function notBlank(value) {
    return isString(value) && value.trim().length > 0;
  };

  _exports.notBlank = notBlank;

  var cm = function cm(value) {
    return "".concat((0, _round.default)(value / 10, 2), "cm");
  };

  _exports.cm = cm;
});