define("boekdrukken/components/ui-block/sketch/inspector/section/autosizing/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B0t7M52D",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[1,[28,\"ui-input/checkbox\",null,[[\"class\",\"size\",\"checked\",\"update\"],[\"edge top\",\"small\",[24,[\"autosizing\",\"top\"]],[28,\"action\",[[23,0,[]],\"update\",\"top\"],null]]]],false],[0,\"\\n  \"],[1,[28,\"ui-input/checkbox\",null,[[\"class\",\"size\",\"checked\",\"update\"],[\"edge bottom\",\"small\",[24,[\"autosizing\",\"bottom\"]],[28,\"action\",[[23,0,[]],\"update\",\"bottom\"],null]]]],false],[0,\"\\n  \"],[1,[28,\"ui-input/checkbox\",null,[[\"class\",\"size\",\"checked\",\"update\"],[\"edge left\",\"small\",[24,[\"autosizing\",\"left\"]],[28,\"action\",[[23,0,[]],\"update\",\"left\"],null]]]],false],[0,\"\\n  \"],[1,[28,\"ui-input/checkbox\",null,[[\"class\",\"size\",\"checked\",\"update\"],[\"edge right\",\"small\",[24,[\"autosizing\",\"right\"]],[28,\"action\",[[23,0,[]],\"update\",\"right\"],null]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/inspector/section/autosizing/template.hbs"
    }
  });

  _exports.default = _default;
});