define("boekdrukken/components/ui-block/sketch/tools/tool/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var icons = {
    'selection': {
      name: 'mouse-pointer'
    },
    'stage/drag': {
      name: 'hand-rock-o'
    },
    'stage/zoom': {
      name: 'search-plus'
    }
  };

  var _default = Ember.Component.extend({
    classNameBindings: [':tool', 'isSelected:light:dark'],
    sketch: null,
    name: null,
    icon: Ember.computed('name', function () {
      var name = this.name;
      return icons[name];
    }).readOnly(),
    tools: Ember.computed.readOnly('sketch.stage.node.tools'),
    selected: Ember.computed.readOnly('tools.selected'),
    isSelected: Ember.computed('name', 'selected.type', function () {
      var name = this.name,
          type = this.selected.type;
      return name === type;
    }).readOnly(),
    click: function click() {
      if (this.isSelected) {
        return;
      }

      this.tools.activate(this.name);
    }
  });

  _exports.default = _default;
});