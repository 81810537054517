define("boekdrukken/routes/internal/render/image", ["exports", "boekdrukken/routes/internal/render/-existing"], function (_exports, _existing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _existing.default.extend({});

  _exports.default = _default;
});