define("boekdrukken/components/ui-block/cart/content/anonymous/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lbNOMAgd",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"hidden\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"ui-block/container\",null,[[\"type\",\"class\"],[\"centered\",\"content\"]],{\"statements\":[[0,\"\\n\"],[4,\"ui-block/card\",null,[[\"type\",\"class\"],[\"minimal\",\"card\"]],{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"block.cart.content.anonymous.header.title\"],null],false],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[1,[28,\"t\",[\"block.cart.content.anonymous.header.description\"],null],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n        \"],[1,[28,\"ui-input/button\",null,[[\"value\",\"action\"],[[28,\"t\",[\"block.cart.content.anonymous.actions.link\"],null],[28,\"action\",[[23,0,[]],\"link\"],null]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/cart/content/anonymous/template.hbs"
    }
  });

  _exports.default = _default;
});