define("boekdrukken/models/analytics/events/order", ["exports", "boekdrukken/models/analytics/events/-base", "boekdrukken/models/analytics/events"], function (_exports, _base, _events) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    category: 'order',
    created: (0, _base.event)(function (type) {
      return {
        action: 'created',
        label: type
      };
    }),
    componentCompleted: (0, _base.event)(function (type, component) {
      return {
        action: "".concat(component, "-completed"),
        label: type
      };
    }),
    completed: (0, _base.event)(function (type) {
      return {
        action: "completed",
        label: type
      };
    }),
    design: (0, _events.events)('order/design'),
    checkout: (0, _events.events)('order/checkout')
  });

  _exports.default = _default;
});