define("boekdrukken/components/ui-block/sketch/inspector/section/font-style/column/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BISwkzBe",
    "block": "{\"symbols\":[\"hash\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[28,\"t\",[[24,[\"label\"]]],null],false],[9],[0,\"\\n\"],[4,\"ui-input/dropdown\",null,[[\"size\",\"items\",\"selected\",\"select\"],[\"small\",[24,[\"choices\"]],[24,[\"selected\"]],[28,\"action\",[[23,0,[]],\"select\"],null]]],{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"component\"]],\"expected `hash.component` to be a contextual component but found a string. Did you mean `(component hash.component)`? ('boekdrukken/components/ui-block/sketch/inspector/section/font-style/column/template.hbs' @ L3:C4) \"],null]],[[\"value\"],[[28,\"t\",[[23,1,[\"item\",\"label\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/inspector/section/font-style/column/template.hbs"
    }
  });

  _exports.default = _default;
});