define("boekdrukken/routes/onboarding", ["exports", "ember-cli-zuglet/lifecycle"], function (_exports, _lifecycle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: (0, _lifecycle.route)().inline({
      storage: Ember.inject.service(),
      bundle: null,
      prepare: function prepare() {
        var bundle = this.storage.retrieve('onboarding/operation');

        if (!bundle) {
          this.router.transitionTo('index');
          return;
        }

        this.setProperties({
          bundle: bundle
        });
      }
    })
  });

  _exports.default = _default;
});