define("boekdrukken/components/ui-block/product/book/step/cover/design/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-product-book-step-cover-design'],
    sketch: Ember.computed.readOnly('component.design'),
    stage: Ember.computed.readOnly('sketch.stage'),
    actions: {
      ready: function ready(stage) {
        stage.fit();
      }
    }
  });

  _exports.default = _default;
});