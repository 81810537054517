define("boekdrukken/components/ui-block/product/book/step/cover/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C4sdGSmG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isDesign\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"cover\",\"hasDesign\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"ui-block/product/book/step/cover/design\",null,[[\"class\",\"component\"],[\"design\",[24,[\"cover\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"ui-block/product/book/step/cover/templates/standalone\",null,[[\"class\",\"component\"],[\"templates\",[24,[\"cover\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"isUpload\"]]],null,{\"statements\":[[0,\"\\n  \"],[1,[28,\"ui-block/product/book/step/cover/upload\",null,[[\"class\",\"component\"],[\"upload\",[24,[\"cover\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n  \"],[1,[28,\"ui-block/product/book/step/cover/types\",null,[[\"class\",\"component\"],[\"types\",[24,[\"cover\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/product/book/step/cover/template.hbs"
    }
  });

  _exports.default = _default;
});