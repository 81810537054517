define("boekdrukken/components/ui-block/product/book/step/cover/upload/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-product-book-step-cover-upload'],
    upload: Ember.computed.readOnly('component.upload'),
    actions: {
      back: function back() {
        this.component.updateSubtype(null);
      }
    }
  });

  _exports.default = _default;
});