define("boekdrukken/services/breadcrumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    models: Ember.computed(function () {
      return Ember.A();
    }).readOnly(),
    items: Ember.computed.mapBy('models', 'breadcrumb'),
    add: function add(model) {
      if (!model || !model.breadcrumb) {
        return;
      }

      this.models.pushObject(model);
    },
    remove: function remove(model) {
      if (!model) {
        return;
      }

      this.models.removeObject(model);
    }
  });

  _exports.default = _default;
});