define("boekdrukken/components/ui-dialog/unlock-product-parameters/component", ["exports", "design/components/ui-dialog/component", "boekdrukken/util/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNameBindings: [':ui-dialog-unlock-product-parameters'],
    externalChecklistURL: (0, _computed.config)(function (_ref) {
      var checklist = _ref.external.checklist;
      return checklist;
    }),
    actions: {
      confirm: function confirm() {
        this.dialog.confirm();
      },
      cancel: function cancel() {
        this.dialog.cancel();
      }
    }
  });

  _exports.default = _default;
});