define("boekdrukken/services/dialogs", ["exports", "design/services/dialogs"], function (_exports, _dialogs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _dialogs.default;
    }
  });
});