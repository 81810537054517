define("boekdrukken/util/progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(opts) {
    return Ember.computed(function () {
      return this.store.models.create('progress', {
        opts: opts
      });
    }).readOnly();
  };

  _exports.default = _default;
});