define("boekdrukken/components/ui-block/localization/editor/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZTHoOPPA",
    "block": "{\"symbols\":[\"locale\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"key\"],[8],[1,[24,[\"item\",\"key\"]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"canonical\"],[8],[1,[24,[\"item\",\"canonical\"]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"item\",\"locales\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"item\",\"editable\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"ui-input/textarea\",null,[[\"class\",\"size\",\"value\",\"update\"],[\"value editable\",\"small\",[23,1,[\"value\"]],[28,\"action\",[[23,0,[]],\"update\",[23,1,[\"locale\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"value readonly\"],[8],[1,[23,1,[\"value\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[7,\"div\",true],[10,\"class\",\"locale\"],[8],[1,[28,\"t\",[[23,1,[\"label\"]]],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/localization/editor/item/template.hbs"
    }
  });

  _exports.default = _default;
});