define("boekdrukken/components/ui-route/account/new/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-route-account-new'],
    actions: {
      signUp: function signUp() {
        this.model.signUp();
      },
      signIn: function signIn() {
        this.model.signIn();
      },
      anonymous: function anonymous() {
        this.model.anonymous();
      }
    }
  });

  _exports.default = _default;
});