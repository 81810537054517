define("boekdrukken/components/ui-route/internal/render/image/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-route-internal-render-image'],
    offset: Ember.computed.readOnly('model.dimensions.offset'),
    actions: {
      ready: function ready(stage) {
        var offset = this.offset;
        stage.fit({
          type: 'containers',
          offset: offset
        });
      }
    }
  });

  _exports.default = _default;
});