define("boekdrukken/components/ui-block/sketch/tools/tool/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qZSBfX88",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ui-block/icon\",[[24,[\"icon\",\"name\"]]],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/tools/tool/template.hbs"
    }
  });

  _exports.default = _default;
});