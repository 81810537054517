define("boekdrukken/components/ui-block/sketch/switch/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-sketch-switch'],
    sketch: null,
    choices: null,
    nodes: Ember.computed.readOnly('sketch.stage.nodes'),
    selected: Ember.computed('choices', 'nodes.@each.{identifier,selectable}', function () {
      var choices = this.choices,
          nodes = this.nodes;

      if (!choices || !nodes) {
        return;
      }

      var isNodeSelected = function isNodeSelected(node) {
        return node.selectable;
      };

      var isNodesSelected = function isNodesSelected(nodes) {
        return nodes.find(function (node) {
          return isNodeSelected(node);
        });
      };

      return choices.find(function (choice) {
        var identifiers = choice.identifiers;
        return identifiers.find(function (identifier) {
          var matching = nodes.filterBy('identifier', identifier);
          return isNodesSelected(matching);
        });
      });
    }).readOnly(),
    actions: {
      select: function select(choice) {
        this.update(choice);
      }
    },
    update: function update(selected) {
      var choices = this.choices,
          nodes = this.nodes;

      if (!choices || !nodes) {
        return;
      }

      choices.forEach(function (choice) {
        var selectable = choice === selected;
        var visible = choice === selected || choice.visible;
        choice.identifiers.forEach(function (identifier) {
          var matching = nodes.filterBy('identifier', identifier);
          matching.forEach(function (node) {
            node.update({
              selectable: selectable,
              visible: visible
            });

            if (!selectable) {
              node.deselect();
            }
          });
        });
      });
    }
  });

  _exports.default = _default;
});