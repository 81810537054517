define("boekdrukken/components/ui-block/products/list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-products-list'],
    actions: {
      select: function select(product) {
        this.select && this.select(product);
      },
      delete: function _delete(product) {
        this.delete && this.delete(product);
      }
    }
  });

  _exports.default = _default;
});