define("boekdrukken/models/users/user/products/product/-steps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.steps = _exports.default = void 0;

  var steps = function steps() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return Ember.computed(function () {
      return Object.freeze(args);
    }).readOnly();
  };

  _exports.steps = steps;

  var _default = Ember.Mixin.create({
    step: null,
    instantiateStep: function instantiateStep(type) {
      var steps = this.steps;
      var step = null;

      if (steps && steps.includes(type)) {
        step = this.store.models.create("users/user/products/steps/".concat(this.type, "/").concat(type), {
          product: this,
          type: type
        });
      }

      this.setProperties({
        step: step
      });
      return step;
    }
  });

  _exports.default = _default;
});