define("boekdrukken/components/ui-block/standalone/calculator/book/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var field = function field(key) {
    return "block.standalone.calculator.book.field.".concat(key);
  };

  var label = function label(key) {
    return field("".concat(key, ".label"));
  };

  var placeholder = function placeholder(key) {
    return field("".concat(key, ".placeholder"));
  };

  var highlight = function highlight(key) {
    return field("".concat(key, ".highlight"));
  };

  var properties = Object.freeze([{
    label: label('width'),
    placeholder: placeholder('width'),
    key: 'width',
    highlight: highlight('width')
  }, {
    label: label('height'),
    placeholder: placeholder('height'),
    key: 'height',
    highlight: highlight('height')
  }, {
    label: label('pages'),
    placeholder: placeholder('pages'),
    key: 'pages',
    highlight: highlight('pages')
  }, {
    label: label('pages-in-color'),
    placeholder: placeholder('pages-in-color'),
    key: 'colorPages',
    highlight: highlight('pages-in-color')
  }, {
    label: label('count'),
    placeholder: placeholder('count'),
    key: 'count',
    highlight: highlight('count')
  }]);

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-standalone-calculator-book'],
    model: null,
    properties: properties,
    actions: {
      update: function update(key, value) {
        value = parseInt(value);

        if (isNaN(value)) {
          value = null;
        }

        this.model.update(key, value);
      },
      updateCountry: function updateCountry(country) {
        this.model.update('country', country);
      },
      calculate: function calculate(highlight) {
        highlight.present();
        this.model.calculate();
      }
    }
  });

  _exports.default = _default;
});