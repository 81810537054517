define("boekdrukken/components/ui-route/stylebook/segmented-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-route-stylebook-page', ':ui-route-stylebook-segmented-button'],
    choices: Object.freeze(['Black & White', 'Full Color']),
    selected: 'Black & White',
    actions: {
      select: function select(value) {
        this.set('selected', value);
      }
    }
  });

  _exports.default = _default;
});