define("boekdrukken/routes/stylebook/wip", ["exports", "ember-cli-zuglet/lifecycle", "boekdrukken/routes/-breadcrumb"], function (_exports, _lifecycle, _breadcrumb) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_breadcrumb.default, {
    model: (0, _lifecycle.route)().inline({
      breadcrumb: (0, _breadcrumb.breadcrumb)({
        title: 'WIP',
        action: function action(transitionTo) {
          return transitionTo('stylebook.wip');
        }
      }),
      prepare: function prepare() {}
    })
  });

  _exports.default = _default;
});