define("boekdrukken/components/ui-route/stylebook/progress-bar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-route-stylebook-page', ':ui-route-stylebook-progress-bar'],
    min: 0,
    max: 100,
    value: 50,
    actions: {
      update: function update(value) {
        this.setProperties({
          value: value
        });
      }
    }
  });

  _exports.default = _default;
});