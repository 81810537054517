define("boekdrukken/components/ui-dialog/upload-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e0sUHWq4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[1,[28,\"t\",[\"dialog.upload-image.header\"],null],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[1,[28,\"ui-input/file\",null,[[\"title\",\"accept\",\"class\",\"size\",\"color\",\"update\",\"disabled\"],[[28,\"t\",[\"dialog.upload-image.select\"],null],\"image/*\",\"file\",\"smaller\",\"primary\",[28,\"action\",[[23,0,[]],\"selectFile\"],null],[24,[\"isFileDisabled\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[1,[28,\"ui-input/button\",null,[[\"size\",\"value\",\"action\",\"class\",\"disabled\"],[\"small\",[28,\"if\",[[24,[\"isBusy\"]],[28,\"t\",[\"dialog.upload-image.uploading\"],null],[28,\"t\",[\"dialog.upload-image.actions.upload\"],null]],null],[28,\"action\",[[23,0,[]],\"perform\"],null],\"action\",[24,[\"isPerformDisabled\"]]]]],false],[0,\"\\n  \"],[1,[28,\"ui-input/button\",null,[[\"size\",\"value\",\"action\",\"class\",\"disabled\"],[\"small\",[28,\"t\",[\"dialog.upload-image.actions.cancel\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null],\"action\",[24,[\"isCancelDisabled\"]]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-dialog/upload-image/template.hbs"
    }
  });

  _exports.default = _default;
});