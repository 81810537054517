define("boekdrukken/components/ui-block/sketch/inspector/section/button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EdgeGQJv",
    "block": "{\"symbols\":[\"button\"],\"statements\":[[4,\"ui-input/button\",null,[[\"class\",\"size\",\"color\",\"action\"],[\"button\",\"tiny\",[24,[\"color\"]],[24,[\"action\"]]]],{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"icon\"]],\"expected `button.icon` to be a contextual component but found a string. Did you mean `(component button.icon)`? ('boekdrukken/components/ui-block/sketch/inspector/section/button/template.hbs' @ L2:C4) \"],null]],[[\"name\",\"type\"],[[24,[\"icon\",\"name\"]],[24,[\"icon\",\"type\"]]]]],false],[0,\"\\n  \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"label\"]],\"expected `button.label` to be a contextual component but found a string. Did you mean `(component button.label)`? ('boekdrukken/components/ui-block/sketch/inspector/section/button/template.hbs' @ L3:C4) \"],null]],[[\"value\"],[[24,[\"label\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/inspector/section/button/template.hbs"
    }
  });

  _exports.default = _default;
});