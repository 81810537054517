define("boekdrukken/components/ui-block/product/book/step/cover/design/dev/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bMux4kVa",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isDevelopmentBuild\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"block.product.book.step.cover.design.dev.title\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    \"],[1,[28,\"ui-input/button\",null,[[\"class\",\"value\",\"size\",\"type\",\"color\",\"action\"],[\"button\",[28,\"t\",[\"block.product.book.step.cover.design.dev.actions.remove-design\"],null],\"small\",\"outline\",\"warning\",[28,\"action\",[[23,0,[]],\"remove\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/product/book/step/cover/design/dev/template.hbs"
    }
  });

  _exports.default = _default;
});