define("boekdrukken/templates/stylebook/card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xYcsmiHY",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"ui-route/stylebook/card\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/templates/stylebook/card.hbs"
    }
  });

  _exports.default = _default;
});