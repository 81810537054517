define("boekdrukken/components/ui-route/backend/templates/type/template/preview/design/-render/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QCEFeZBi",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ui-input/button\",null,[[\"size\",\"value\",\"disabled\",\"action\"],[\"small\",[24,[\"buttonValue\"]],[24,[\"createDisabled\"]],[28,\"action\",[[23,0,[]],\"create\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"operation\",\"status\"]],\"finished\"],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"ui-input/button\",null,[[\"size\",\"value\",\"action\"],[\"small\",[24,[\"open\"]],[28,\"action\",[[23,0,[]],\"show\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-route/backend/templates/type/template/preview/design/-render/template.hbs"
    }
  });

  _exports.default = _default;
});