define("boekdrukken/models/sketch/base/stage/-schedule-save", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    cancelScheduledSave: function cancelScheduledSave() {
      Ember.run.cancel(this._scheduledSave);
    },
    performScheduledSave: function performScheduledSave() {
      if (this.isDestroying) {
        return;
      }

      this.save();
    },
    scheduleSave: function scheduleSave() {
      var _this = this;

      this.cancelScheduledSave();
      this._scheduledSave = Ember.run.later(function () {
        return _this.performScheduledSave();
      }, 150);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this.cancelScheduledSave();
    }
  });

  _exports.default = _default;
});