define("boekdrukken/components/ui-application/header/cart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6AHgINy7",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"user\"]]],null,{\"statements\":[[4,\"ui-block/pill\",null,[[\"class\",\"action\"],[\"cart\",[28,\"action\",[[23,0,[]],\"cart\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"t\",[\"application.header.cart.label\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-application/header/cart/template.hbs"
    }
  });

  _exports.default = _default;
});