define("boekdrukken/models/sketch/base/stage/-node", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    replace: function replace(model, next) {
      var pos = {
        model: model.position,
        next: next.position
      };

      var set = function set(model, pos) {
        model.set('doc.data.position', pos);
        model.didUpdate();
      };

      set(model, pos.next);
      set(next, pos.model);
    }
  });

  _exports.default = _default;
});