define("boekdrukken/components/ui-route/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nAeTPRQV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[1,[28,\"t\",[\"route.error.title\"],null],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"message\"],[8],[1,[24,[\"model\",\"message\"]],false],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"model\",\"code\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"code\"],[8],[1,[24,[\"model\",\"code\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"stack\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"stack\"],[8],[1,[24,[\"model\",\"stack\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-route/error/template.hbs"
    }
  });

  _exports.default = _default;
});