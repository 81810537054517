define("boekdrukken/util/thenable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default() {
    var key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'promise';

    var wrap = function wrap(target) {
      return function () {
        var promise = this[key];
        var fn = promise[target];

        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        return fn.call.apply(fn, [promise].concat(args));
      };
    };

    return Ember.Mixin.create({
      then: wrap('then'),
      catch: wrap('catch'),
      finally: wrap('finally')
    });
  };

  _exports.default = _default;
});