define("boekdrukken/components/ui-block/product/book/step/cover/design/back/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "enDv/PM2",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ui-input/button\",null,[[\"class\",\"value\",\"size\",\"color\",\"action\"],[\"button\",[28,\"t\",[\"block.product.book.step.cover.design.back.label\"],null],\"small\",\"warning\",[28,\"action\",[[23,0,[]],\"back\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/product/book/step/cover/design/back/template.hbs"
    }
  });

  _exports.default = _default;
});