define("boekdrukken/components/ui-block/sketch/inspector/selection/model/image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cI6C+j0u",
    "block": "{\"symbols\":[\"section\"],\"statements\":[[4,\"ui-block/sketch/inspector/section\",null,[[\"sketch\",\"model\"],[[24,[\"sketch\"]],[24,[\"model\"]]]],{\"statements\":[[0,\"\\n  \"],[1,[23,1,[\"header\"]],false],[0,\"\\n\\n  \"],[1,[23,1,[\"autosizing\"]],false],[0,\"\\n\\n  \"],[1,[23,1,[\"size-warning\"]],false],[0,\"\\n\\n  \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"button\"]],\"expected `section.button` to be a contextual component but found a string. Did you mean `(component section.button)`? ('boekdrukken/components/ui-block/sketch/inspector/selection/model/image/template.hbs' @ L9:C4) \"],null]],[[\"label\",\"icon\",\"action\"],[[28,\"t\",[\"block.sketch.inspector.selection.model.image.upload\"],null],[28,\"hash\",null,[[\"name\",\"type\"],[\"upload-to-cloud\",\"custom\"]]],[28,\"action\",[[23,0,[]],\"chooseImage\"],null]]]],false],[0,\"\\n\\n  \"],[1,[23,1,[\"position\"]],false],[0,\"\\n  \"],[1,[23,1,[\"size\"]],false],[0,\"\\n\\n  \"],[1,[23,1,[\"fit\"]],false],[0,\"\\n  \"],[1,[23,1,[\"opacity-rotation\"]],false],[0,\"\\n\\n  \"],[1,[23,1,[\"move\"]],false],[0,\"\\n  \"],[1,[23,1,[\"remove\"]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/inspector/selection/model/image/template.hbs"
    }
  });

  _exports.default = _default;
});