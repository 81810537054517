define("boekdrukken/sketches", ["exports", "ember-cli-sketch/sketches"], function (_exports, _sketches) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _sketches.default.extend({
    options: _sketches.defaults
  });

  _exports.default = _default;
});