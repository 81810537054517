define("boekdrukken/components/ui-route/backend/templates/type/template/preview/design/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-route-backend-templates-type-template-preview-design'],
    template: null,
    sketch: Ember.computed.readOnly('template.preview'),
    stage: Ember.computed.readOnly('sketch.stage'),
    actions: {
      ready: function ready(stage) {
        stage.fit();
      }
    }
  });

  _exports.default = _default;
});