define("boekdrukken/util/paper-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.paperType = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var mapping = {
    mm: {
      'Academic value': [170, 250],
      'A1': [594, 841],
      'A2': [420, 594],
      'A3': [297, 420],
      'A4': [210, 297],
      'A5': [148, 210],
      'A6': [105, 148],
      'A7': [74, 105],
      'B3': [353, 500],
      'B4': [250, 353],
      'B5': [176, 250],
      'B6': [125, 176],
      'B7': [88, 125]
    }
  };

  var paperType = function paperType(width, height, unit) {
    if (unit !== 'mm') {
      return;
    }

    var mm = mapping.mm;
    var round = 5;

    var compare = function compare(value, expected) {
      if (value > expected - round && value < expected + round) {
        return true;
      }
    };

    for (var key in mm) {
      var _mm$key = _slicedToArray(mm[key], 2),
          w = _mm$key[0],
          h = _mm$key[1];

      if (compare(width, w) && compare(height, h)) {
        return key;
      }
    }
  };

  _exports.paperType = paperType;
});