define("boekdrukken/components/ui-block/stripe/types/card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hat1YI0C",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ui-input/text\",null,[[\"class\",\"value\",\"placeholder\",\"size\",\"update\"],[\"name\",[24,[\"type\",\"name\"]],[28,\"t\",[\"block.stripe.type.card.name.placeholder\"],null],\"small\",[28,\"action\",[[23,0,[]],\"updateName\"],null]]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/stripe/types/card/template.hbs"
    }
  });

  _exports.default = _default;
});