define("boekdrukken/components/ui-block/standalone/download/book/component", ["exports", "mathjs"], function (_exports, _mathjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var field = function field(key) {
    return "block.standalone.download.book.field.".concat(key);
  };

  var label = function label(key) {
    return field("".concat(key, ".label"));
  };

  var placeholder = function placeholder(key) {
    return field("".concat(key, ".placeholder"));
  };

  var highlight = function highlight(key) {
    return field("".concat(key, ".highlight"));
  };

  var properties = Object.freeze([{
    type: 'text',
    label: label('width'),
    key: 'width',
    placeholder: placeholder('width'),
    highlight: highlight('width')
  }, {
    type: 'text',
    label: label('height'),
    key: 'height',
    placeholder: placeholder('height'),
    highlight: highlight('height')
  }, {
    type: 'text',
    label: label('pages'),
    key: 'numberOfPages',
    placeholder: placeholder('pages'),
    highlight: highlight('pages')
  }, {
    type: 'dropdown',
    label: label('cover'),
    key: 'cover',
    source: 'coverPapers'
  }, {
    type: 'dropdown',
    label: label('content'),
    key: 'content',
    source: 'contentPapers'
  }]);

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-standalone-download-book'],
    window: Ember.inject.service(),
    properties: properties,
    actions: {
      updateText: function updateText(key, value) {
        value = (0, _mathjs.round)(parseFloat(value), 2);

        if (isNaN(value)) {
          value = null;
        }

        this.model.update(key, value);
      },
      updateDropdown: function updateDropdown(key, value) {
        this.model.update(key, value);
      },
      perform: function perform(highlight) {
        highlight.present();
        this.model.perform();
      },
      download: function download() {
        var url = this.model.url,
            window = this.window;
        window.open(url);
      }
    }
  });

  _exports.default = _default;
});