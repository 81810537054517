define("boekdrukken/components/ui-application/header/navigation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j+vepSrF",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"items\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"title\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"ui-application/header/navigation/breadcrumb\",null,[[\"item\",\"class\",\"action\"],[[23,1,[]],\"item breadcrumb\",[28,\"action\",[[23,0,[]],\"select\",[23,1,[]]],null]]]],false],[0,\"\\n      \"],[1,[28,\"ui-block/icon\",[\"chevron-right\"],[[\"class\"],[\"item divider\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-application/header/navigation/template.hbs"
    }
  });

  _exports.default = _default;
});