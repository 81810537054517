define("boekdrukken/models/analytics/events/index", ["exports", "boekdrukken/models/analytics/events/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    category: 'index',
    upload: (0, _base.event)(function (label) {
      return {
        action: 'upload-pdf',
        label: label
      };
    })
  });

  _exports.default = _default;
});