define("boekdrukken/util/document-wait-for", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(doc, keys, fn) {
    var observer = doc.observe();
    var deferred = Ember.RSVP.defer();
    var isDone = false;

    var withKeys = function withKeys(cb) {
      return keys.forEach(function (key) {
        return cb(key);
      });
    };

    var done = function done() {
      isDone = true;
      observer.cancel();
      withKeys(function (key) {
        return Ember.removeObserver(doc, key, keyDidChange);
      });
      Ember.run.next(function () {
        return deferred.resolve(doc);
      });
    };

    var keyDidChange = function keyDidChange(target, key) {
      if (isDone) {
        return;
      }

      var result = fn(target, key);

      if (result) {
        done();
      }
    };

    withKeys(function (key) {
      return Ember.addObserver(doc, key, keyDidChange);
    });
    withKeys(function (key) {
      return keyDidChange(doc, key);
    });
    return deferred.promise;
  };

  _exports.default = _default;
});