define("boekdrukken/components/ui-application/header/locales/component", ["exports", "boekdrukken/util/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var flag = function flag(key) {
    return "/flags/icons8-".concat(key, "-150.png");
  };

  var flags = {
    'en-us': flag('great-britain'),
    'nl': flag('netherlands')
  };

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-application-header-locales', 'list:list', 'locales::hidden'],
    isDevelopmentBuild: (0, _computed.isDevelopmentBuild)(),
    flags: flags,
    list: false,
    locale: Ember.computed.readOnly('intl.primaryLocale'),
    locales: (0, _computed.config)(function (config) {
      return Ember.get(config, 'localization.locales');
    }),
    actions: {
      list: function list() {
        this.setProperties({
          list: true
        });
      },
      select: function select(locale) {
        var settings = this.get('store.user.settings');

        if (settings) {
          settings.setLocale(locale);
        }

        this.intl.setLocaleWithFallbackChain(locale);
        this.setProperties({
          list: false
        });
      },
      keys: function keys() {
        this.intl.setShowKeys();
        this.setProperties({
          list: false
        });
      }
    }
  });

  _exports.default = _default;
});