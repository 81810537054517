define("boekdrukken/models/users/user/products/book/-flow", ["exports", "design/flow", "boekdrukken/models/users/user/products/-product"], function (_exports, _flow, _product) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var stepRoute = function stepRoute(step) {
    return Ember.computed('owner.{id,user.id}', function () {
      var _this$owner = this.owner,
          id = _this$owner.id,
          uid = _this$owner.user.id;
      return {
        path: "users.user.products.product.step",
        args: [step],
        current: function current(_current) {
          var url = "/users/".concat(uid, "/products/").concat(id, "/").concat(step);
          return _current.url.startsWith(url);
        }
      };
    }).readOnly();
  };

  var _default = Ember.Mixin.create({
    steps: (0, _product.steps)('source', 'parameters', 'content', 'cover', 'summary'),
    flow: (0, _flow.flow)({
      pdf: (0, _flow.step)({
        title: 'model.users.user.products.book.flow.source',
        route: stepRoute('source')
      }),
      parameters: (0, _flow.step)({
        title: 'model.users.user.products.book.flow.parameters',
        route: stepRoute('parameters'),
        confirmation: function confirmation(_ref) {
          var step = _ref.owner.step;
          return step.handleDidFinishEditing();
        }
      }),
      content: (0, _flow.step)({
        title: 'model.users.user.products.book.flow.content',
        route: stepRoute('content')
      }),
      cover: (0, _flow.step)({
        title: 'model.users.user.products.book.flow.cover',
        route: stepRoute('cover')
      }),
      summary: (0, _flow.step)({
        title: 'model.users.user.products.book.flow.summary',
        route: stepRoute('summary'),
        badge: Ember.computed.readOnly('owner.pricing.description')
      })
    })
  });

  _exports.default = _default;
});