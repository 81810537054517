define("boekdrukken/routes/-breadcrumb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.breadcrumb = void 0;

  var breadcrumb = function breadcrumb() {
    var _Ember;

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var opts = Ember.assign({}, args.pop());
    return (_Ember = Ember).computed.apply(_Ember, args.concat([function () {
      var _this = this;

      var layout = opts.layout;
      var title = opts.title;

      if (typeof title === 'function') {
        title = title.call(this, this);
      }

      var component = opts.component;

      if (typeof component === 'function') {
        component = component.call(this, this);
      }

      var action;

      if (opts.action) {
        action = function action() {
          var transitionTo = function transitionTo() {
            var _this$router;

            return (_this$router = _this.router).transitionTo.apply(_this$router, arguments);
          };

          opts.action.call(_this, transitionTo, _this);
        };
      }

      return {
        layout: layout,
        title: title,
        component: component,
        action: action
      };
    }])).readOnly();
  };

  _exports.breadcrumb = breadcrumb;

  var _default = Ember.Mixin.create({
    breadcrumbs: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this.breadcrumbs.remove(controller.model);

      this._super.apply(this, arguments);

      this.breadcrumbs.add(model);
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this.breadcrumbs.remove(this.modelFor(this.routeName));
    }
  });

  _exports.default = _default;
});