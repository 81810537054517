define("boekdrukken/components/ui-route/users/user/products/product/step/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rQ9g62pN",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"component\",[[28,\"concat\",[\"ui-block/product/\",[24,[\"product\",\"type\"]],\"/step/\",[24,[\"step\",\"type\"]]],null]],[[\"step\"],[[24,[\"step\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-route/users/user/products/product/step/index/template.hbs"
    }
  });

  _exports.default = _default;
});