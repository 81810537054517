define("boekdrukken/components/ui-block/product/book/step/parameters/content/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':section', ':content'],
    content: Ember.computed.readOnly('parameters.content'),
    paper: Ember.computed.readOnly('content.paper'),
    actions: {
      update: function update(parameter, value) {
        parameter.update(value);
      }
    }
  });

  _exports.default = _default;
});