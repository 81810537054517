define("boekdrukken/components/ui-block/product/step/errors/component", ["exports", "boekdrukken/util/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var action = function action(key) {
    return "block.product.step.errors.action.".concat(key);
  };

  var start = {
    label: action('start-again'),
    type: 'index'
  };
  var index = {
    label: action('try-again'),
    type: 'index'
  };

  var step = function step(id) {
    return {
      label: action('show-step'),
      type: 'step',
      id: id
    };
  };

  var label = function label(key) {
    return "block.product.step.errors.error.".concat(key);
  };

  var mapping = {
    'content-size': function contentSize(_ref) {
      var min = _ref.min,
          max = _ref.max;

      if (min) {
        return {
          label: label('content-size.min'),
          width: (0, _string.cm)(min.width),
          height: (0, _string.cm)(min.height),
          action: start
        };
      }

      if (max) {
        return {
          label: label('content-size.max'),
          width: (0, _string.cm)(max.width),
          height: (0, _string.cm)(max.height),
          action: start
        };
      }

      return {
        label: label('content-size.fallback'),
        action: start
      };
    },
    'spine': function spine(_ref2) {
      var min = _ref2.min,
          max = _ref2.max;

      if (min) {
        return {
          label: label('spine.min'),
          min: min,
          action: start
        };
      }

      if (max) {
        return {
          label: label('spine.max'),
          max: max,
          action: start
        };
      }
    },
    'dimensions-mismatch': function dimensionsMismatch() {
      return {
        label: label('dimensions-mismatch'),
        action: start
      };
    },
    'default': function _default() {
      return {
        label: label('default'),
        action: index
      };
    }
  };

  var _default2 = Ember.Component.extend({
    classNameBindings: [':ui-block-product-step-errors', 'description::hidden'],
    product: null,
    showIncomplete: true,
    pricing: Ember.computed.readOnly('product.pricing'),
    isError: Ember.computed.readOnly('pricing.isError'),
    error: Ember.computed.readOnly('pricing.error'),
    incomplete: Ember.computed.readOnly('product.incompleteComponentDescription'),
    errorDescription: Ember.computed('error', 'intl.locale', function () {
      var error = this.error;

      if (!error) {
        return;
      }

      var identifier = error.identifier,
          details = error.details;
      var fn = mapping[identifier] || mapping['default'];

      if (!fn) {
        return;
      }

      var hash = fn.call(this, details || {});
      var label = this.intl.t(hash.label, hash);
      var action = hash.action;
      return {
        label: label,
        action: action
      };
    }).readOnly(),
    incompleteDescription: Ember.computed('showIncomplete', 'incomplete', function () {
      var showIncomplete = this.showIncomplete,
          incomplete = this.incomplete;

      if (!showIncomplete) {
        return;
      }

      if (!incomplete) {
        return;
      }

      var label = incomplete.description,
          id = incomplete.step;
      var action = step(id);
      return {
        label: label,
        action: action
      };
    }).readOnly(),
    description: Ember.computed('errorDescription', 'incompleteDescription', function () {
      var errorDescription = this.errorDescription,
          incompleteDescription = this.incompleteDescription;

      if (errorDescription) {
        return errorDescription;
      }

      if (incompleteDescription) {
        return incompleteDescription;
      }
    }).readOnly(),
    actions: {
      index: function index() {
        this.router.transitionTo('index');
      },
      step: function step(description) {
        var id = description.action.id;
        this.product.flow.step(id).transitionTo();
      }
    }
  });

  _exports.default = _default2;
});