define("boekdrukken/components/ui-route/standalone/downloads/book/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F0djrMTJ",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ui-block/standalone/download/book\",null,[[\"model\",\"class\"],[[24,[\"model\"]],\"content\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-route/standalone/downloads/book/index/template.hbs"
    }
  });

  _exports.default = _default;
});