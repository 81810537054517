define("boekdrukken/models/sketch/base/pdf", ["exports", "boekdrukken/models/sketch/base/-operation"], function (_exports, _operation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var pdf = function pdf(key) {
    return Ember.computed.readOnly("sketch.doc.data.pdf.".concat(key));
  };

  var _default = _operation.default.extend({
    sketch: null,
    url: pdf('url'),
    exists: Ember.computed.bool('url'),
    dimensions: Ember.computed('sketch.dimensions', function () {
      var _this$sketch$dimensio = this.sketch.dimensions,
          _this$sketch$dimensio2 = _this$sketch$dimensio.page,
          width = _this$sketch$dimensio2.width,
          height = _this$sketch$dimensio2.height,
          crop = _this$sketch$dimensio.crop,
          spine = _this$sketch$dimensio.spine;
      return {
        width: crop + width + spine + width + crop,
        height: crop + height + crop,
        bleeding: crop
      };
    }).readOnly(),
    _optionsForCreateOperation: function _optionsForCreateOperation() {
      var dimensions = this.dimensions;
      return {
        type: 'pdf',
        dimensions: dimensions
      };
    }
  });

  _exports.default = _default;
});