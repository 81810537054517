define("boekdrukken/util/load-script", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(url, cb) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    var promise = new Ember.RSVP.Promise(function (resolve, reject) {
      script.onload = function () {
        resolve(cb(window));
      };

      script.onerror = function () {
        var err = new Error('Script load failed');
        err.code = 'load-script';
        reject(err);
      };
    });
    document.body.appendChild(script);
    return promise;
  };

  _exports.default = _default;
});