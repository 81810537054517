define("boekdrukken/components/ui-block/sketch/stage/node/area/accessories/book-crop-marks/component", ["exports", "ember-cli-sketch/components/ui-block/sketch/stage/-computed", "ember-cli-sketch/util/math"], function (_exports, _computed, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var zoomed = function zoomed(key) {
    return Ember.computed(key, 'zoom', function () {
      var zoom = this.zoom;
      var value = this.get(key);
      return value * zoom;
    }).readOnly();
  };

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-sketch-stage-node-area-accessories-book-crop-marks', 'highlighted:highlighted', 'rendering:rendering'],
    rendering: Ember.computed.readOnly('model.stage.isRendering'),
    highlighted: Ember.computed.readOnly('model.node.isHovered'),
    zoom: Ember.computed.readOnly('model.node.frame.zoom'),
    _width: Ember.computed.readOnly('model.node.frame.width'),
    _spine: Ember.computed.readOnly('accessory.spine'),
    _bleeding: Ember.computed.readOnly('accessory.bleeding'),
    _overlay: Ember.computed.readOnly('accessory.overlay'),
    width: zoomed('_width'),
    spine: zoomed('_spine'),
    bleeding: zoomed('_bleeding'),
    mark: Ember.computed('bleeding', 'spine', 'width', function () {
      var _bleeding = this.bleeding,
          _spine = this.spine,
          _width = this.width;
      var bleeding = "".concat(_bleeding, "px");
      var spine = {
        left: "".concat((0, _math.round)(_width / 2 - _spine / 2), "px"),
        width: "".concat(_spine, "px")
      };
      return {
        edge: {
          top: {
            left: (0, _computed.objectToStyle)({
              top: 0,
              left: 0,
              width: bleeding,
              height: bleeding
            }),
            right: (0, _computed.objectToStyle)({
              top: 0,
              right: 0,
              width: bleeding,
              height: bleeding
            })
          },
          bottom: {
            left: (0, _computed.objectToStyle)({
              bottom: 0,
              left: 0,
              width: bleeding,
              height: bleeding
            }),
            right: (0, _computed.objectToStyle)({
              bottom: 0,
              right: 0,
              width: bleeding,
              height: bleeding
            })
          }
        },
        spine: (0, _computed.objectToStyle)({
          left: spine.left,
          width: spine.width
        })
      };
    }).readOnly(),
    bleedingOverlayStyle: (0, _computed.style)('bleeding', '_overlay', function () {
      var bleeding = this.bleeding,
          _overlay = this._overlay;

      if (!bleeding || !_overlay) {
        return;
      }

      var borderWidth = bleeding - 1;
      borderWidth = (0, _math.round)(borderWidth, 2);
      borderWidth = "".concat(borderWidth, "px");
      return {
        borderWidth: borderWidth
      };
    })
  });

  _exports.default = _default;
});