define("boekdrukken/components/ui-block/product/book/step/cover/templates/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-product-book-step-cover-templates'],
    component: null,
    actions: {
      choose: function choose() {
        this.component.chooseTemplate();
      }
    }
  });

  _exports.default = _default;
});