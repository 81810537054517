define("boekdrukken/components/ui-block/product/book/step/cover/design/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+5x/p+FQ",
    "block": "{\"symbols\":[\"inspector\",\"tree\"],\"statements\":[[4,\"ui-block/sketch/tree\",null,[[\"sketch\",\"class\"],[[24,[\"sketch\"]],\"tree\"]],{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,2,[\"position\"]],\"top\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n      \"],[1,[28,\"ui-block/product/book/step/cover/templates\",null,[[\"component\"],[[24,[\"component\"]]]]],false],[0,\"\\n      \"],[1,[28,\"ui-block/sketch/nodes\",null,[[\"sketch\"],[[24,[\"sketch\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[1,[28,\"ui-block/sketch/toolbar\",null,[[\"sketch\",\"class\"],[[24,[\"sketch\"]],\"toolbar\"]]],false],[0,\"\\n  \"],[1,[28,\"ui-block/sketch/stage\",null,[[\"stage\",\"class\",\"ready\"],[[24,[\"stage\"]],\"stage\",[28,\"action\",[[23,0,[]],\"ready\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"ui-block/sketch/inspector\",null,[[\"sketch\",\"class\"],[[24,[\"sketch\"]],\"inspector\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"position\"]],\"bottom\"],null]],null,{\"statements\":[[4,\"unless\",[[24,[\"stage\",\"selection\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"ui-block/product/book/step/cover/design/back\",null,[[\"component\"],[[24,[\"component\"]]]]],false],[0,\"\\n      \"],[1,[28,\"ui-block/product/book/step/cover/design/dev\",null,[[\"component\"],[[24,[\"component\"]]]]],false],[0,\"\\n      \"],[1,[28,\"ui-block/product/book/step/cover/design/pdf\",null,[[\"component\"],[[24,[\"component\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[1,[28,\"ui-block/dialogs\",null,[[\"label\"],[\"sketch\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/product/book/step/cover/design/template.hbs"
    }
  });

  _exports.default = _default;
});