define("boekdrukken/models/analytics/events/ecommerce", ["exports", "boekdrukken/models/analytics/events/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    // https://developers.google.com/analytics/devguides/collection/gtagjs/events#default_google_analytics_events
    category: 'order',
    // begin: event(amount => ({ action: `begin_checkout`, value: amount })),
    success: (0, _base.event)(function (amount) {
      return {
        action: "success",
        value: amount
      };
    })
  });

  _exports.default = _default;
});