define("boekdrukken/components/ui-block/sketch/nodes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EO2wa2pO",
    "block": "{\"symbols\":[\"hash\",\"item\"],\"statements\":[[4,\"ui-input/dropdown\",null,[[\"type\",\"size\",\"disabled\",\"items\",\"selected\",\"select\"],[\"prominent\",\"medium\",[24,[\"isDisabled\"]],[24,[\"types\"]],[24,[\"selected\"]],[28,\"action\",[[23,0,[]],\"select\"],null]]],{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"component\"]],\"expected `hash.component` to be a contextual component but found a string. Did you mean `(component hash.component)`? ('boekdrukken/components/ui-block/sketch/nodes/template.hbs' @ L2:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,2,[\"icon\"]],\"expected `item.icon` to be a contextual component but found a string. Did you mean `(component item.icon)`? ('boekdrukken/components/ui-block/sketch/nodes/template.hbs' @ L3:C6) \"],null]],[[\"name\"],[[23,1,[\"item\",\"icon\"]]]]],false],[0,\"\\n    \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,2,[\"value\"]],\"expected `item.value` to be a contextual component but found a string. Did you mean `(component item.value)`? ('boekdrukken/components/ui-block/sketch/nodes/template.hbs' @ L4:C6) \"],null]],[[\"value\"],[[28,\"t\",[[23,1,[\"item\",\"label\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/nodes/template.hbs"
    }
  });

  _exports.default = _default;
});