define("boekdrukken/components/ui-block/accessory/popup/component", ["exports", "design/util/mouse-enter-leave-mixin"], function (_exports, _mouseEnterLeaveMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO: not used at the moment
  //
  // {{#ui-block/accessory/popup class='popup' title=title details=details icon=icon as |popup|}}
  //   {{ui-block/order/accessory/products model=model editing=popup.suspend}}
  //   {{ui-block/order/accessory/total model=model}}
  //   {{ui-block/order/accessory/actions model=model checkout=(action 'checkout' popup.close)}}
  // {{/ui-block/accessory/popup}}
  var _default = Ember.Component.extend(_mouseEnterLeaveMixin.default, {
    classNameBindings: [':ui-block-accessory-popup', 'open:open'],
    suspended: false,
    open: false,
    opts: Ember.computed('title', 'details', 'icon', function () {
      var title = this.title,
          details = this.details,
          icon = this.icon;
      return {
        title: title,
        details: details,
        icon: icon
      };
    }).readOnly(),
    actions: {
      suspend: function suspend(suspended) {
        this.setProperties({
          suspended: suspended
        });

        if (suspended) {
          this.cancelLeave();
        }
      },
      close: function close() {
        this.setOpen(false);
      }
    },
    setOpen: function setOpen(open) {
      this.cancelLeave();

      if (this.suspended && !open) {
        return;
      }

      this.set('open', open);
    },
    cancelLeave: function cancelLeave() {
      Ember.run.cancel(this._leave);
    },
    handleMouseEnter: function handleMouseEnter() {
      this._super.apply(this, arguments);

      this.setOpen(true);
    },
    handleMouseLeave: function handleMouseLeave() {
      var _this = this;

      this._super.apply(this, arguments);

      this.cancelLeave();
      this._leave = Ember.run.later(function () {
        return _this.setOpen(false);
      }, 200);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.cancelLeave();
    }
  });

  _exports.default = _default;
});