define("boekdrukken/services/models", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lookup = _exports.default = void 0;

  var lookup = function lookup(owner) {
    return Ember.getOwner(owner).lookup('service:models');
  };

  _exports.lookup = lookup;

  var _default = Ember.Service.extend({
    normalize: function normalize(name) {
      return Ember.String.dasherize(name);
    },
    factoryFor: function factoryFor(name) {
      var normalizedName = this.normalize(name);
      var fullName = "model:".concat(normalizedName);
      var factory = Ember.getOwner(this).factoryFor(fullName);
      (false && !(!!factory) && Ember.assert("model '".concat(normalizedName, "' is not registered in container"), !!factory));
      return factory;
    },
    create: function create(name, props) {
      return this.factoryFor(name).create(props);
    }
  });

  _exports.default = _default;
});