define("boekdrukken/components/ui-dialog/unlock-product-parameters/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "shTPSXXL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[1,[28,\"t\",[\"dialog.unlock-product-parameters.header\"],null],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"dialog.unlock-product-parameters.content.description\"],null],false],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"dialog.unlock-product-parameters.content.more\"],[[\"externalChecklistURL\",\"help\",\"htmlSafe\"],[[24,[\"externalChecklistURL\"]],[28,\"widow\",[[28,\"t\",[\"dialog.unlock-product-parameters.content.help\"],null]],null],true]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[1,[28,\"ui-input/button\",null,[[\"size\",\"value\",\"action\",\"class\"],[\"small\",[28,\"t\",[\"dialog.unlock-product-parameters.actions.cancel\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null],\"action\"]]],false],[0,\"\\n  \"],[1,[28,\"ui-input/button\",null,[[\"size\",\"value\",\"color\",\"action\",\"class\"],[\"small\",[28,\"t\",[\"dialog.unlock-product-parameters.actions.unlock\"],null],\"danger\",[28,\"action\",[[23,0,[]],\"confirm\"],null],\"action\"]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-dialog/unlock-product-parameters/template.hbs"
    }
  });

  _exports.default = _default;
});