define("boekdrukken/routes/account/sign-out", ["exports", "ember-cli-zuglet/lifecycle", "boekdrukken/routes/-auth", "boekdrukken/routes/account/-account-mixin"], function (_exports, _lifecycle, _auth, _accountMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_auth.default, {
    require: 'authenticated',
    model: (0, _lifecycle.route)().inline(_accountMixin.default, {
      prepare: function prepare() {},
      load: function load() {
        this.store.user.signOut();
      }
    })
  });

  _exports.default = _default;
});