define("boekdrukken/components/ui-block/cart/content/newsletter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N6No2Y58",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isEditable\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"ui-block/card\",null,[[\"type\",\"class\"],[\"minimal\",\"card\"]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"block.cart.content.newsletter.header.title\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n      \"],[1,[28,\"ui-input/checkbox\",null,[[\"class\",\"size\",\"type\",\"checked\",\"update\"],[\"checkbox\",\"small\",\"outline\",[24,[\"newsletter\",\"isEnabled\"]],[28,\"action\",[[23,0,[]],\"update\"],null]]]],false],[0,\"\\n      \"],[7,\"div\",false],[12,\"class\",\"label\"],[3,\"action\",[[23,0,[]],\"update\",[28,\"not\",[[24,[\"newsletter\",\"isEnabled\"]]],null]]],[8],[1,[28,\"t\",[\"block.cart.content.newsletter.label\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/cart/content/newsletter/template.hbs"
    }
  });

  _exports.default = _default;
});