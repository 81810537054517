define("boekdrukken/components/ui-block/sketch/stage/node/area/accessories/book-crop-marks/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yLMW0B1h",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"bleedingOverlayStyle\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"overlay\"],[11,\"style\",[22,\"bleedingOverlayStyle\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"marks\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mark edge top left\"],[11,\"style\",[24,[\"mark\",\"edge\",\"top\",\"left\"]]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mark edge top right\"],[11,\"style\",[24,[\"mark\",\"edge\",\"top\",\"right\"]]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mark edge bottom left\"],[11,\"style\",[24,[\"mark\",\"edge\",\"bottom\",\"left\"]]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mark edge bottom right\"],[11,\"style\",[24,[\"mark\",\"edge\",\"bottom\",\"right\"]]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mark spine\"],[11,\"style\",[24,[\"mark\",\"spine\"]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"unless\",[[24,[\"rendering\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"labels\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"label left\"],[8],[1,[28,\"t\",[\"block.sketch.stage.node.area.accessories.book-crop-marks.label.back\"],null],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"label spine\"],[8],[1,[28,\"t\",[\"block.sketch.stage.node.area.accessories.book-crop-marks.label.spine\"],null],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"label right\"],[8],[1,[28,\"t\",[\"block.sketch.stage.node.area.accessories.book-crop-marks.label.front\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/stage/node/area/accessories/book-crop-marks/template.hbs"
    }
  });

  _exports.default = _default;
});