define("boekdrukken/instance-initializers/boekdrukken-intl", ["exports", "boekdrukken/utils/intl/missing-message"], function (_exports, _missingMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'boekdrukken:intl',
    initialize: function initialize(app) {
      app.register('util:intl/missing-message', function (key, locales) {
        if (!Ember.isEmpty(locales)) {// ..
        }

        return (0, _missingMessage.default)(key, locales);
      });
    }
  };
  _exports.default = _default;
});