define("boekdrukken/models/sketch/strings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var string = function string(key) {
    return Ember.computed('intl.locale', function () {
      return this.intl.t(key);
    }).readOnly();
  };

  var _default = Ember.Object.extend({
    intl: Ember.inject.service(),
    edit: string('model.sketch.strings.edit'),
    loadingFont: string('model.sketch.strings.loading-font')
  });

  _exports.default = _default;
});