define("boekdrukken/components/ui-block/pdf/page/color-types/segmented-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-pdf-page-color-types-segmented-button'],
    size: 'small'
  });

  _exports.default = _default;
});