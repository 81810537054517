define("boekdrukken/components/ui-application/alerts/alert/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N8pyJe05",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"if\",[[24,[\"model\",\"icon\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"ui-block/icon\",[[24,[\"model\",\"icon\"]]],[[\"class\"],[\"icon\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"description\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"type\"]],\"normal\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"item normal\"],[8],[1,[23,1,[\"value\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"type\"]],\"strong\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"item strong\"],[8],[1,[23,1,[\"value\"]],false],[9],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-application/alerts/alert/template.hbs"
    }
  });

  _exports.default = _default;
});