define("boekdrukken/models/users/user/products/steps/book/cover", ["exports", "boekdrukken/models/users/user/products/steps/-step"], function (_exports, _step) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _step.default.extend({
    component: Ember.computed.readOnly('product.cover'),
    willDestroy: function willDestroy() {
      this.component.render();

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});