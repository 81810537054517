define("boekdrukken/util/auth", ["exports", "boekdrukken/util/t"], function (_exports, _t) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.errorMessage = void 0;

  var message = function message(key) {
    return "route.account.errors.".concat(key);
  };

  var messages = {
    'auth/wrong-password': message('wrong-password'),
    'auth/user-not-found': message('user-not-found'),
    'auth/email-already-in-use': message('email-already-in-use'),
    'auth/weak-password': message('weak-password')
  };

  var errorMessage = function errorMessage(key) {
    return Ember.computed("".concat(key, ".{code,message}"), function () {
      var error = this.get(key);

      if (!error) {
        return;
      }

      return messages[error.code] || (0, _t.nt)(error.message);
    }).readOnly();
  };

  _exports.errorMessage = errorMessage;
});