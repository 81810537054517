define("boekdrukken/models/analytics/hotjar", ["exports", "analytics/models/analytics/hotjar"], function (_exports, _hotjar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _hotjar.default;
    }
  });
});