define("boekdrukken/routes/standalone", ["exports", "ember-cli-zuglet/lifecycle", "boekdrukken/routes/-breadcrumb", "boekdrukken/routes/-alerts"], function (_exports, _lifecycle, _breadcrumb, _alerts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_breadcrumb.default, _alerts.default, {
    model: (0, _lifecycle.route)().inline({
      analyticsSuppressed: true,
      alerts: (0, _alerts.alerts)({
        suspend: ['mobile']
      }),
      breadcrumb: (0, _breadcrumb.breadcrumb)({
        layout: 'blank'
      }),
      prepare: function prepare() {}
    })
  });

  _exports.default = _default;
});