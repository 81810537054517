define("boekdrukken/components/ui-route/users/user/products/product/delete/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-route-users-user-products-product-delete'],
    product: Ember.computed.readOnly('model.product'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.model.delete();
    }
  });

  _exports.default = _default;
});