define("boekdrukken/util/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rejectWithError = _exports.error = void 0;

  var error = function error(message, code) {
    var err = new Ember.Error(message);

    if (code) {
      err.code = code;
    }

    return err;
  };

  _exports.error = error;

  var rejectWithError = function rejectWithError(message, code) {
    return Ember.RSVP.reject(error(message, code));
  };

  _exports.rejectWithError = rejectWithError;
});