define("boekdrukken/components/ui-block/sketch/inspector/section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-sketch-inspector-section'],
    sketch: null,
    model: null,
    isTemplate: Ember.computed.readOnly('sketch.isTemplate'),
    actions: {
      update: function update(key, value) {
        this.model.update(_defineProperty({}, key, value));
      },
      move: function move(type) {
        if (type === 'backward') {
          this.model.node.moveDown();
        } else if (type === 'forward') {
          this.model.node.moveUp();
        }
      },
      remove: function remove() {
        this.model.remove();
      }
    }
  });

  _exports.default = _default;
});