define("boekdrukken/routes/-accessory", ["exports", "ember-cli-zuglet/lifecycle"], function (_exports, _lifecycle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.accessory = _exports.accessories = void 0;

  var accessories = function accessories(opts) {
    return (0, _lifecycle.model)().inline(opts).mapping(function (owner) {
      return {
        owner: owner
      };
    });
  };

  _exports.accessories = accessories;

  var accessory = function accessory() {
    var _Ember;

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var opts = Ember.assign({}, args.pop());
    return (_Ember = Ember).computed.apply(_Ember, args.concat([function () {
      var owner = this.owner;

      var lookup = function lookup(key) {
        var value = opts[key];

        if (typeof value === 'function') {
          value = value.call(owner, owner);
        }

        return value;
      };

      var component = lookup('component');
      var model = lookup('model');
      return {
        component: component,
        model: model
      };
    }])).readOnly();
  };

  _exports.accessory = accessory;

  var _default = Ember.Mixin.create({
    accessories: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this.accessories.remove(controller.model);

      this._super.apply(this, arguments);

      this.accessories.add(model);
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this.accessories.remove(this.modelFor(this.routeName));
    }
  });

  _exports.default = _default;
});