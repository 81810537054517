define("boekdrukken/models/users/user/products/parameters/book/content/paper", ["exports", "boekdrukken/models/users/user/products/parameters/book/-paper"], function (_exports, _paper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paper.default.extend({});

  _exports.default = _default;
});