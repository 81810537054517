define("boekdrukken/components/ui-block/product/step/product/component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qHrqICDV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"name\"],[8],[1,[24,[\"component\",\"title\"]],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[1,[28,\"widow\",[[24,[\"component\",\"description\"]]],null],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/product/step/product/component/template.hbs"
    }
  });

  _exports.default = _default;
});