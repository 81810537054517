define("boekdrukken/components/ui-block/cart/content/signup/component", ["exports", "ember-cli-zuglet/lifecycle"], function (_exports, _lifecycle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-cart-content-signup', 'hidden:hidden'],
    cart: null,
    highlight: null,
    user: Ember.computed.readOnly('cart.user'),
    isAnonymous: Ember.computed.readOnly('user.isAnonymous'),
    isSkipped: false,
    notSkipped: Ember.computed.not('isSkipped'),
    isShown: Ember.computed.and('isAnonymous', 'notSkipped'),
    hidden: Ember.computed.not('isShown'),
    model: (0, _lifecycle.model)().named('users/user/cart/signup').mapping(function () {
      return {};
    }),
    actions: {
      commit: function commit() {
        this.model.commit();
      },
      skip: function skip() {
        this.setProperties({
          isSkipped: true
        });
        this.cart.setRequiresEmailAddress();
      }
    }
  });

  _exports.default = _default;
});