define("boekdrukken/templates/backend/orders/range/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MgrEqhiu",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ui-route/backend/orders/range/index\",null,[[\"model\"],[[24,[\"model\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/templates/backend/orders/range/index.hbs"
    }
  });

  _exports.default = _default;
});