define("boekdrukken/components/ui-block/cart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OSothPpY",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"cart\",\"items\",\"ordered\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"ui-block/container\",null,[[\"type\",\"class\"],[\"centered\",\"header\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"block.cart.header.title\"],null],false],[9],[0,\"\\n\"],[4,\"unless\",[[24,[\"cart\",\"hasCustomItems\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n        \"],[1,[28,\"ui-input/button\",null,[[\"class\",\"size\",\"type\",\"value\",\"action\"],[\"action\",\"small\",\"outline\",[28,\"t\",[\"block.cart.header.actions.new\"],null],[28,\"action\",[[23,0,[]],\"new\"],null]]]],false],[0,\"\\n        \"],[1,[28,\"ui-input/button\",null,[[\"class\",\"size\",\"type\",\"value\",\"action\"],[\"action\",\"small\",\"outline\",[28,\"t\",[\"block.cart.header.actions.products\"],null],[28,\"action\",[[23,0,[]],\"products\"],null]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[28,\"ui-block/cart/content\",null,[[\"cart\",\"class\"],[[24,[\"cart\"]],\"content\"]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n  \"],[1,[28,\"ui-block/cart/placeholder\",null,[[\"cart\",\"class\"],[[24,[\"cart\"]],\"placeholder\"]]],false],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/cart/template.hbs"
    }
  });

  _exports.default = _default;
});