define("boekdrukken/components/ui-block/product/book/step/parameters/cover/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':section', ':cover'],
    cover: Ember.computed.readOnly('parameters.cover'),
    finish: Ember.computed.readOnly('cover.finish'),
    paper: Ember.computed.readOnly('cover.paper'),
    actions: {
      update: function update(parameter, value) {
        parameter.update(value);
      }
    }
  });

  _exports.default = _default;
});