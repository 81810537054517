define("boekdrukken/components/ui-block/cart/content/payment/component", ["exports", "boekdrukken/models/stripe/payment"], function (_exports, _payment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-cart-content-payment'],
    payment: (0, _payment.payment)(function (payment) {
      this.cart.payment.setPaymentImplementation(payment);
    })
  });

  _exports.default = _default;
});