define("boekdrukken/components/ui-block/product/book/step/cover/templates/content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/hwOKO9C",
    "block": "{\"symbols\":[\"template\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"templates\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"templates\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"ui-block/product/book/step/cover/templates/content/template\",null,[[\"template\",\"selected\",\"select\"],[[23,1,[]],[28,\"eq\",[[23,1,[]],[24,[\"selected\"]]],null],[28,\"action\",[[23,0,[]],\"select\",[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[28,\"ui-block/product/book/step/cover/templates/content/selected\",null,[[\"selected\"],[[24,[\"selected\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/product/book/step/cover/templates/content/template.hbs"
    }
  });

  _exports.default = _default;
});