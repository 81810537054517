define("boekdrukken/models/sketch/base/thumbnail", ["exports", "boekdrukken/models/sketch/base/-operation"], function (_exports, _operation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var thumbnail = function thumbnail(key) {
    return Ember.computed.readOnly("sketch.doc.data.thumbnail.".concat(key));
  };

  var _default = _operation.default.extend({
    sketch: null,
    url: thumbnail('url'),
    dimensions: thumbnail('dimensions.serialized'),
    exists: Ember.computed.bool('url'),
    _optionsForCreateOperation: function _optionsForCreateOperation() {
      var size = this.sketch.stage.size;
      var offset = 20;
      var width = 1024;
      var scale = width / size.width;
      var height = Math.ceil(size.height * scale);
      width = offset + width + offset;
      height = offset + height + offset;
      return {
        type: 'image',
        dimensions: {
          width: width,
          height: height,
          offset: offset
        }
      };
    }
  });

  _exports.default = _default;
});