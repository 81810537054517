define("boekdrukken/components/ui-block/product/book/step/cover/templates/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lz/qOIJm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"class\",\"button\"],[3,\"action\",[[23,0,[]],\"choose\"]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"value\"],[8],[1,[28,\"t\",[\"block.product.book.step.cover.templates.label\"],null],false],[9],[0,\"\\n  \"],[1,[28,\"ui-block/icon\",null,[[\"class\",\"name\"],[\"icon\",\"chevron-right\"]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/product/book/step/cover/templates/template.hbs"
    }
  });

  _exports.default = _default;
});