define("boekdrukken/components/ui-route/users/user/products/product/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2Xz67Bqc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"ui-block/dialogs\",null,[[\"label\"],[\"product\"]]],false],[0,\"\\n\\n\"],[14,1],[0,\"\\n\\n\"],[4,\"unless\",[[24,[\"model\",\"product\",\"isError\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"ui-block/flow/navigation\",null,[[\"flow\",\"disabled\",\"class\"],[[24,[\"flow\"]],[24,[\"model\",\"product\",\"isPricingError\"]],\"flow\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-route/users/user/products/product/template.hbs"
    }
  });

  _exports.default = _default;
});