define("boekdrukken/components/ui-block/sketch/toolbar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YtmyTHMh",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"switchChoices\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"ui-block/sketch/switch\",null,[[\"sketch\",\"choices\",\"class\"],[[24,[\"sketch\"]],[24,[\"switchChoices\"]],\"switch\"]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"center\"],[8],[0,\"\\n    \"],[1,[28,\"ui-block/sketch/tools\",null,[[\"sketch\",\"class\"],[[24,[\"sketch\"]],\"tools\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"ui-block/sketch/tools\",null,[[\"sketch\",\"class\"],[[24,[\"sketch\"]],\"tools\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[1,[28,\"ui-block/sketch/zoom\",null,[[\"sketch\",\"class\"],[[24,[\"sketch\"]],\"zoom\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/toolbar/template.hbs"
    }
  });

  _exports.default = _default;
});