define("boekdrukken/components/ui-block/stripe/selection/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':selection'],
    actions: {
      select: function select(type) {
        this.payment.selectType(type);
      }
    }
  });

  _exports.default = _default;
});