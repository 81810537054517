define("boekdrukken/components/ui-route/stylebook/shape/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1pXN4Kvg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"stylebook.shape\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"section\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"stylebook.default\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content col\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"full-width full-height\"],[8],[0,\"\\n\"],[4,\"ui-block/shape\",null,[[\"type\"],[\"default\"]],{\"statements\":[],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"section\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"stylebook.regular\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content col\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"full-width full-height\"],[8],[0,\"\\n\"],[4,\"ui-block/shape\",null,[[\"type\"],[\"regular\"]],{\"statements\":[],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"section\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"stylebook.warning\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content col\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"full-width full-height\"],[8],[0,\"\\n\"],[4,\"ui-block/shape\",null,[[\"type\"],[\"warning\"]],{\"statements\":[],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-route/stylebook/shape/template.hbs"
    }
  });

  _exports.default = _default;
});