define("boekdrukken/components/ui-block/stripe/types/card/component", ["exports", "boekdrukken/components/ui-block/stripe/types/-type"], function (_exports, _type) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _type.default.extend({
    actions: {
      updateName: function updateName(value) {
        this.type.updateName(value);
      }
    }
  });

  _exports.default = _default;
});