define("boekdrukken/models/analytics/events/-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.event = _exports.default = void 0;

  var event = function event(fn) {
    return function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var opts = fn.call.apply(fn, [this].concat(args));

      if (!opts) {
        return;
      }

      return this.send(opts);
    };
  };

  _exports.event = event;

  var _default = Ember.Object.extend({
    events: null,
    analytics: Ember.computed.readOnly('events.analytics'),
    category: null,
    send: function send(opts) {
      var category = this.category;
      opts = Ember.assign({
        category: category
      }, opts);
      return this.analytics.ga.sendEvent(opts);
    }
  });

  _exports.default = _default;
});