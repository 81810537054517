define("boekdrukken/components/ui-route/maintenance/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e+EsnBT5",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ui-block/container\",null,[[\"type\",\"class\"],[\"centered\",\"master\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"logo\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"route.maintenance.title\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-route/maintenance/template.hbs"
    }
  });

  _exports.default = _default;
});