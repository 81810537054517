define("boekdrukken/util/runloop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.delay = void 0;

  var delay = function delay(amount) {
    return new Ember.RSVP.Promise(function (resolve) {
      return Ember.run.later(resolve, amount);
    });
  };

  _exports.delay = delay;
});