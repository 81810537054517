define("boekdrukken/components/ui-route/backend/orders/range/index/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':route-backend-orders-range-index'],
    actions: {
      previous: function previous() {
        this.model.previous();
      },
      next: function next() {
        this.model.next();
      }
    }
  });

  _exports.default = _default;
});