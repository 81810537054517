define("boekdrukken/components/ui-block/cart/content/legal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R1VHxc4X",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ui-block/card\",null,[[\"type\",\"class\"],[\"minimal\",\"card\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"block.cart.content.legal.header.title\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[1,[28,\"ui-input/checkbox\",null,[[\"class\",\"size\",\"type\",\"checked\",\"update\"],[\"checkbox\",\"small\",\"outline\",[24,[\"legal\",\"privacy\"]],[28,\"action\",[[23,0,[]],\"update\",\"privacy\"],null]]]],false],[0,\"\\n      \"],[7,\"a\",true],[10,\"class\",\"label\"],[11,\"href\",[24,[\"legal\",\"links\",\"privacy\"]]],[10,\"target\",\"top\"],[8],[1,[28,\"t\",[\"block.cart.content.legal.label.privacy\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[1,[28,\"ui-input/checkbox\",null,[[\"class\",\"size\",\"type\",\"checked\",\"update\"],[\"checkbox\",\"small\",\"outline\",[24,[\"legal\",\"terms\"]],[28,\"action\",[[23,0,[]],\"update\",\"terms\"],null]]]],false],[0,\"\\n      \"],[7,\"a\",true],[10,\"class\",\"label\"],[11,\"href\",[24,[\"legal\",\"links\",\"terms\"]]],[10,\"target\",\"top\"],[8],[1,[28,\"t\",[\"block.cart.content.legal.label.terms\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/cart/content/legal/template.hbs"
    }
  });

  _exports.default = _default;
});