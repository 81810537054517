define("boekdrukken/models/sketch/base/stage/node/rect", ["exports", "boekdrukken/models/sketch/base/stage/node"], function (_exports, _node) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _node.default.extend({
    name: 'model.sketch.base.stage.node.rect.name',
    description: Ember.computed.readOnly('name'),
    node: (0, _node.node)({
      type: 'sized'
    }),
    guidelines: (0, _node.guidelines)(),
    x: (0, _node.x)(),
    y: (0, _node.y)(),
    width: (0, _node.width)(),
    height: (0, _node.height)(),
    rotation: (0, _node.rotation)(),
    aspect: (0, _node.aspect)(),
    fill: (0, _node.fill)(),
    opacity: (0, _node.opacity)()
  });

  _exports.default = _default;
});