define("boekdrukken/components/ui-block/sketch/inspector/section/slider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SS34TJw/",
    "block": "{\"symbols\":[\"attribute\"],\"statements\":[[4,\"let\",[[28,\"get\",[[24,[\"model\",\"node\",\"attributes\"]],[24,[\"key\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",\"label\"],[3,\"action\",[[23,0,[]],\"reset\"]],[8],[1,[22,\"label\"],false],[9],[0,\"\\n  \"],[1,[28,\"ui-input/slider\",null,[[\"min\",\"max\",\"step\",\"value\",\"update\"],[[23,1,[\"min\"]],[23,1,[\"max\"]],[24,[\"step\"]],[28,\"get\",[[24,[\"model\"]],[24,[\"key\"]]],null],[28,\"action\",[[23,0,[]],[24,[\"update\"]],[24,[\"key\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/inspector/section/slider/template.hbs"
    }
  });

  _exports.default = _default;
});