define("boekdrukken/components/ui-block/sketch/zoom/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Sq42wk4b",
    "block": "{\"symbols\":[\"zoom\"],\"statements\":[[1,[28,\"ui-input/button\",null,[[\"type\",\"size\",\"value\",\"action\",\"class\"],[\"outline\",\"tiny\",[28,\"t\",[\"block.sketch.zoom.fit\"],null],[28,\"action\",[[23,0,[]],\"fit\"],null],\"fit\"]]],false],[0,\"\\n\"],[4,\"let\",[[24,[\"stage\",\"node\",\"attributes\",\"zoom\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"ui-input/slider\",null,[[\"min\",\"max\",\"value\",\"step\",\"update\",\"class\"],[[23,1,[\"min\"]],[23,1,[\"max\"]],[24,[\"stage\",\"zoom\"]],[23,1,[\"step\"]],[28,\"action\",[[23,0,[]],\"update\"],null],\"slider\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/zoom/template.hbs"
    }
  });

  _exports.default = _default;
});