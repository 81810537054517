define("boekdrukken/services/window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    scrollbarWidth: Ember.computed(function () {
      var outer = document.createElement('div');
      outer.style.visibility = 'hidden';
      outer.style.overflow = 'scroll';
      document.body.appendChild(outer);
      var inner = document.createElement('div');
      outer.appendChild(inner);
      var scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
      document.body.removeChild(outer);
      return scrollbarWidth;
    }).readOnly(),
    absoluteUrl: function absoluteUrl(path) {
      var _window$location = window.location,
          protocol = _window$location.protocol,
          host = _window$location.host;
      return "".concat(protocol, "//").concat(host).concat(path);
    },
    open: function open(url) {
      var blank = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      if (blank) {
        window.open(url, '_blank');
      } else {
        window.location = url;
      }
    },
    openPath: function openPath(path) {
      var url = this.absoluteUrl(path);
      this.open(url);
    }
  });

  _exports.default = _default;
});