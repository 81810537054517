define("boekdrukken/components/ui-block/accessory/flow/steps/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eqPRCMIR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"accessory\",\"model\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"ui-block/flow/steps\",null,[[\"flow\",\"componentOwner\"],[[24,[\"accessory\",\"model\"]],[24,[\"componentOwner\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/accessory/flow/steps/template.hbs"
    }
  });

  _exports.default = _default;
});