define("boekdrukken/helpers/widow", ["exports", "design/helpers/widow"], function (_exports, _widow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _widow.default;
    }
  });
  Object.defineProperty(_exports, "widow", {
    enumerable: true,
    get: function get() {
      return _widow.widow;
    }
  });
});