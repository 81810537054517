define("boekdrukken/components/ui-block/product/step/errors/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RA2aFZJh",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"description\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    \"],[1,[28,\"ui-block/icon\",[\"times-circle\"],[[\"class\"],[\"icon\"]]],false],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"message\"],[8],[1,[24,[\"description\",\"label\"]],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"description\",\"action\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"ui-input/button\",null,[[\"class\",\"color\",\"type\",\"size\",\"value\",\"action\"],[\"action\",\"warning\",\"outline\",\"tiny\",[28,\"t\",[[24,[\"description\",\"action\",\"label\"]]],null],[28,\"action\",[[23,0,[]],[24,[\"description\",\"action\",\"type\"]],[24,[\"description\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/product/step/errors/template.hbs"
    }
  });

  _exports.default = _default;
});