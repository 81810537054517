define("boekdrukken/components/ui-route/stylebook/color-picker/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-route-stylebook-page', ':ui-route-stylebook-color-picker'],
    colors: Object.freeze(['white', 'black', 'yellow', '#172b4d', '#5e72e4', '#2dce89', '#11cdef', '#fb6340']),
    color: '#990000',
    colorStyle: Ember.computed('color', function () {
      var color = this.color;
      return Ember.String.htmlSafe("width: 100px; height: 50px; background-color: ".concat(color));
    }).readOnly(),
    actions: {
      update: function update(color) {
        this.setProperties({
          color: color
        });
      },
      setColor: function setColor(value) {
        this.setProperties({
          color: value
        });
      }
    }
  });

  _exports.default = _default;
});