define("boekdrukken/components/ui-route/stylebook/typography/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var titles = [1, 2, 3, 4];
  var headings = [1, 2, 3, 4, 5, 6];

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-route-stylebook-page', ':ui-route-stylebook-typography'],
    titles: titles,
    headings: headings
  });

  _exports.default = _default;
});