define("boekdrukken/components/ui-block/product/book/step/parameters/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t2ui/EaI",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ui-block/container\",null,[[\"type\",\"class\"],[\"centered-wide\",\"master\"]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"ui-block/product/book/step/parameters/cover\",null,[[\"parameters\"],[[24,[\"step\"]]]]],false],[0,\"\\n  \"],[1,[28,\"ui-block/product/book/step/parameters/content\",null,[[\"parameters\"],[[24,[\"step\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/product/book/step/parameters/template.hbs"
    }
  });

  _exports.default = _default;
});