define("boekdrukken/routes/users/user/products/product/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var model = this.modelFor('users.user.products.product');
      var flow = model.flow;

      if (!flow) {
        return;
      }

      return flow.initial.transitionTo();
    }
  });

  _exports.default = _default;
});