define("boekdrukken/components/ui-block/product/book/step/content/spread/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var delta = function delta(value) {
    return Ember.computed('selection', 'pdf.pairs.[]', function () {
      var pair = this.selection;
      var pairs = this.pdf.pairs;
      var index = pairs.indexOf(pair) + value;

      if (index < 0 || index > pairs.length - 1) {
        return;
      }

      return pairs.objectAt(index);
    }).readOnly();
  };

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-product-book-step-content-spread'],
    selection: null,
    pdf: null,
    previous: delta(-1),
    next: delta(+1),
    actions: {
      select: function select(pair) {
        this.select(pair);
      }
    }
  });

  _exports.default = _default;
});