define("boekdrukken/components/ui-block/accessory/popup/icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vOfPUnPI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[24,[\"opts\",\"title\"]],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"opts\",\"details\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"details\"],[8],[1,[24,[\"opts\",\"details\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[1,[28,\"ui-block/icon\",[[24,[\"opts\",\"icon\"]]],[[\"class\"],[\"icon\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/accessory/popup/icon/template.hbs"
    }
  });

  _exports.default = _default;
});