define("boekdrukken/components/ui-route/stylebook/progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hzu9u7B4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"stylebook.progress-bar\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"section\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"stylebook.value\"],null],false],[0,\": \"],[1,[22,\"value\"],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n      \"],[1,[28,\"ui-input/slider\",null,[[\"min\",\"max\",\"value\",\"update\"],[0,100,[24,[\"value\"]],[28,\"action\",[[23,0,[]],\"update\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item row\"],[8],[0,\"\\n      \"],[1,[28,\"ui-input/button\",null,[[\"value\",\"size\",\"action\"],[\"0\",\"small\",[28,\"action\",[[23,0,[]],\"update\",0],null]]]],false],[0,\"\\n      \"],[1,[28,\"ui-input/button\",null,[[\"value\",\"size\",\"action\"],[\"23\",\"small\",[28,\"action\",[[23,0,[]],\"update\",23],null]]]],false],[0,\"\\n      \"],[1,[28,\"ui-input/button\",null,[[\"value\",\"size\",\"action\"],[\"50\",\"small\",[28,\"action\",[[23,0,[]],\"update\",50],null]]]],false],[0,\"\\n      \"],[1,[28,\"ui-input/button\",null,[[\"value\",\"size\",\"action\"],[\"100\",\"small\",[28,\"action\",[[23,0,[]],\"update\",100],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"section\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"stylebook.regular\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item\"],[10,\"style\",\"width: 400px\"],[8],[0,\"\\n      \"],[1,[28,\"ui-block/progress-bar\",null,[[\"value\"],[[24,[\"value\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-route/stylebook/progress-bar/template.hbs"
    }
  });

  _exports.default = _default;
});