define("boekdrukken/components/ui-application/header/auth/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var routes = {
    any: ['account.new', 'account.restore', 'account.reset'],
    user: []
  };

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-application-header-auth', 'isHidden:hidden'],
    currentRouteName: Ember.computed.readOnly('router.currentRouteName'),
    user: Ember.computed.readOnly('store.user'),
    isAnonymous: Ember.computed.readOnly('user.isAnonymous'),
    isHidden: Ember.computed('currentRouteName', 'user', function () {
      var currentRouteName = this.currentRouteName,
          user = this.user;

      if (routes.any.includes(currentRouteName)) {
        return true;
      }

      if (user && routes.user.includes(currentRouteName)) {
        return true;
      }

      return false;
    }),
    email: Ember.computed('user.email', function () {
      var email = this.get('user.email');

      if (!email) {
        return;
      }

      var _email$split = email.split('@'),
          _email$split2 = _slicedToArray(_email$split, 2),
          name = _email$split2[0],
          domain = _email$split2[1];

      return {
        name: name,
        domain: domain
      };
    }).readOnly(),
    actions: {
      profile: function profile() {
        this.router.transitionTo('users');
      },
      signIn: function signIn() {
        this.router.transitionTo('account.new');
      }
    }
  });

  _exports.default = _default;
});