define("boekdrukken/components/ui-block/pdf/-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sizeStyle = _exports.size = _exports.pdf = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var floor = Math.floor;

  var pdf = function pdf(prop) {
    return Ember.computed.readOnly("pdf.".concat(prop));
  };

  _exports.pdf = pdf;

  var size = function size(dimensionsKey, widthKey, heightKey) {
    var _Ember;

    var deps = [dimensionsKey, widthKey, heightKey].filter(Boolean);
    return (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(deps).concat([function () {
      var dimensions = this.get(dimensionsKey);

      if (!dimensions) {
        return;
      }

      var pt = Ember.get(dimensions, 'pt');

      var _Ember$getProperties = Ember.getProperties(pt, 'width', 'height'),
          width = _Ember$getProperties.width,
          height = _Ember$getProperties.height;

      var makeScaled = function makeScaled(scale) {
        return {
          width: floor(width * scale),
          height: floor(height * scale)
        };
      };

      var max = this.get(widthKey);
      var scaled = makeScaled(max / width);

      if (heightKey) {
        max = this.get(heightKey);

        if (max && scaled.height > max) {
          scaled = makeScaled(max / height);
        }
      }

      return scaled;
    }])).readOnly();
  };

  _exports.size = size;

  var sizeStyle = function sizeStyle(sizeKey) {
    return Ember.computed(sizeKey, function () {
      var size = this.get(sizeKey);

      if (!size) {
        return;
      }

      var width = size.width,
          height = size.height;
      return Ember.String.htmlSafe("width: ".concat(width, "px; height: ").concat(height, "px"));
    }).readOnly();
  };

  _exports.sizeStyle = sizeStyle;
});