define("boekdrukken/components/ui-block/localization/editor/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    classNameBindings: [':item', 'item.hasErrors:error'],
    actions: {
      update: function update(key, value) {
        this.item.update(_defineProperty({}, key, value));
      }
    }
  });

  _exports.default = _default;
});