define("boekdrukken/components/ui-route/backend/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5IkfJJKm",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ui-block/container\",null,[[\"type\",\"class\"],[\"margin\",\"content\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[1,[28,\"t\",[\"route.backend.index.title\"],null],false],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"links\"],[8],[0,\"\\n    \"],[4,\"link-to\",null,[[\"route\"],[\"backend.templates\"]],{\"statements\":[[1,[28,\"t\",[\"route.backend.index.actions.templates\"],null],false]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"link-to\",null,[[\"route\"],[\"backend.localization\"]],{\"statements\":[[1,[28,\"t\",[\"route.backend.index.actions.localization\"],null],false]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"link-to\",null,[[\"route\"],[\"backend.orders\"]],{\"statements\":[[1,[28,\"t\",[\"route.backend.index.actions.orders\"],null],false]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"link-to\",null,[[\"route\"],[\"backend.subscriptions\"]],{\"statements\":[[1,[28,\"t\",[\"route.backend.index.actions.subscriptions\"],null],false]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"isDevops\"]]],null,{\"statements\":[[0,\"      \"],[4,\"link-to\",null,[[\"route\"],[\"backend.xml\"]],{\"statements\":[[1,[28,\"t\",[\"route.backend.index.actions.xml\"],null],false]],\"parameters\":[]},null],[0,\"\\n      \"],[4,\"link-to\",null,[[\"route\"],[\"stylebook\"]],{\"statements\":[[1,[28,\"t\",[\"route.backend.index.actions.stylebook\"],null],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"isFooterVisible\"]]],null,{\"statements\":[[4,\"ui-block/container\",null,[[\"type\",\"class\"],[\"compact\",\"footer\"]],{\"statements\":[[0,\"    \"],[1,[22,\"ui-block/dev/index\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-route/backend/index/template.hbs"
    }
  });

  _exports.default = _default;
});