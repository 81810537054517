define("boekdrukken/components/ui-route/users/user/products/product/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7PAaz2zL",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"product\",\"isError\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"ui-block/container\",null,[[\"type\",\"class\"],[\"centered\",\"error\"]],{\"statements\":[[4,\"ui-block/card\",null,[[\"class\",\"type\"],[\"card\",\"minimal\"]],{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"route.users.user.products.product.index.title\"],null],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"code\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[1,[28,\"t\",[[28,\"concat\",[\"route.users.user.products.product.index.errors.\",[24,[\"code\"]]],null]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n        \"],[1,[28,\"ui-input/button\",null,[[\"class\",\"size\",\"value\",\"action\"],[\"action\",\"small\",[28,\"t\",[\"route.users.user.products.product.index.actions.delete\"],null],[28,\"action\",[[23,0,[]],\"delete\"],null]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-route/users/user/products/product/index/template.hbs"
    }
  });

  _exports.default = _default;
});