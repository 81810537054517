define("boekdrukken/components/ui-route/backend/index/component", ["exports", "boekdrukken/util/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-route-backend-index'],
    isDevelopmentBuild: (0, _computed.isDevelopmentBuild)(),
    isDevops: Ember.computed.readOnly('store.user.isDevops'),
    isFooterVisible: Ember.computed.and('isDevops', 'isDevelopmentBuild')
  });

  _exports.default = _default;
});