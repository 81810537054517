define("boekdrukken/components/ui-route/internal/render/template/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3fUIoEtj",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ui-block/sketch/stage\",null,[[\"stage\",\"size\",\"class\",\"ready\"],[[24,[\"model\",\"sketch\",\"stage\"]],[24,[\"size\"]],\"stage\",[28,\"action\",[[23,0,[]],\"ready\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-route/internal/render/template/template.hbs"
    }
  });

  _exports.default = _default;
});