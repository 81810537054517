define("boekdrukken/models/users/user/products/steps/custom/summary", ["exports", "boekdrukken/models/users/user/products/steps/-step"], function (_exports, _step) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _step.default.extend({});

  _exports.default = _default;
});