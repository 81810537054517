define("boekdrukken/components/ui-block/sketch/tree/models/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':models'],
    nodes: Ember.computed.readOnly('model.nodes'),
    reversed: Ember.computed('nodes.[]', function () {
      var nodes = this.nodes;

      if (!nodes) {
        return;
      }

      return nodes.slice().reverse();
    }).readOnly()
  });

  _exports.default = _default;
});