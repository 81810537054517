define("boekdrukken/components/ui-block/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kZxbKNfI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"ui-block/container\",null,[[\"type\",\"class\"],[\"centered\",\"content\"]],{\"statements\":[[4,\"ui-block/card\",null,[[\"class\",\"type\"],[\"card\",\"minimal\"]],{\"statements\":[[0,\"    \"],[1,[28,\"ui-block/icon\",[\"cog\"],[[\"spin\",\"class\"],[true,\"icon\"]]],false],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"      \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[[24,[\"message\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/loading/template.hbs"
    }
  });

  _exports.default = _default;
});