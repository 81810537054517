define("boekdrukken/routes/users", ["exports", "boekdrukken/routes/-maintenance", "boekdrukken/routes/-auth"], function (_exports, _maintenance, _auth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_maintenance.default, _auth.default, {
    require: 'authenticated'
  });

  _exports.default = _default;
});