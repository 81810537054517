define("boekdrukken/components/ui-route/standalone/add/token/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jHOLHWbI",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"operation\",\"error\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"error\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"route.standalone.add.token.error\"],null],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"message\"],[8],[1,[24,[\"operation\",\"error\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"ui-block/loading\",null,[[\"message\"],[\"route.standalone.add.token.loading\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-route/standalone/add/token/template.hbs"
    }
  });

  _exports.default = _default;
});