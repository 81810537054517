define("boekdrukken/instance-initializers/boekdrukken-dev", ["exports", "boekdrukken/util/set-global", "boekdrukken/util/is-development"], function (_exports, _setGlobal, _isDevelopment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'boekdrukken:dev',
    after: 'boekdrukken:store',
    initialize: (0, _isDevelopment.inDevelopment)(function (app) {
      // eslint-disable-line no-unused-vars
      window.setGlobal = _setGlobal.default;
      (0, _setGlobal.default)({// index: () => app.lookup('service:router').transitionTo('index'),
      });
    })
  };
  _exports.default = _default;
});