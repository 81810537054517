define("boekdrukken/components/ui-block/product/book/step/content/sidebar/color-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ty2xhKOr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"description\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"key\"],[8],[1,[28,\"t\",[[24,[\"type\",\"description\"]]],null],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"value\"],[8],[1,[22,\"count\"],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[1,[28,\"ui-input/button\",null,[[\"size\",\"type\",\"value\",\"disabled\",\"action\"],[\"tiny\",\"outline\",[28,\"t\",[\"block.product.book.step.content.sidebar.color-type.mark-all\"],null],[24,[\"actionsDisabled\"]],[24,[\"markAll\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/product/book/step/content/sidebar/color-type/template.hbs"
    }
  });

  _exports.default = _default;
});