define("boekdrukken/routes/users/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var uid = this.get('store.user.uid');

      if (!uid) {
        this.transitionTo('index');
        return;
      }

      this.transitionTo('users.user.products', uid);
    }
  });

  _exports.default = _default;
});