define("boekdrukken/components/ui-route/backend/templates/type/new/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-route-backend-templates-type-new'],
    model: null,
    actions: {
      update: function update(key, value) {
        this.model.set(key, value);
      },
      save: function save() {
        this.model.save();
      },
      cancel: function cancel() {
        this.model.cancel();
      }
    }
  });

  _exports.default = _default;
});