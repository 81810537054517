define("boekdrukken/models/users/user/products/components/-paper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    _paper: Ember.computed.readOnly('doc.data.paper'),
    paper: Ember.computed('_paper', 'settings.papers.@each.id', function () {
      var _paper = this._paper,
          papers = this.settings.papers;
      return papers.findBy('id', _paper);
    }).readOnly()
  });

  _exports.default = _default;
});