define("boekdrukken/components/ui-block/products/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8dj2HvzQ",
    "block": "{\"symbols\":[\"product\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"block.products.list.header.title\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"store\",\"user\",\"isAdmin\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"ui-input/button\",null,[[\"class\",\"size\",\"type\",\"color\",\"value\",\"action\"],[\"action\",\"small\",\"outline\",\"warning\",[28,\"t\",[\"block.products.list.actions.custom\"],null],[28,\"action\",[[23,0,[]],[24,[\"custom\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[28,\"ui-input/button\",null,[[\"class\",\"size\",\"value\",\"action\"],[\"action\",\"small\",[28,\"t\",[\"block.products.list.actions.new\"],null],[28,\"action\",[[23,0,[]],[24,[\"new\"]]],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"products\",\"models\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"ui-block/products/list/product\",null,[[\"product\",\"select\",\"delete\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"select\",[23,1,[]]],null],[28,\"action\",[[23,0,[]],\"delete\",[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"placeholder\"],[8],[1,[28,\"t\",[\"block.products.list.placeholder\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/products/list/template.hbs"
    }
  });

  _exports.default = _default;
});