define("boekdrukken/util/assert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assertWithCode = _exports.assert = void 0;

  var assert = function assert(message, code, condition) {
    if (!condition) {
      var err = new Ember.Error(message);
      err.code = code;
      throw err;
    }
  };

  _exports.assert = assert;

  var assertWithCode = function assertWithCode(code) {
    return function (message, condition) {
      return assert(message, code, condition);
    };
  };

  _exports.assertWithCode = assertWithCode;
});