define("boekdrukken/components/ui-block/sketch/inspector/section/move/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':move']
  });

  _exports.default = _default;
});