define("boekdrukken/instance-initializers/zuglet-fastboot", ["exports", "ember-cli-zuglet/-private/util/fastboot"], function (_exports, _fastboot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'zuglet:fastboot',
    initialize: function initialize(app) {
      var _lookupFastBoot = (0, _fastboot.lookupFastBoot)(app),
          fastboot = _lookupFastBoot.fastboot,
          isFastBoot = _lookupFastBoot.isFastBoot;

      if (!fastboot) {
        return;
      }

      var stores = app.lookup('zuglet:stores');

      if (isFastBoot) {
        fastboot.deferRendering(stores.settle());
      }
    }
  };
  _exports.default = _default;
});