define("boekdrukken/components/ui-block/cart/content/legal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-cart-content-legal'],
    actions: {
      update: function update(key, value) {
        this.legal.update(key, value);
      }
    }
  });

  _exports.default = _default;
});