define("boekdrukken/models/templates/template/book-cover", ["exports", "boekdrukken/models/templates/-template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _template.default.extend();

  _exports.default = _default;
});