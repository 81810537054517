define("boekdrukken/components/ui-block/sketch/zoom/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-sketch-zoom'],
    sketch: null,
    stage: Ember.computed.readOnly('sketch.stage'),
    actions: {
      update: function update(value) {
        this.stage.perform('zoom', {
          value: value
        });
      },
      fit: function fit() {
        this.stage.fit();
      }
    }
  });

  _exports.default = _default;
});