define("boekdrukken/components/ui-block/product/book/step/summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Avrl6JdR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ui-block/container\",null,[[\"type\",\"class\"],[\"centered\",\"header\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"block.product.book.step.summary.header\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"ui-block/container\",null,[[\"type\",\"class\"],[\"centered\",\"content\"]],{\"statements\":[[0,\"  \"],[1,[28,\"ui-block/product/step/errors\",null,[[\"product\",\"class\"],[[24,[\"product\"]],\"errors\"]]],false],[0,\"\\n  \"],[1,[28,\"ui-block/product/step/product\",null,[[\"product\",\"class\"],[[24,[\"product\"]],\"product\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"ui-block/container\",null,[[\"type\",\"class\"],[\"centered\",\"actions\"]],{\"statements\":[[4,\"if\",[[24,[\"isBusy\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"isProductBusy\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"info\"],[8],[1,[28,\"t\",[\"block.product.book.step.summary.busy\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\",true],[10,\"class\",\"spinner\"],[8],[0,\"\\n      \"],[1,[28,\"ui-block/icon\",[\"refresh\"],[[\"spin\"],[true]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[28,\"ui-input/button\",null,[[\"color\",\"disabled\",\"value\",\"action\"],[\"success\",[24,[\"isDisabled\"]],[28,\"t\",[\"block.product.book.step.summary.actions.add-to-cart\"],null],[28,\"action\",[[23,0,[]],\"addToCart\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/product/book/step/summary/template.hbs"
    }
  });

  _exports.default = _default;
});