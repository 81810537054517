define("boekdrukken/instance-initializers/boekdrukken-sketches", ["exports", "ember-cli-sketch/initialize", "boekdrukken/sketches", "boekdrukken/models/sketch/strings"], function (_exports, _initialize, _sketches, _strings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'boekdrukken:sketches',
    initialize: (0, _initialize.initialize)({
      factory: _sketches.default,
      strings: _strings.default,
      fonts: {
        google: {
          'Ubuntu Mono': '400,400i,700,700i',
          'Pacifico': true,
          'Montserrat': '100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i:latin,latin-ext',
          'Bitter': true,
          'Amatic SC': true,
          'Chewy': true,
          'Dokdo': true,
          'Fredoka One': true,
          'Raleway': '100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i:latin,latin-ext',
          'Alegreya': '400,400i,500,500i,700,700i,800,800i,900,900i:latin,latin-ext',
          'Archivo': '400,400i,500,500i,600,600i,700,700i:latin,latin-ext',
          'Cairo': '200,300,400,600,700,900:latin,latin-ext',
          'Concert One': true,
          'Crimson Text': '400,400i,600,600i,700,700i:latin,latin-ext',
          'Frank Ruhl Libre': '300,400,500,700,900:latin,latin-ext',
          'IBM Plex Serif': '100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i:latin,latin-ext',
          'Lato': '100,100i,300,300i,400,400i,700,700i,900,900i:latin,latin-ext',
          'Lora': '400,400i,700,700i:latin,latin-ext',
          'Muli': '200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i:latin,latin-ext',
          'Noto Sans': '400,400i,700,700i:latin,latin-ext',
          'Nunito Sans': '200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i:latin,latin-ext',
          'Open Sans': '300,300i,400,400i,600,600i,700,700i,800,800i:latin,latin-ext',
          'Oswald': '200,300,400,500,600,700:latin,latin-ext',
          'PT Sans': '400,400i,700,700i:latin,latin-ext',
          'Playfair Display': '400,400i,500,500i,600,600i,700,700i,800,800i,900,900i:latin,latin-ext',
          'Prompt': '100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i:latin,latin-ext',
          'Roboto': '100,100i,300,300i,400,400i,500,500i,700,700i,900,900i:latin,latin-ext',
          'Source Sans Pro': '200,200i,300,300i,400,400i,600,600i,700,700i,900,900i:latin,latin-ext',
          'Titillium Web': '200,200i,300,300i,400,400i,600,600i,700,700i,900:latin,latin-ext',
          'Varela': true,
          'Vollkorn': '400,400i,600,600i,700,700i,900,900i:latin,latin-ext',
          'Work Sans': '100,200,300,400,500,600,700,800,900:latin,latin-ext'
        }
      }
    })
  };
  _exports.default = _default;
});