define("boekdrukken/components/ui-dialog/apply-product-parameters/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4zszHAN4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[1,[28,\"t\",[\"dialog.apply-product-parameters.header\"],null],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"dialog.apply-product-parameters.content\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"left\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isBusy\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"ui-block/icon\",[\"refresh\"],[[\"spin\"],[true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"right\"],[8],[0,\"\\n    \"],[1,[28,\"ui-input/button\",null,[[\"size\",\"value\",\"action\",\"class\",\"disabled\"],[\"small\",[28,\"t\",[\"dialog.apply-product-parameters.actions.cancel\"],null],[28,\"action\",[[23,0,[]],\"discard\"],null],\"action\",[24,[\"isDisabled\"]]]]],false],[0,\"\\n    \"],[1,[28,\"ui-input/button\",null,[[\"size\",\"color\",\"value\",\"action\",\"class\",\"disabled\"],[\"small\",\"danger\",[28,\"t\",[\"dialog.apply-product-parameters.actions.apply\"],null],[28,\"action\",[[23,0,[]],\"apply\"],null],\"action\",[24,[\"isDisabled\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-dialog/apply-product-parameters/template.hbs"
    }
  });

  _exports.default = _default;
});