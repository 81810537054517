define("boekdrukken/components/ui-block/pdf/grid/component", ["exports", "boekdrukken/components/ui-block/pdf/-util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-pdf-grid', 'select:selectable', 'hasOneColumn:center'],
    pdf: null,
    filter: null,
    dimensions: (0, _util.pdf)('dimensions'),
    pairs: (0, _util.pdf)('pairs'),
    hasOneColumn: Ember.computed.equal('columns', 1),
    pairWidth: Ember.computed('columns', 'container.width', function () {
      var columns = this.columns,
          width = this.container.width;
      var padding = 20;
      var inner = (columns - 1) * 10;
      return Math.floor((width - padding * 2 - inner) / columns);
    }).readOnly(),
    itemWidth: Ember.computed('pairWidth', function () {
      var pairWidth = this.pairWidth;
      var padding = 12;
      return Math.floor((pairWidth - padding) / 2);
    }).readOnly(),
    maxItemHeight: Ember.computed('container.height', function () {
      var height = this.container.height;
      return height - 50;
    }).readOnly(),
    itemSize: (0, _util.size)('dimensions', 'itemWidth', 'maxItemHeight'),
    actions: {
      selectPair: function selectPair(pair) {
        this.select && this.select(pair);
      }
    }
  });

  _exports.default = _default;
});