define("boekdrukken/components/ui-block/product/book/step/cover/templates/standalone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bQ9Fc6+A",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ui-block/container\",null,[[\"type\",\"class\"],[\"centered\",\"content\"]],{\"statements\":[[4,\"ui-block/card\",null,[[\"type\",\"class\"],[\"minimal\",\"card\"]],{\"statements\":[[4,\"if\",[[24,[\"component\",\"defaultTemplateIdentifier\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"block.product.book.step.cover.templates.standalone.creating\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"block.product.book.step.cover.templates.standalone.title\"],null],false],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[1,[28,\"t\",[\"block.product.book.step.cover.templates.standalone.description\"],null],false],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n        \"],[1,[28,\"ui-input/button\",null,[[\"value\",\"color\",\"action\"],[[28,\"t\",[\"block.product.book.step.cover.templates.standalone.actions.choose\"],null],\"success\",[28,\"action\",[[23,0,[]],\"show\"],null]]]],false],[0,\"\\n        \"],[1,[28,\"ui-input/button\",null,[[\"value\",\"action\"],[[28,\"t\",[\"block.product.book.step.cover.templates.standalone.actions.cancel\"],null],[28,\"action\",[[23,0,[]],\"back\"],null]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/product/book/step/cover/templates/standalone/template.hbs"
    }
  });

  _exports.default = _default;
});