define("boekdrukken/components/ui-block/product/step/product/price/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bODPPJkl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"prefix\"],[8],[1,[28,\"t\",[\"block.product.step.product.price.total\"],null],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"value\"],[8],[1,[28,\"currency\",[[24,[\"pricing\",\"total\"]]],null],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/product/step/product/price/template.hbs"
    }
  });

  _exports.default = _default;
});