define("boekdrukken/components/ui-block/sketch/tree/model/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nFmZAZqB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"details\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"description\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"select\"],[[\"bubbles\"],[false]]],[8],[1,[28,\"t\",[[24,[\"model\",\"description\"]]],null],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"accessories\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"warning\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"ui-block/sketch/tree/model/accessory\",null,[[\"class\",\"icon\",\"faded\"],[\"warning\",\"exclamation-circle\",false]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[28,\"ui-block/sketch/tree/model/accessory\",null,[[\"icon\",\"faded\",\"model\",\"key\"],[[28,\"if\",[[24,[\"model\",\"visible\"]],\"eye\",\"eye-slash\"],null],[24,[\"model\",\"visible\"]],[24,[\"model\"]],\"visible\"]]],false],[0,\"\\n    \"],[1,[28,\"ui-block/sketch/tree/model/accessory\",null,[[\"icon\",\"faded\",\"model\",\"key\"],[[28,\"if\",[[24,[\"model\",\"selectable\"]],\"unlock-alt\",\"lock\"],null],[24,[\"model\",\"selectable\"]],[24,[\"model\"]],\"selectable\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"nodes\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"ui-block/sketch/tree/models\",null,[[\"model\",\"level\"],[[24,[\"model\"]],[24,[\"nextLevel\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/tree/model/template.hbs"
    }
  });

  _exports.default = _default;
});