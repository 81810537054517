define("boekdrukken/models/stripe/payment/ideal", ["exports", "boekdrukken/models/stripe/payment/-type"], function (_exports, _type) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _type.default.extend({
    elementType: 'idealBank',
    _confirm: function _confirm(_ref) {
      var stripe = _ref.stripe,
          intent = _ref.intent,
          element = _ref.element,
          url = _ref.url;
      return stripe.confirmIdealPayment(intent.client_secret, {
        payment_method: {
          ideal: element
        },
        return_url: url
      });
    }
  });

  _exports.default = _default;
});