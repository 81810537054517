define("boekdrukken/components/ui-block/product/book/step/content/toolbar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-product-book-step-content-toolbar'],
    min: 1,
    max: 5,
    columns: null,
    value: Ember.computed('columns', function () {
      var columns = this.columns,
          max = this.max,
          min = this.min;
      return max - columns + min;
    }).readOnly(),
    actions: {
      updateValue: function updateValue(value) {
        var min = this.min,
            max = this.max;
        this.updateColumns(max - value + min);
      },
      reset: function reset() {
        this.updateColumns(this.defaultColumns);
      },
      diff: function diff(_diff) {
        var min = this.min,
            max = this.max,
            columns = this.columns;
        var value = columns + _diff;

        if (value < min) {
          return;
        }

        if (value > max) {
          return;
        }

        this.updateColumns(value);
      }
    }
  });

  _exports.default = _default;
});