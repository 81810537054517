define("boekdrukken/components/ui-block/sketch/inspector/section/font-style/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RsA4qJOP",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ui-block/sketch/inspector/section/font-style/column\",null,[[\"label\",\"key\",\"value\",\"choices\",\"update\"],[\"block.sketch.inspector.section.font-style.weight.label\",\"fontWeight\",[24,[\"model\",\"fontWeight\"]],[24,[\"weights\"]],[24,[\"update\"]]]]],false],[0,\"\\n\"],[1,[28,\"ui-block/sketch/inspector/section/font-style/column\",null,[[\"label\",\"key\",\"value\",\"choices\",\"update\"],[\"block.sketch.inspector.section.font-style.style.label\",\"fontStyle\",[24,[\"model\",\"fontStyle\"]],[24,[\"styles\"]],[24,[\"update\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/inspector/section/font-style/template.hbs"
    }
  });

  _exports.default = _default;
});