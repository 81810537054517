define("boekdrukken/routes/backend/orders/index", ["exports", "luxon"], function (_exports, _luxon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var now = _luxon.DateTime.local();

      var string = function string(max) {
        var year = now.year,
            month = now.month;
        var day = max ? now.daysInMonth : 1;
        return "".concat(year, "-").concat(month, "-").concat(day);
      };

      var from = string(false);
      var to = string(true);
      this.transitionTo('backend.orders.range', from, to);
    }
  });

  _exports.default = _default;
});