define("boekdrukken/components/ui-route/users/user/products/product/index/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-route-users-user-products-product-index'],
    product: Ember.computed.readOnly('model.product'),
    code: Ember.computed.readOnly('product.doc.data.error.code'),
    actions: {
      delete: function _delete() {
        this.router.transitionTo('users.user.products.product.delete');
      }
    }
  });

  _exports.default = _default;
});