define("boekdrukken/services/analytics", ["exports", "analytics/services/analytics", "ember-cli-zuglet/lifecycle"], function (_exports, _analytics, _lifecycle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _analytics.default.extend({
    events: (0, _lifecycle.model)().named('analytics/events').mapping(function (analytics) {
      return {
        analytics: analytics
      };
    })
  });

  _exports.default = _default;
});