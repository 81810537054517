define("boekdrukken/components/ui-route/users/user/products/product/delete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s00kZqOw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ui-block/loading\",null,null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"route.users.user.products.product.delete.title\"],[[\"name\",\"htmlSafe\"],[[24,[\"product\",\"name\"]],true]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-route/users/user/products/product/delete/template.hbs"
    }
  });

  _exports.default = _default;
});