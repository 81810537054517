define("boekdrukken/components/ui-application/header/cart/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-application-header-cart', 'user::hidden'],
    user: Ember.computed.readOnly('store.user'),
    actions: {
      cart: function cart() {
        this.router.transitionTo('users.user.cart', this.user.uid);
      }
    }
  });

  _exports.default = _default;
});