define("boekdrukken/components/ui-route/stylebook/card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fn8krrU/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"stylebook.card\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"section\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"stylebook.default\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content col\"],[8],[0,\"\\n\"],[4,\"ui-block/card\",null,[[\"class\"],[\"card\"]],{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"stylebook.cats-craddle\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"section\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"stylebook.padded\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content col\"],[8],[0,\"\\n\"],[4,\"ui-block/card\",null,[[\"type\",\"class\"],[\"container\",\"card\"]],{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"stylebook.cats-craddle\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"section\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"stylebook.minimal\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content col\"],[8],[0,\"\\n\"],[4,\"ui-block/card\",null,[[\"type\",\"class\"],[\"minimal\",\"card\"]],{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"stylebook.cats-craddle\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-route/stylebook/card/template.hbs"
    }
  });

  _exports.default = _default;
});