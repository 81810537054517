define("boekdrukken/components/ui-block/stripe/selection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZxtjOOJd",
    "block": "{\"symbols\":[\"type\"],\"statements\":[[4,\"each\",[[24,[\"payment\",\"types\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"ui-block/stripe/selection/type\",null,[[\"payment\",\"type\",\"select\"],[[24,[\"payment\"]],[23,1,[]],[28,\"action\",[[23,0,[]],\"select\",[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/stripe/selection/template.hbs"
    }
  });

  _exports.default = _default;
});