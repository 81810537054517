define("boekdrukken/templates/backend/templates/type/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0zXBsY2E",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ui-route/backend/templates/type/new\",null,[[\"model\"],[[24,[\"model\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/templates/backend/templates/type/new.hbs"
    }
  });

  _exports.default = _default;
});