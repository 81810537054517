define("boekdrukken/helpers/tt", ["exports", "design/helpers/tt"], function (_exports, _tt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _tt.default;
    }
  });
});