define("boekdrukken/components/ui-block/sketch/inspector/selection/model/image/component", ["exports", "boekdrukken/components/ui-block/sketch/inspector/selection/model/-component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    actions: {
      chooseImage: function chooseImage() {
        this.model.chooseImage();
      }
    }
  });

  _exports.default = _default;
});