define("boekdrukken/components/ui-route/backend/templates/type/template/preview/edit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-route-backend-templates-type-template-preview-edit'],
    opts: Ember.computed.readOnly('model.opts'),
    isBusy: false,
    actions: {
      update: function update(key, value) {
        var transformed = parseInt(value);

        if (isNaN(transformed)) {
          return;
        }

        this.set("model.opts.".concat(key), transformed);
      },
      show: function show() {
        this.templateTransitionTo('preview.design');
      },
      cancel: function cancel() {
        this.templateTransitionTo('index');
      }
    },
    templateTransitionTo: function templateTransitionTo(name) {
      this.set('isBusy', true);
      this.router.transitionTo("backend.templates.type.template.".concat(name));
    }
  });

  _exports.default = _default;
});