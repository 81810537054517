define("boekdrukken/components/ui-block/sketch/inspector/section/text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qd5HLjyV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"],[1,[28,\"ui-input/text\",null,[[\"size\",\"value\",\"update\"],[\"small\",[28,\"get\",[[24,[\"model\"]],[24,[\"key\"]]],null],[28,\"action\",[[23,0,[]],[24,[\"update\"]],[24,[\"key\"]]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/inspector/section/text/template.hbs"
    }
  });

  _exports.default = _default;
});