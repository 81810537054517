define("boekdrukken/models/sketch/base/guidelines/area", ["exports", "ember-cli-sketch/-private/node/guidelines/basic", "ember-cli-sketch/util/math"], function (_exports, _basic, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    crop: Ember.computed.readOnly('node.model.cropMarksDefinition'),
    points: (0, _basic.points)('crop', 'zoom', function (_ref) {
      var x = _ref.x,
          y = _ref.y,
          width = _ref.width,
          height = _ref.height;
      var crop = this.crop,
          zoom = this.zoom;
      var bleeding;
      var spine;
      var cw;
      var sw;
      var hw;

      if (crop) {
        bleeding = crop.bleeding * zoom;
        spine = crop.spine * zoom;
        cw = (0, _math.round)(width / 2, 0);
        sw = (0, _math.round)(spine / 2, 0);
        hw = (0, _math.round)((cw - sw - bleeding) / 2, 0);
      }

      var optional = function optional(fn) {
        if (!crop) {
          return;
        }

        return fn();
      };

      return {
        vertical: Ember.A([x, optional(function () {
          return x + bleeding - 1;
        }), optional(function () {
          return x + bleeding + hw;
        }), optional(function () {
          return x + cw - sw;
        }), x + width / 2, optional(function () {
          return x + cw + sw;
        }), optional(function () {
          return x + cw + sw + hw;
        }), optional(function () {
          return x + width - bleeding;
        }), x + width - 1]).compact(),
        horizontal: Ember.A([y, optional(function () {
          return y + bleeding - 1;
        }), y + height / 2, optional(function () {
          return y + height - bleeding;
        }), y + height - 1]).compact()
      };
    })
  });

  _exports.default = _default;
});