define("boekdrukken/components/ui-block/product/book/step/source/component", ["exports", "boekdrukken/util/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-product-book-step-source'],
    externalChecklistURL: (0, _computed.config)(function (_ref) {
      var checklist = _ref.external.checklist;
      return checklist;
    }),
    step: null,
    product: Ember.computed.readOnly('step.product')
  });

  _exports.default = _default;
});