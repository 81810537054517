define("boekdrukken/components/ui-block/product/book/step/cover/upload/upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UiQepF9d",
    "block": "{\"symbols\":[\"button\"],\"statements\":[[4,\"if\",[[24,[\"isBusy\"]]],null,{\"statements\":[[4,\"ui-input/button\",null,[[\"size\",\"class\",\"color\"],[\"small\",\"action\",\"success\"]],{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"icon\"]],\"expected `button.icon` to be a contextual component but found a string. Did you mean `(component button.icon)`? ('boekdrukken/components/ui-block/product/book/step/cover/upload/upload/template.hbs' @ L3:C6) \"],null]],[[\"name\",\"spin\"],[\"refresh\",true]]],false],[0,\"\\n    \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"label\"]],\"expected `button.label` to be a contextual component but found a string. Did you mean `(component button.label)`? ('boekdrukken/components/ui-block/product/book/step/cover/upload/upload/template.hbs' @ L4:C6) \"],null]],[[\"value\"],[[28,\"t\",[\"block.product.book.step.cover.upload.actions.upload.in-progress\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"ui-input/file\",null,[[\"class\",\"size\",\"type\",\"title\",\"disabled\",\"update\"],[\"action\",\"small\",[28,\"if\",[[24,[\"faded\"]],\"outline\",\"regular\"],null],[28,\"if\",[[24,[\"component\",\"upload\",\"exists\"]],[28,\"t\",[\"block.product.book.step.cover.upload.actions.upload.replace\"],null],[28,\"t\",[\"block.product.book.step.cover.upload.actions.upload.default\"],null]],null],[24,[\"isBusy\"]],[28,\"action\",[[23,0,[]],\"upload\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/product/book/step/cover/upload/upload/template.hbs"
    }
  });

  _exports.default = _default;
});