define("boekdrukken/components/ui-block/product/book/step/content/sidebar/color-type/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':block'],
    count: Ember.computed('pdf.pageColorTypes', 'type', function () {
      var pageColorTypes = this.pdf.pageColorTypes,
          id = this.type.id;
      return pageColorTypes[id].count;
    }).readOnly(),
    actionsDisabled: Ember.computed('count', 'pdf.pageCount', function () {
      var pageCount = this.pdf.pageCount,
          count = this.count;
      return count === pageCount;
    }).readOnly()
  });

  _exports.default = _default;
});