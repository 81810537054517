define("boekdrukken/components/ui-block/pdf/grid/pair/page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H0kofRV5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"class\",\"content\"],[12,\"style\",[22,\"contentStyle\"]],[3,\"action\",[[23,0,[]],\"select\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"page\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"thumbnail\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"ui-block/background-image\",null,[[\"class\",\"url\"],[\"image\",[24,[\"thumbnail\",\"url\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"ui-block/icon\",[\"clock-o\"],[[\"class\"],[\"placeholder\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[4,\"if\",[[24,[\"showCropmarks\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"ui-block/crop-marks\",null,[[\"inset\",\"class\"],[[24,[\"cropmarksInset\"]],\"crop-marks\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"details\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"page\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"ui-block/pdf/page/color-types/segmented-button\",null,[[\"size\",\"page\",\"type\",\"class\",\"select\"],[\"tiniest\",[24,[\"page\"]],\"fill\",\"color\",[28,\"action\",[[23,0,[]],\"selectColorType\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"number\"],[8],[1,[24,[\"page\",\"number\"]],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/pdf/grid/pair/page/template.hbs"
    }
  });

  _exports.default = _default;
});