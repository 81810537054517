define("boekdrukken/components/ui-route/users/user/products/product/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-route-users-user-products-product'],
    flow: Ember.computed.readOnly('model.product.flow')
  });

  _exports.default = _default;
});