define("boekdrukken/components/ui-block/product/book/step/cover/templates/standalone/component", ["exports", "boekdrukken/util/alive"], function (_exports, _alive) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-product-book-step-cover-templates-standalone'],
    defaultTemplateIdentifier: Ember.computed.readOnly('component.defaultTemplateIdentifier'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.defaultTemplateIdentifier) {
        this.select();
      } else {
        this.show();
      }
    },
    actions: {
      show: function show() {
        this.show();
      },
      back: function back() {
        this.cancel();
      }
    },
    show: (0, _alive.default)(function () {
      this.component.chooseTemplate();
    }),
    select: (0, _alive.default)(function () {
      this.component.selectDefaultTemplate();
    }),
    cancel: function cancel() {
      this.component.updateSubtype(null);
    }
  });

  _exports.default = _default;
});