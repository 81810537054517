define("boekdrukken/routes/account/-error-message-mixin", ["exports", "boekdrukken/util/auth"], function (_exports, _auth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    errorMessage: (0, _auth.errorMessage)('error')
  });

  _exports.default = _default;
});