define("boekdrukken/util/safe-set-properties", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(owner, props) {
    if (owner.isDestroying) {
      return;
    }

    owner.setProperties(props);
  };

  _exports.default = _default;
});