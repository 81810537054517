define("boekdrukken/components/ui-dialog/color-pages/component", ["exports", "design/components/ui-dialog/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNameBindings: [':ui-dialog-color-pages'],
    actions: {
      confirm: function confirm() {
        this.dialog.resolve({
          status: 'confirmed'
        });
      },
      reject: function reject() {
        this.dialog.resolve({
          status: 'rejected'
        });
      }
    }
  });

  _exports.default = _default;
});