define("boekdrukken/components/ui-block/sketch/inspector/section/-reset-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      reset: function reset() {
        this.reset();
      }
    },
    reset: function reset() {
      var initial = this.initial,
          key = this.key;

      if (initial === undefined) {
        var attributes = this.model.node.attributes;
        initial = attributes.attribute(key).initial;
      }

      if (initial === undefined) {
        return;
      }

      this.update(key, initial);
    }
  });

  _exports.default = _default;
});