define("boekdrukken/components/ui-block/sketch/inspector/section/color/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tzreZoNM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"class\",\"label\"],[3,\"action\",[[23,0,[]],\"reset\"]],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"],[1,[28,\"ui-block/color-picker\",null,[[\"value\",\"update\"],[[28,\"get\",[[24,[\"model\"]],[24,[\"key\"]]],null],[28,\"action\",[[23,0,[]],[24,[\"update\"]],[24,[\"key\"]]],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/inspector/section/color/template.hbs"
    }
  });

  _exports.default = _default;
});