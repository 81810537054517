define("boekdrukken/models/users/user/products/components/-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    settings: Ember.computed('type', 'product.settings.components.@each.type', function () {
      var type = this.type,
          product = this.product;
      return product.settings.components.findBy('type', type);
    }).readOnly()
  });

  _exports.default = _default;
});