define("boekdrukken/components/ui-block/sketch/inspector/selection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3cWruOoV",
    "block": "{\"symbols\":[\"model\"],\"statements\":[[4,\"each\",[[24,[\"sketch\",\"stage\",\"selection\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"ui-block/sketch/inspector/selection/model\",null,[[\"sketch\",\"model\"],[[24,[\"sketch\"]],[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"  \"],[1,[28,\"ui-block/sketch/inspector/selection/model\",null,[[\"sketch\",\"model\"],[[24,[\"sketch\"]],[24,[\"sketch\",\"stage\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/inspector/selection/template.hbs"
    }
  });

  _exports.default = _default;
});