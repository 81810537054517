define("boekdrukken/components/ui-block/pdf/grid/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kmv52Mg4",
    "block": "{\"symbols\":[\"pair\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"pairs\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"ui-block/pdf/grid/pair\",null,[[\"pair\",\"width\",\"itemSize\",\"filter\",\"select\"],[[23,1,[]],[24,[\"pairWidth\"]],[24,[\"itemSize\"]],[24,[\"filter\"]],[28,\"if\",[[24,[\"select\"]],[28,\"action\",[[23,0,[]],\"selectPair\",[23,1,[]]],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/pdf/grid/template.hbs"
    }
  });

  _exports.default = _default;
});