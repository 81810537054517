define("boekdrukken/models/sketch/base/stage/node/area", ["exports", "boekdrukken/models/sketch/base/stage/node"], function (_exports, _node) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var id = function id(key) {
    return "model.sketch.base.stage.node.area.identifier.".concat(key);
  };

  var identifiers = {
    background: id('background'),
    back: id('back'),
    spine: id('spine'),
    front: id('front'),
    content: id('content')
  };
  var mapping = {
    book: function book(_ref) {
      var bleeding = _ref.bleeding,
          spine = _ref.spine,
          isRendering = _ref.isRendering;
      return {
        type: 'book-crop-marks',
        spine: spine,
        bleeding: bleeding,
        overlay: !isRendering
      };
    }
  };

  var cropMarksDefinition = function cropMarksDefinition(type, node) {
    var fn = mapping[type];
    return fn && fn(node);
  };

  var immutable = Object.freeze({
    immutable: true
  });

  var _default = _node.default.extend({
    name: 'model.sketch.base.stage.node.area.name',
    isArea: true,
    description: Ember.computed('identifier', 'name', function () {
      var identifier = this.identifier;
      return identifiers[identifier] || this.name;
    }).readOnly(),
    node: (0, _node.node)({
      type: 'sized',
      container: true
    }),
    guidelines: (0, _node.guidelines)('area'),
    identifier: (0, _node.attr)('identifier', {
      type: 'string'
    }),
    x: (0, _node.x)(immutable),
    y: (0, _node.y)(immutable),
    width: (0, _node.width)(immutable),
    height: (0, _node.height)(immutable),
    aspect: (0, _node.aspect)(),
    fill: (0, _node.fill)(),
    cropMarks: (0, _node.attr)('cropMarks', {
      type: 'string'
    }),
    spine: (0, _node.attr)('spine', {
      type: 'number',
      min: 0,
      initial: 0
    }),
    bleeding: (0, _node.attr)('bleeding', {
      type: 'number',
      min: 0,
      initial: 0
    }),
    isRendering: Ember.computed.readOnly('stage.isRendering'),
    cropMarksDefinition: Ember.computed('cropMarks', 'spine', 'bleeding', 'isRendering', function () {
      var cropMarks = this.cropMarks;
      return cropMarksDefinition(cropMarks, this);
    }).readOnly(),
    accessories: Ember.computed('cropMarksDefinition', function () {
      var accessory = this.cropMarksDefinition;

      if (accessory) {
        return [accessory];
      }
    }).readOnly()
  });

  _exports.default = _default;
});