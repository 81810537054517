define("boekdrukken/templates/standalone/add/token", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6dqKOJzI",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ui-route/standalone/add/token\",null,[[\"operation\"],[[24,[\"model\",\"operation\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/templates/standalone/add/token.hbs"
    }
  });

  _exports.default = _default;
});