define("boekdrukken/helpers/blank-image", ["exports", "boekdrukken/util/blank-image"], function (_exports, _blankImage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.blankImage = blankImage;
  _exports.default = void 0;

  function blankImage() {
    return _blankImage.default;
  }

  var _default = Ember.Helper.helper(blankImage);

  _exports.default = _default;
});