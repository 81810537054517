define("boekdrukken/components/ui-block/sketch/tree/model/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':model', 'model.node.type', 'levelClassName', 'selected:selected', 'warning:warning'],
    selected: Ember.computed.readOnly('model.selected'),
    warning: Ember.computed('model.{isArea,parent.isArea}', function () {
      var model = this.model;

      if (model.isArea) {
        return false;
      }

      var parent = model.parent;

      if (!parent) {
        return false;
      }

      if (parent.isArea) {
        return false;
      }

      return true;
    }).readOnly(),
    levelClassName: Ember.computed('level', function () {
      var level = this.level;
      return "level-".concat(level);
    }).readOnly(),
    nextLevel: Ember.computed('level', function () {
      var level = this.level;
      return level + 1;
    }).readOnly(),
    actions: {
      select: function select() {
        this.model.select();
      },
      toggleVisible: function toggleVisible() {
        var model = this.model;
        model.update({
          visible: !model.visible
        });
      },
      toggleSelectable: function toggleSelectable() {
        var model = this.model;
        model.update({
          selectable: !model.selectable
        });
      }
    }
  });

  _exports.default = _default;
});