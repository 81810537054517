define("boekdrukken/components/ui-block/product/book/step/cover/templates/content/selected/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F3wR+4YA",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"selected\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"thumbnail\"],[8],[0,\"\\n    \"],[1,[28,\"ui-block/background-image\",null,[[\"url\",\"class\"],[[24,[\"selected\",\"thumbnail\",\"url\"]],\"image\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"placeholder\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"value\"],[8],[1,[28,\"t\",[\"block.product.book.step.cover.templates.content.selected.placeholder\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/product/book/step/cover/templates/content/selected/template.hbs"
    }
  });

  _exports.default = _default;
});