define("boekdrukken/util/units", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ptToIn = _exports.inToMm = void 0;

  var ptToIn = function ptToIn(value) {
    return value / 72;
  };

  _exports.ptToIn = ptToIn;

  var inToMm = function inToMm(value) {
    return value * 25.4;
  };

  _exports.inToMm = inToMm;
});