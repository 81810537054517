define("boekdrukken/models/alerts/-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    dismissable: false,
    timeout: 0,
    icon: 'check-circle-o',
    description: null,
    // description: computed('filename', function() {
    //   let { filename } = this;
    //   return [
    //     { type: 'normal', value: 'Creating book from ' },
    //     { type: 'strong', value: filename }
    //   ];
    // }).readOnly()
    dismiss: function dismiss() {
      this._cancelTimeout();

      this.alerts._dismissed(this);
    },
    willPresent: function willPresent() {
      var _this = this;

      var timeout = this.timeout;

      if (!timeout) {
        return;
      }

      this._timeout = Ember.run.later(function () {
        return _this.dismiss();
      }, timeout);
    },
    _cancelTimeout: function _cancelTimeout() {
      Ember.run.cancel(this._timeout);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this._cancelTimeout();
    }
  });

  _exports.default = _default;
});