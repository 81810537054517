define("boekdrukken/components/ui-route/backend/templates/type/template/preview/design/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "69q9X5Gy",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ui-block/sketch/stage\",null,[[\"stage\",\"class\",\"ready\"],[[24,[\"stage\"]],\"stage\",[28,\"action\",[[23,0,[]],\"ready\"],null]]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"overlay\"],[8],[0,\"\\n  \"],[1,[28,\"ui-route/backend/templates/type/template/preview/design/-render\",null,[[\"template\",\"label\",\"open\",\"type\"],[[24,[\"template\"]],\"Render Image\",\"Open Image\",\"thumbnail\"]]],false],[0,\"\\n  \"],[1,[28,\"ui-route/backend/templates/type/template/preview/design/-render\",null,[[\"template\",\"label\",\"open\",\"type\"],[[24,[\"template\"]],\"Render PDF\",\"Open PDF\",\"pdf\"]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-route/backend/templates/type/template/preview/design/template.hbs"
    }
  });

  _exports.default = _default;
});