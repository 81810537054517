define("boekdrukken/components/ui-block/pdf/grid/pair/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hjCBSErR",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ui-block/pdf/grid/pair/page\",null,[[\"page\",\"size\",\"filter\",\"class\",\"select\"],[[24,[\"left\"]],[24,[\"itemSize\"]],[24,[\"filter\"]],\"left\",[28,\"action\",[[23,0,[]],\"select\"],null]]]],false],[0,\"\\n\"],[1,[28,\"ui-block/pdf/grid/pair/page\",null,[[\"page\",\"size\",\"filter\",\"class\",\"select\"],[[24,[\"right\"]],[24,[\"itemSize\"]],[24,[\"filter\"]],\"right\",[28,\"action\",[[23,0,[]],\"select\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/pdf/grid/pair/template.hbs"
    }
  });

  _exports.default = _default;
});