define("boekdrukken/components/ui-block/pdf/grid/pair/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':pair', 'select:has-action'],
    left: Ember.computed.readOnly('pair.firstObject'),
    right: Ember.computed.readOnly('pair.lastObject'),
    actions: {
      select: function select() {
        this.select && this.select();
      }
    }
  });

  _exports.default = _default;
});