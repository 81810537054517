define("boekdrukken/components/ui-application/header/auth/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iPbhmiFY",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"user\"]]],null,{\"statements\":[[4,\"ui-block/pill\",null,[[\"class\",\"action\"],[\"user\",[28,\"action\",[[23,0,[]],\"profile\"],null]]],{\"statements\":[[4,\"if\",[[24,[\"isAnonymous\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"anonymous\"],[8],[1,[28,\"t\",[\"application.header.auth.anonymous\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"name\"],[8],[1,[24,[\"email\",\"name\"]],false],[9],[7,\"div\",true],[10,\"class\",\"domain\"],[8],[1,[28,\"t\",[\"application.header.auth.domain\"],[[\"domain\"],[[24,[\"email\",\"domain\"]]]]],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"ui-block/pill\",null,[[\"value\",\"action\"],[[28,\"t\",[\"application.header.auth.sign-in\"],null],[28,\"action\",[[23,0,[]],\"signIn\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-application/header/auth/template.hbs"
    }
  });

  _exports.default = _default;
});