define("boekdrukken/components/ui-block/sketch/inspector/section/font/component", ["exports", "ember-cli-sketch/sketches"], function (_exports, _sketches) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':font'],
    fonts: Ember.computed(function () {
      return (0, _sketches.sketches)(this).fonts.families.map(function (value) {
        return {
          value: value
        };
      }).sortBy('value');
    }).readOnly(),
    selected: Ember.computed('model.fontFamily', 'fonts.@each.value', function () {
      var value = this.model.fontFamily;
      return this.fonts.findBy('value', value);
    }).readOnly(),
    actions: {
      updateFontFamily: function updateFontFamily(_ref) {
        var value = _ref.value;
        this.update('fontFamily', value);
      },
      updateFontSize: function updateFontSize(action, prop, value) {
        value = parseInt(value);

        if (isNaN(value)) {
          return;
        }

        action(prop, value);
      },
      updateLineHeight: function updateLineHeight(action, prop, value) {
        value = parseFloat(value);

        if (isNaN(value)) {
          return;
        }

        action(prop, value);
      }
    }
  });

  _exports.default = _default;
});