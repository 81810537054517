define("boekdrukken/services/intl", ["exports", "ember-intl/services/intl", "boekdrukken/util/computed", "design/util/computed"], function (_exports, _intl, _computed, _computed2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var localization = function localization(key, fallback) {
    return (0, _computed.config)(function (config) {
      var value = Ember.get(config, "localization.".concat(key));

      if (value === undefined) {
        value = fallback;
      }

      return value;
    });
  };

  var _default = _intl.default.extend({
    isDevelopmentBuild: (0, _computed.isDevelopmentBuild)(),
    isDevelopmentEnvironment: (0, _computed.isDevelopmentEnvironment)(),
    isMarkersEnabled: localization('markers', false),
    configuredLocales: localization('locales', []),
    fallbackLocale: Ember.computed('configuredLocales', 'isDevelopmentEnvironment', function () {
      if (this.isDevelopmentEnvironment) {
        return 'en-us';
      }

      var configuredLocales = this.configuredLocales;
      return configuredLocales[0];
    }).readOnly(),
    decorate: Ember.computed.and('isDevelopmentBuild', 'isMarkersEnabled'),
    isShowKeysEnabled: false,
    history: (0, _computed2.array)(),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.isDevelopmentBuild) {
        window.intl = this.history;
      }
    },
    log: function log(_ref) {
      var key = _ref.key,
          opts = _ref.opts,
          string = _ref.string;

      if (!this.isDevelopmentBuild) {
        return;
      }

      var hash = {
        key: key,
        string: string
      };

      if (opts && Object.keys(opts).length > 0) {
        hash.opts = opts;
      }

      this.history.insertAt(0, hash);
    },
    t: function t(key, opts) {
      if (this.isShowKeysEnabled) {
        return key;
      }

      var _opts = Object.assign({
        nbsp: "\xA0"
      }, opts);

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      var string = this._super.apply(this, [key, _opts].concat(args));

      this.log({
        key: key,
        opts: opts,
        string: string
      });
      return string;
    },
    formatMessage: function formatMessage() {
      var result = this._super.apply(this, arguments);

      if (this.decorate) {
        var decorated = "\u22EE".concat(result, "\u22EE");

        if (Ember.String.isHTMLSafe(result)) {
          return Ember.String.htmlSafe(decorated);
        }

        return decorated;
      }

      return result;
    },
    setShowKeys: function setShowKeys() {
      this.set('isShowKeysEnabled', true);
      this.setLocale('en');
    },
    setLocaleWithFallbackChain: function setLocaleWithFallbackChain(locale) {
      var configuredLocales = this.configuredLocales,
          fallbackLocale = this.fallbackLocale;

      if (locale === 'en') {
        locale = 'en-us';
      }

      if (configuredLocales) {
        if (!configuredLocales.includes(locale)) {
          locale = null;
        }
      } else {
        locale = null;
      }

      var chain = Ember.A(Ember.A([locale, fallbackLocale, 'en']).compact()).uniq();
      this.set('isShowKeysEnabled', false);
      this.setLocale(chain);
    }
  });

  _exports.default = _default;
});