define("boekdrukken/router", ["exports", "boekdrukken/config/environment", "analytics/mixins/router"], function (_exports, _environment, _router) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend(_router.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('onboarding', function () {
      this.route('process');
    });
    this.route('products');
    this.route('account', function () {
      this.route('new');
      this.route('restore');
      this.route('link');
      this.route('reset');
      this.route('sign-out');
    });
    this.route('definitions', function () {
      this.route('definition', {
        path: ':definition_id'
      }, function () {});
    });
    this.route('users', function () {
      this.route('user', {
        path: ':user_id'
      }, function () {
        this.route('products', function () {
          this.route('product', {
            path: ':product_id'
          }, function () {
            this.route('delete');
            this.route('step', {
              path: ':step_id'
            }, function () {});
          });
        });
        this.route('cart', function () {});
        this.route('orders', function () {
          this.route('inbox', {
            path: '/inbox/:order_id'
          });
          this.route('order', {
            path: ':order_id'
          }, function () {});
        });
      });
    });
    this.route('backend', function () {
      this.route('dev');
      this.route('templates', function () {
        this.route('type', {
          path: ':type_id'
        }, function () {
          this.route('new');
          this.route('template', {
            path: ':template_id'
          }, function () {
            this.route('edit');
            this.route('design');
            this.route('preview', function () {
              this.route('edit');
              this.route('design');
            });
          });
        });
      });
      this.route('localization', function () {});
      this.route('designs', function () {
        this.route('design', {
          path: ':operation_id/:modifier'
        }, function () {});
      });
      this.route('subscriptions');
      this.route('exports');
      this.route('xml');
      this.route('orders', function () {
        this.route('range', {
          path: ':start/:end'
        }, function () {});
      });
    });
    this.route('internal', {
      path: '-internal'
    }, function () {
      this.route('render', {
        path: 'render/:operation_id'
      }, function () {
        this.route('image');
        this.route('pdf');
      });
    });
    this.route('standalone', function () {
      this.route('add', function () {
        this.route('token', {
          path: '/:token'
        }, function () {});
      });
      this.route('calculators', function () {
        this.route('book', function () {});
      });
      this.route('downloads', function () {
        this.route('book', function () {});
      });
    });
    this.route('stylebook', function () {
      this.route('wip');
      this.route('typography');
      this.route('button');
      this.route('input');
      this.route('container');
      this.route('shape');
      this.route('card');
      this.route('pill');
      this.route('textarea');
      this.route('segmented-button');
      this.route('slider');
      this.route('dropdown');
      this.route('color-picker');
      this.route('checkbox');
      this.route('progress-bar');
      this.route('icons');
      this.route('validation');
      this.route('flow', function () {
        this.route('upload');
        this.route('params');
        this.route('content');
        this.route('cover');
        this.route('summary');
      });
    });
    this.route('maintenance', {
      path: '/maintenance'
    });
    this.route('missing', {
      path: '/*path'
    });
  });
  var _default = Router;
  _exports.default = _default;
});