define("boekdrukken/components/ui-block/product/step/notifications/component", ["exports", "boekdrukken/util/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-product-step-notifications', 'description::hidden'],
    product: null,
    dimensions: Ember.computed.readOnly('product.details.dimensions'),
    description: Ember.computed('dimensions', function () {
      var dimensions = this.dimensions;

      if (!dimensions) {
        return;
      }

      var type = dimensions.type,
          mm = dimensions.mm;

      if (type !== 'trimbox') {
        return;
      }

      if (!mm) {
        return;
      }

      var width = mm.width,
          height = mm.height;
      return {
        label: "block.product.step.notifications.notification.trimbox",
        width: (0, _string.cm)(width),
        height: (0, _string.cm)(height)
      };
    }).readOnly()
  });

  _exports.default = _default;
});