define("boekdrukken/services/operations", ["exports", "design/util/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isBusy = _exports.default = void 0;

  var isBusy = function isBusy() {
    return Ember.computed('operations.running.@each.owner', function () {
      return !!this.operations.running.findBy('owner', this);
    }).readOnly();
  };

  _exports.isBusy = isBusy;

  var _default = Ember.Service.extend({
    models: Ember.inject.service(),
    leave: Ember.inject.service(),
    running: (0, _computed.array)(),
    _register: function _register(model) {
      var running = this.running;
      running.pushObject(model);
      model.promise.catch(function () {}).finally(function () {
        return running.removeObject(model);
      });
      this.leave.guard(model.promise);
      return model;
    },
    create: function create(name, props) {
      return this._register(this.models.create("operations/".concat(name), props));
    }
  });

  _exports.default = _default;
});