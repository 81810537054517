define("boekdrukken/components/ui-block/sketch/inspector/section/size/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+PtMSIgw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[28,\"t\",[\"block.sketch.inspector.section.size.width\"],null],false],[9],[0,\"\\n  \"],[1,[28,\"ui-input/text\",null,[[\"size\",\"value\",\"update\"],[\"small\",[24,[\"model\",\"width\"]],[28,\"action\",[[23,0,[]],[24,[\"update\"]],\"width\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[28,\"t\",[\"block.sketch.inspector.section.size.height\"],null],false],[9],[0,\"\\n  \"],[1,[28,\"ui-input/text\",null,[[\"size\",\"value\",\"update\"],[\"small\",[24,[\"model\",\"height\"]],[28,\"action\",[[23,0,[]],[24,[\"update\"]],\"height\"],null]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/inspector/section/size/template.hbs"
    }
  });

  _exports.default = _default;
});