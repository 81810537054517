define("boekdrukken/util/currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  });

  var _default = function _default(value) {
    if (isNaN(value)) {
      return;
    }

    return formatter.format(value);
  };

  _exports.default = _default;
});