define("boekdrukken/components/ui-block/accessory/popup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2nXkvTSn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"ui-block/accessory/popup/icon\",null,[[\"opts\",\"class\"],[[24,[\"opts\"]],\"icon\"]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"open\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"icon\"],[8],[0,\"\\n      \"],[1,[28,\"ui-block/accessory/popup/icon\",null,[[\"opts\",\"class\"],[[24,[\"opts\"]],\"icon\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"overlay\"],[8],[0,\"\\n      \"],[14,1,[[28,\"hash\",null,[[\"suspend\",\"close\"],[[28,\"action\",[[23,0,[]],\"suspend\"],null],[28,\"action\",[[23,0,[]],\"close\"],null]]]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/accessory/popup/template.hbs"
    }
  });

  _exports.default = _default;
});