define("boekdrukken/components/ui-route/onboarding/process/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var mapping = {
    'Invalid PDF structure': "Please make sure you upload a valid PDF"
  };

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-route-onboarding-process'],
    _error: Ember.computed.readOnly('model.error.message'),
    error: Ember.computed('_error', function () {
      var error = this._error;

      if (!error) {
        return;
      }

      return mapping[error] || error;
    }).readOnly(),
    actions: {
      index: function index() {
        this.router.transitionTo('index');
      }
    }
  });

  _exports.default = _default;
});