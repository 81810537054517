define("boekdrukken/components/ui-application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VjlH+51l",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[24,[\"isBlankType\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"ui-application/header\",null,[[\"class\",\"type\"],[\"header\",[24,[\"type\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"ui-application/alerts\",null,[[\"class\",\"type\"],[\"alerts\",[24,[\"type\"]]]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"ui-block/dialogs\",null,[[\"label\"],[\"application\"]]],false],[0,\"\\n\\n\"],[1,[22,\"ui-block/cookie-consent\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-application/template.hbs"
    }
  });

  _exports.default = _default;
});