define("boekdrukken/templates/missing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R56M2+Rx",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ui-route/missing\",null,[[\"path\"],[[24,[\"model\",\"path\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/templates/missing.hbs"
    }
  });

  _exports.default = _default;
});