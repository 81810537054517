define("boekdrukken/components/ui-route/backend/templates/index/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-route-backend-templates-index'],
    actions: {
      select: function select(template) {
        var type = template.type,
            id = template.id;
        this.router.transitionTo('backend.templates.type.template', type, id);
      },
      createNew: function createNew(type) {
        this.router.transitionTo('backend.templates.type.new', type.type);
      }
    }
  });

  _exports.default = _default;
});