define("boekdrukken/components/ui-block/product/book/step/content/component", ["exports", "boekdrukken/util/alive"], function (_exports, _alive) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-product-book-step-content'],
    step: null,
    product: Ember.computed.readOnly('step.product'),
    content: Ember.computed.readOnly('step.component'),
    pdf: Ember.computed.readOnly('content.pdf'),
    selection: Ember.computed.readOnly('pdf.selection'),
    filter: null,
    defaultColumns: 5,
    columns: 5,
    actions: {
      selectPair: function selectPair(selection) {
        this.pdf.select(selection);
      },
      updateFilter: function updateFilter(filter) {
        this.set('filter', filter);
      },
      updateColumns: function updateColumns(columns) {
        this.set('columns', columns);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.show();
    },
    show: (0, _alive.default)(function () {
      this.content.showFirstVisitDialog();
    })
  });

  _exports.default = _default;
});