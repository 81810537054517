define("boekdrukken/components/ui-block/sketch/switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p43A7PIr",
    "block": "{\"symbols\":[\"block\",\"choice\"],\"statements\":[[4,\"ui-block/segmented-button\",null,[[\"size\",\"type\"],[\"small\",\"fill\"]],{\"statements\":[[4,\"each\",[[24,[\"choices\"]]],null,{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"segment\"]],\"expected `block.segment` to be a contextual component but found a string. Did you mean `(component block.segment)`? ('boekdrukken/components/ui-block/sketch/switch/template.hbs' @ L3:C7) \"],null]],[[\"selected\",\"action\"],[[28,\"eq\",[[23,2,[]],[24,[\"selected\"]]],null],[28,\"action\",[[23,0,[]],\"select\",[23,2,[]]],null]]],{\"statements\":[[0,\"      \"],[1,[23,2,[\"label\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/switch/template.hbs"
    }
  });

  _exports.default = _default;
});