define("boekdrukken/instance-initializers/boekdrukken-injections", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'boekdrukken:injections',
    initialize: function initialize(app) {
      app.inject('component', 'router', 'service:router');
      app.inject('model', 'router', 'service:router');
      app.inject('component', 'intl', 'service:intl');
      app.inject('model', 'intl', 'service:intl');
    }
  };
  _exports.default = _default;
});