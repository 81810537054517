define("boekdrukken/models/localization/locale", ["exports", "boekdrukken/util/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    item: null,
    locale: null,
    editable: Ember.computed.readOnly('item.editable'),
    label: Ember.computed('locale', function () {
      var locale = this.locale;
      return "locale.".concat(locale);
    }).readOnly(),
    values: Ember.computed.readOnly('item.serialized'),
    value: Ember.computed('values', 'locale', function () {
      return this.values[this.locale];
    }).readOnly(),
    isValid: (0, _computed.notBlank)('value'),
    pinned: false,
    prepare: function prepare(props) {
      this.setProperties(props);
      this.reset();
    },
    reset: function reset() {
      this.setProperties({
        pinned: !this.isValid
      });
    }
  });

  _exports.default = _default;
});