define("boekdrukken/templates/stylebook/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tO1KP16o",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"ui-route/stylebook/index\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/templates/stylebook/index.hbs"
    }
  });

  _exports.default = _default;
});