define("boekdrukken/components/ui-block/cart/content/anonymous/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-cart-content-anonymous', 'hidden:hidden'],
    product: null,
    step: null,
    user: Ember.computed.readOnly('store.user'),
    hidden: Ember.computed.not('user.isAnonymous'),
    authRequirements: Ember.inject.service(),
    actions: {
      link: function link() {
        var router = this.router,
            uid = this.user.uid;

        var transition = function transition() {
          return router.transitionTo('users.user.cart', uid);
        };

        this.authRequirements.transitionTo(transition, 'account.link');
      }
    }
  });

  _exports.default = _default;
});