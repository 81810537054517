define("boekdrukken/components/ui-block/sketch/inspector/section/font-style/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var style = function style(key) {
    return "block.sketch.inspector.section.font-style.style.choices.".concat(key);
  };

  var weight = function weight(key) {
    return "block.sketch.inspector.section.font-style.weight.choices.".concat(key);
  };

  var styles = Ember.A([{
    label: style('normal'),
    value: 'normal',
    icon: 'typography-filled'
  }, {
    label: style('italic'),
    value: 'italic',
    icon: 'italic'
  }]);
  var weights = Ember.A([{
    label: weight('thin'),
    value: 100
  }, {
    label: weight('extra-light'),
    value: 200
  }, {
    label: weight('light'),
    value: 300
  }, {
    label: weight('regular'),
    value: 400
  }, {
    label: weight('medium'),
    value: 500
  }, {
    label: weight('semi-bold'),
    value: 600
  }, {
    label: weight('bold'),
    value: 700
  }, {
    label: weight('extra-bold'),
    value: 800
  }, {
    label: weight('black'),
    value: 900
  }]);

  var _default = Ember.Component.extend({
    classNameBindings: [':font-style'],
    styles: styles,
    weights: weights
  });

  _exports.default = _default;
});