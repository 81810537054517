define("boekdrukken/models/users/user/products/parameters/book/cover/finish", ["exports", "boekdrukken/models/users/user/products/parameters/book/-parameter"], function (_exports, _parameter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var options = Ember.A([{
    value: 'glossy'
  }, {
    value: 'matte'
  }]);

  var _default = _parameter.default.extend({
    options: options,
    value: (0, _parameter.doc)('finish'),
    changed: (0, _parameter.changed)('finish'),
    update: function update(finish) {
      this.parameters.update({
        finish: finish
      });
    }
  });

  _exports.default = _default;
});