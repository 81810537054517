define("boekdrukken/components/ui-block/cart/content/coupon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-cart-content-coupon'],
    coupon: null,
    actions: {
      update: function update(value, save) {
        this.coupon.update(value, save);
      },
      apply: function apply() {
        this.coupon.apply();
      }
    }
  });

  _exports.default = _default;
});