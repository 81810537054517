define("boekdrukken/components/ui-block/product/book/step/content/toolbar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a1qPuyd5",
    "block": "{\"symbols\":[\"button\",\"button\"],\"statements\":[[4,\"ui-input/button\",null,[[\"class\",\"type\",\"size\",\"value\",\"action\"],[\"item button\",\"outline\",\"tiny\",\"Reset\",[28,\"action\",[[23,0,[]],\"diff\",1],null]]],{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,2,[\"icon\"]],\"expected `button.icon` to be a contextual component but found a string. Did you mean `(component button.icon)`? ('boekdrukken/components/ui-block/product/book/step/content/toolbar/template.hbs' @ L2:C4) \"],null],\"minus-circle\"],[[\"class\"],[\"icon smaller\"]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n\"],[1,[28,\"ui-input/slider\",null,[[\"class\",\"min\",\"max\",\"step\",\"value\",\"update\"],[\"item slider\",[24,[\"min\"]],[24,[\"max\"]],1,[24,[\"value\"]],[28,\"action\",[[23,0,[]],\"updateValue\"],null]]]],false],[0,\"\\n\\n\"],[4,\"ui-input/button\",null,[[\"class\",\"type\",\"size\",\"value\",\"action\"],[\"item button\",\"outline\",\"tiny\",\"Reset\",[28,\"action\",[[23,0,[]],\"diff\",-1],null]]],{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"icon\"]],\"expected `button.icon` to be a contextual component but found a string. Did you mean `(component button.icon)`? ('boekdrukken/components/ui-block/product/book/step/content/toolbar/template.hbs' @ L8:C4) \"],null],\"plus-circle\"],[[\"class\"],[\"icon smaller\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[1,[28,\"ui-input/button\",null,[[\"class\",\"type\",\"size\",\"value\",\"action\"],[\"item button reset\",\"outline\",\"tiny\",[28,\"t\",[\"block.product.book.step.content.toolbar.reset\"],null],[28,\"action\",[[23,0,[]],\"reset\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/product/book/step/content/toolbar/template.hbs"
    }
  });

  _exports.default = _default;
});