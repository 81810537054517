define("boekdrukken/components/ui-application/header/navigation/breadcrumb/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-application-header-navigation-breadcrumb', 'item.component:custom:default'],
    click: function click() {
      this.action();
    }
  });

  _exports.default = _default;
});