define("boekdrukken/models/analytics/events", ["exports", "ember-cli-zuglet/lifecycle"], function (_exports, _lifecycle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.events = _exports.default = void 0;

  var events = function events(name) {
    return (0, _lifecycle.model)().named("analytics/events/".concat(name)).mapping(function (events) {
      return {
        events: events
      };
    });
  };

  _exports.events = events;

  var _default = Ember.Object.extend({
    analytics: null,
    index: events('index'),
    order: events('order'),
    ecommerce: events('ecommerce'),
    standalone: events('standalone')
  });

  _exports.default = _default;
});