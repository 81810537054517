define("boekdrukken/components/ui-block/product/book/step/content/sidebar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Sktw+Ui3",
    "block": "{\"symbols\":[\"type\",\"block\",\"item\"],\"statements\":[[4,\"ui-block/container\",null,[[\"type\",\"class\"],[\"compact\",\"content\"]],{\"statements\":[[0,\"\\n\"],[4,\"ui-block/segmented-button\",null,[[\"size\",\"type\"],[\"tiny\",\"fill\"]],{\"statements\":[[4,\"each\",[[24,[\"filters\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,2,[\"segment\"]],\"expected `block.segment` to be a contextual component but found a string. Did you mean `(component block.segment)`? ('boekdrukken/components/ui-block/product/book/step/content/sidebar/template.hbs' @ L5:C8) \"],null]],[[\"caption\",\"selected\",\"action\"],[[28,\"t\",[[23,3,[\"label\"]]],null],[28,\"eq\",[[23,3,[\"value\"]],[24,[\"filter\"]]],null],[28,\"action\",[[23,0,[]],\"filter\",[23,3,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[2]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"blocks\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"block\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"key\"],[8],[1,[28,\"t\",[\"block.product.book.step.content.sidebar.total-pages\"],null],false],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"value\"],[8],[1,[24,[\"pdf\",\"pageCount\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"pdf\",\"colorTypes\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"ui-block/product/book/step/content/sidebar/color-type\",null,[[\"type\",\"pdf\",\"markAll\"],[[23,1,[]],[24,[\"pdf\"]],[28,\"action\",[[23,0,[]],\"markAll\",[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/product/book/step/content/sidebar/template.hbs"
    }
  });

  _exports.default = _default;
});