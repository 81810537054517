define("boekdrukken/routes/users/user/orders/inbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(_ref) {
      var order_id = _ref.order_id;

      var _this$paramsFor = this.paramsFor('users.user'),
          user_id = _this$paramsFor.user_id;

      this.transitionTo('users.user.orders.order', user_id, order_id);
    }
  });

  _exports.default = _default;
});