define("boekdrukken/components/ui-block/sketch/tree/models/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ic0Nvr7a",
    "block": "{\"symbols\":[\"nested\"],\"statements\":[[4,\"each\",[[24,[\"reversed\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"ui-block/sketch/tree/model\",null,[[\"model\",\"level\"],[[23,1,[]],[24,[\"level\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/sketch/tree/models/template.hbs"
    }
  });

  _exports.default = _default;
});