define("boekdrukken/components/ui-route/stylebook/icons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ImG8sGgY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"stylebook.icons\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"section\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"stylebook.font-awesome\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[1,[28,\"ui-block/icon\",[\"check\"],[[\"class\"],[\"icon\"]]],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[1,[28,\"ui-block/icon\",[\"bath\"],[[\"class\"],[\"icon\"]]],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[1,[28,\"ui-block/icon\",[\"rocket\"],[[\"class\"],[\"icon\"]]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"section\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[28,\"t\",[\"stylebook.custom\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[1,[28,\"ui-block/icon\",[\"ok\"],[[\"type\",\"class\"],[\"custom\",\"icon\"]]],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[1,[28,\"ui-block/icon\",[\"upload-to-cloud\"],[[\"type\",\"class\"],[\"custom\",\"icon\"]]],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[1,[28,\"ui-block/icon\",[\"extra-large-icon\"],[[\"type\",\"class\"],[\"custom\",\"icon\"]]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-route/stylebook/icons/template.hbs"
    }
  });

  _exports.default = _default;
});