define("boekdrukken/routes/-alerts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.alerts = void 0;

  var alerts = function alerts(opts) {
    opts = Ember.assign({
      suspend: []
    }, opts);
    return Ember.computed(function () {
      return opts;
    }).readOnly();
  };

  _exports.alerts = alerts;

  var _default = Ember.Mixin.create({
    alerts: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this.alerts.resume(controller.model);

      this._super.apply(this, arguments);

      this.alerts.suspend(model);
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this.alerts.resume(this.modelFor(this.routeName));
    }
  });

  _exports.default = _default;
});