define("boekdrukken/routes/internal/render", ["exports", "ember-cli-zuglet/lifecycle"], function (_exports, _lifecycle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: (0, _lifecycle.route)().inline({
      analyticsSuppressed: true,
      prepare: function prepare() {}
    })
  });

  _exports.default = _default;
});