define("boekdrukken/translations/en", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "application": {
      "header": {
        "auth": {
          "anonymous": "Anonymous Account",
          "domain": "@{domain}",
          "sign-in": "Sign in"
        },
        "back": {
          "label": "Back to website"
        },
        "cart": {
          "label": "Cart"
        },
        "locales": {
          "keys": "Keys"
        },
        "navigation": {
          "breadcrumb": {
            "admin": {
              "anonymous": "Anonymous user"
            },
            "label": {
              "backend": {
                "index": "Backend",
                "localization": {
                  "index": "Localization"
                },
                "orders": "Orders",
                "subscriptions": "Subscriptions",
                "templates": {
                  "index": "Templates",
                  "type": {
                    "design": "Design",
                    "edit": "Edit",
                    "new": "New Template",
                    "preview": {
                      "design": "Design",
                      "index": "Preview"
                    }
                  }
                },
                "xml": {
                  "index": "XML Export"
                }
              },
              "definitions": {
                "definition": "Upload your PDF"
              },
              "index": "Welcome",
              "onboarding": {
                "process": "Uploading PDF…"
              },
              "stylebook": {
                "index": "Stylebook"
              },
              "users": {
                "user": {
                  "cart": {
                    "index": "Your Cart"
                  },
                  "orders": {
                    "order": {
                      "index": "Your Order"
                    }
                  },
                  "products": {
                    "index": "Products",
                    "product": {
                      "delete": "Delete"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "block": {
      "cart": {
        "content": {
          "actions": {
            "actions": {
              "checkout": "Checkout"
            },
            "status": {
              "busy": "Calculating price…"
            }
          },
          "address": {
            "header": {
              "title": "Address details"
            },
            "label": {
              "city": "City",
              "country": "Country",
              "email": "Email",
              "first-name": "First Name",
              "last-name": "Last Name",
              "number": "Number",
              "postal-code": "Postal code",
              "street": "Street"
            }
          },
          "anonymous": {
            "actions": {
              "link": "Secure your account"
            },
            "header": {
              "description": "Secure your current anonymous account with your email address and a password to return to your books later on.",
              "title": "Anonymous account"
            }
          },
          "coupon": {
            "actions": {
              "apply": "Apply"
            },
            "header": {
              "title": "Discount code"
            },
            "status": {
              "applied": "Coupon code applied",
              "checking": "Checking discount code…",
              "conditions": {
                "reason": {
                  "default": "The conditions for the coupon are not met."
                }
              },
              "invalid": {
                "description": "Codes are case sensitive. Please double-check your coupon code and try again.",
                "title": "Looks like you entered an invalid code."
              },
              "removing": "Removing discount code…"
            }
          },
          "highlight": {
            "address": "Please make sure all address fields are filled",
            "anonymous": "Please sign up for an account",
            "legal": "Please accept our privacy policy and terms & conditions",
            "payment": "Please select a payment method"
          },
          "items": {
            "edit": {
              "actions": {
                "cancel": "Cancel",
                "edit": "Edit product"
              },
              "message": "Editing this product will remove it from the cart"
            },
            "item": {
              "actions": {
                "default": "Actions…",
                "delete": "Remove",
                "edit": "Edit"
              },
              "count": {
                "edit": "Edit",
                "save": "Save"
              },
              "delete": {
                "cancel": "Cancel",
                "message": "Are you sure you want to remove this product from your cart?",
                "remove": "Remove product"
              },
              "discount": "Includes discount of {total}",
              "times": "×",
              "total": {
                "label": "Total"
              }
            }
          },
          "legal": {
            "header": {
              "title": "Legal"
            },
            "label": {
              "privacy": "Accept privacy policy",
              "terms": "Accept terms & conditions"
            }
          },
          "newsletter": {
            "header": {
              "title": "Newsletter"
            },
            "label": "Subscribe to newsletter"
          },
          "payment": {
            "header": {
              "title": "Payment Method"
            }
          },
          "signup": {
            "actions": {
              "or": "or",
              "skip": "Continue anonymously"
            },
            "header": {
              "description": "Secure your current anonymous account with your email address and a password to return to your books later on.",
              "title": "Sign up for an account"
            }
          },
          "total": {
            "discount": {
              "value": "Includes discount of {total}"
            },
            "header": {
              "title": "Total"
            },
            "shipping": {
              "label": "Shipping"
            },
            "vat": {
              "value": "incl. VAT {percent}% of {total}"
            }
          }
        },
        "header": {
          "actions": {
            "new": "Start a new book",
            "products": "Add existing product"
          },
          "title": "Your Cart"
        },
        "placeholder": {
          "actions": {
            "new": "Start a new book",
            "products": "Go to your products"
          },
          "title": "Your cart is empty"
        }
      },
      "cookie-consent": {
        "accept": "Accept",
        "label": "This website uses cookies to ensure you get the best experience on our website."
      },
      "definition": {
        "custom": {
          "create": "Create product",
          "name": "Product name",
          "title": "Create a custom product",
          "type": "Custom type"
        }
      },
      "dev": {
        "index": {
          "actions": {
            "backend": "Backend",
            "sign-in": "Sign-in",
            "sign-out": "Sign-out",
            "stylebook": "Stylebook"
          },
          "admin": "Admin",
          "client": "Client",
          "css": "CSS",
          "devops": "Devops",
          "environment": "Environment",
          "functions": "boekdrukken-functions",
          "loading": "Loading…",
          "locales": "Locales",
          "none": "None",
          "not-logged-in": "Not logged in",
          "version": "v{version}"
        }
      },
      "flow": {
        "navigation": {
          "continue": "Continue",
          "label": "Step {number} of {total}",
          "previous": "Previous"
        }
      },
      "localization": {
        "editor": {
          "items": {
            "placeholder": "Nothing to show"
          },
          "readonly": "This localization is not editbale."
        }
      },
      "product": {
        "book": {
          "step": {
            "content": {
              "loading": "Loading pages…",
              "sidebar": {
                "alert": {
                  "cancel": "Cancel",
                  "mark": "Mark all pages {type}",
                  "message": "Are you sure you want to mark all pages {type}?"
                },
                "color-type": {
                  "mark-all": "Mark all"
                },
                "filter": {
                  "all": "All"
                },
                "total-pages": "Total pages"
              },
              "toolbar": {
                "reset": "Reset"
              }
            },
            "cover": {
              "design": {
                "back": {
                  "label": "Back to select type"
                },
                "dev": {
                  "actions": {
                    "remove-design": "Remove design"
                  },
                  "title": "Development tools"
                },
                "pdf": {
                  "create": "Create PDF",
                  "error": "Sorry, something went wrong",
                  "open": {
                    "pdf": "Open PDF File",
                    "preview": "Open Preview"
                  },
                  "pending": "Queued…",
                  "recreate": "Create new preview",
                  "running": "Creating PDF…",
                  "title": "Preview Cover"
                }
              },
              "templates": {
                "content": {
                  "selected": {
                    "placeholder": "Please choose a book cover template from the list"
                  }
                },
                "label": "Choose Template",
                "standalone": {
                  "actions": {
                    "cancel": "Cancel",
                    "choose": "Choose template to start"
                  },
                  "creating": "Creating book cover…",
                  "description": "This book cover doesn't have a design yet. Please choose a template to start.",
                  "title": "Choose a book cover template"
                }
              },
              "types": {
                "header": {
                  "description": "How would you like to design your book cover?",
                  "title": "Create cover"
                },
                "online": {
                  "actions": {
                    "blank": "Start from scratch",
                    "continue": "Continue designing online",
                    "template": "Use template"
                  },
                  "header": "Our online editor"
                },
                "upload": {
                  "actions": {
                    "use": "Use your own tools"
                  },
                  "header": "Your own tools"
                }
              },
              "upload": {
                "actions": {
                  "back": "Back to select type",
                  "download": {
                    "default": "Download template",
                    "download": "Download PDF template",
                    "in-progress": "Creating template…"
                  },
                  "upload": {
                    "default": "Upload finished design",
                    "in-progress": "Uploading finished design…",
                    "replace": "Replace finished design"
                  }
                },
                "details": {
                  "info": "{pageCount} {pageCount, plural, one {page} other {pages}}, with finished size of {dimensions}",
                  "multiple-pages": "This PDF contains more than one page. We will be using the first page only.",
                  "trimbox": {
                    "uniform": "Trimbox is set to {uniform} for all edges",
                    "variable": "Trimbox is set to {top} top, {right} right, {bottom} bottom, {left} left"
                  }
                },
                "error": {
                  "internal": "Sorry, something went wrong",
                  "pdf-load": "Uploaded cover PDF is not valid"
                },
                "header": "Use your own tools",
                "processing": {
                  "title": "Processing finished design…"
                },
                "status": {
                  "valid": "Uploaded PDF is valid and will be used as a cover design"
                }
              }
            },
            "parameters": {
              "content": {
                "paper-type": {
                  "label": "Paper Type"
                },
                "title": "Inner Pages"
              },
              "cover": {
                "cover-type": {
                  "label": "Cover Type",
                  "placeholder": "Paperback"
                },
                "description": "Always printed in full color",
                "paper-finish": {
                  "label": "Paper Finish",
                  "type": {
                    "glossy": "Glossy",
                    "matte": "Matte"
                  }
                },
                "paper-type": {
                  "label": "Paper Type"
                },
                "title": "Cover"
              },
              "row": {
                "unlock": "Press to unlock"
              }
            },
            "source": {
              "tooltip": "See <a href=\"{url}\" target=\"top\">Checklist</a> for more information on how to adjust your book size."
            },
            "summary": {
              "actions": {
                "add-to-cart": "Add to cart"
              },
              "busy": "Creating cover PDF…",
              "header": "Summary"
            }
          }
        },
        "custom": {
          "step": {
            "summary": {
              "actions": {
                "add-to-cart": "Add to cart"
              },
              "details": {
                "header": "Details",
                "identifier": "Identifier",
                "name": "Product name",
                "price": "Base price",
                "shipping": "Shipping price",
                "type": "Type",
                "types": {
                  "book": "Custom Book",
                  "poster": "Custom Poster",
                  "sticker": "Custom Stickers"
                }
              },
              "header": "Summary",
              "thumbnail": {
                "header": "Thumbnail",
                "upload": "Upload"
              }
            }
          }
        },
        "step": {
          "errors": {
            "action": {
              "show-step": "Go to step",
              "start-again": "Start again",
              "try-again": "Try again"
            },
            "error": {
              "content-size": {
                "fallback": "Sorry, printing books in this size is not currently supported",
                "max": "Sorry, maximum book size is {width} × {height}",
                "min": "Sorry, minimum book size is {width} × {height}"
              },
              "default": "Sorry, something went wrong. Please contact support.",
              "dimensions-mismatch": "Sorry, all pages should have exactly the same size",
              "spine": {
                "max": "Sorry, maximum page count is {max} pages",
                "min": "Sorry, minimum page count is {min} pages"
              }
            }
          },
          "notifications": {
            "notification": {
              "trimbox": "We detected crop marks in your document. Finished size will be {width}{nbsp}×{nbsp}{height}"
            }
          },
          "product": {
            "price": {
              "total": "Total"
            }
          }
        }
      },
      "products": {
        "list": {
          "actions": {
            "custom": "New custom product",
            "new": "Start a new book"
          },
          "header": {
            "title": "Your Books"
          },
          "placeholder": "You do not have any books yet",
          "product": {
            "actions": {
              "edit": "Edit Book"
            },
            "details": {
              "accessed-at": "Last Accessed",
              "created-at": "Date Created",
              "dimensions": "Dimensions",
              "pages": "Total pages",
              "source": "Based on"
            },
            "tools": {
              "default": "More…",
              "delete": "Delete book"
            }
          }
        }
      },
      "sketch": {
        "inspector": {
          "section": {
            "align": {
              "horizontal": {
                "choices": {
                  "center": "Center",
                  "justify": "Justify",
                  "left": "Left",
                  "right": "Right"
                },
                "label": "Align"
              },
              "vertical": {
                "choices": {
                  "bottom": "Bottom",
                  "middle": "Middle",
                  "top": "Top"
                },
                "label": "Vertical Align"
              }
            },
            "fit": {
              "choices": {
                "contain": "Contain image into bounds",
                "fill": "Fill bounds"
              }
            },
            "font": {
              "family": "Font Family",
              "line-height": "Line",
              "size": "Font Size"
            },
            "font-style": {
              "style": {
                "choices": {
                  "italic": "Italic",
                  "normal": "Normal"
                },
                "label": "Font Style"
              },
              "weight": {
                "choices": {
                  "black": "Black",
                  "bold": "Bold",
                  "extra-bold": "Extra bold",
                  "extra-light": "Extra light",
                  "light": "Light",
                  "medium": "Medium",
                  "regular": "Regular",
                  "semi-bold": "Semi-bold",
                  "thin": "Thin"
                },
                "label": "Font Weight"
              }
            },
            "labels": {
              "color": "Color",
              "delete": "Delete",
              "fill": "Background Color",
              "fit": "Image Mode",
              "identifier": "Identifier",
              "opacity": "Opacity",
              "rotation": "Rotation",
              "text": "Text"
            },
            "move": {
              "backward": "Move backward",
              "forward": "Move forward"
            },
            "position": {
              "x": "X",
              "y": "Y"
            },
            "size": {
              "height": "Height",
              "width": "Width"
            },
            "size-warning": {
              "description": {
                "main": "Provided image is {size} which is too small. To get this to print decently, please upload a larger one.",
                "size": "<span class=\"size\"><span class=\"px\">{width}</span><span class=\"times\"></span><span class=\"px\">{height}</span></span>"
              },
              "ignore": "Ignore",
              "title": "Image too small"
            }
          },
          "selection": {
            "model": {
              "image": {
                "upload": "Choose image"
              }
            }
          }
        },
        "nodes": {
          "types": {
            "default": "Add…",
            "image": "Image",
            "rect": "Rectangle",
            "text": "Text"
          }
        },
        "stage": {
          "node": {
            "area": {
              "accessories": {
                "book-crop-marks": {
                  "label": {
                    "back": "Back cover",
                    "front": "Front cover",
                    "spine": "Spine"
                  }
                }
              }
            }
          }
        },
        "zoom": {
          "fit": "Fit"
        }
      },
      "standalone": {
        "calculator": {
          "book": {
            "actions": {
              "calculate": "Calculate"
            },
            "field": {
              "count": {
                "highlight": "Please provide number of books",
                "label": "Number of books",
                "placeholder": "e.g. 50"
              },
              "country": {
                "label": "Shipping to"
              },
              "height": {
                "highlight": "Please provide book height in millimeters",
                "label": "Height",
                "placeholder": "Scientific (e.g. 240 mm)"
              },
              "pages": {
                "highlight": "Please provide total number of pages",
                "label": "Total number of pages",
                "placeholder": "e.g. 100"
              },
              "pages-in-color": {
                "highlight": "Please provide number of pages in color",
                "label": "Pages in color",
                "placeholder": "e.g. 20"
              },
              "width": {
                "highlight": "Please provide book width in millimeters",
                "label": "Width",
                "placeholder": "Scientific (e.g. 170 mm)"
              }
            },
            "result": {
              "discount": "Includes discount of {total}",
              "price": "Price for {count} {count, plural, one {book} other {books}}",
              "price-per-book": "Price per book",
              "shipping": "Shipping",
              "total": "Total",
              "vat": "Includes {percent}% VAT of {total}"
            },
            "status": {
              "busy": "Calculating price…",
              "error": {
                "content-size": "Sorry, this book size is not supported",
                "default": "Sorry, please try again",
                "spine": "Sorry, this page count is not supported"
              },
              "loading": "Loading…"
            }
          }
        },
        "download": {
          "book": {
            "actions": {
              "create": "Create Template",
              "download": "Download Template"
            },
            "field": {
              "content": {
                "highlight": "Please select content paper",
                "label": "Content paper"
              },
              "cover": {
                "highlight": "Please select cover paper",
                "label": "Cover paper"
              },
              "height": {
                "highlight": "Please provide book height in millimeters",
                "label": "Height",
                "placeholder": "Scientific (e.g. 240 mm)"
              },
              "pages": {
                "highlight": "Please provide number of pages for the book",
                "label": "Number of pages",
                "placeholder": "e.g. 100"
              },
              "width": {
                "highlight": "Please provide book width in millimeters",
                "label": "Width",
                "placeholder": "Scientific (e.g. 170 mm)"
              }
            },
            "status": {
              "busy": "Creating Template PDF…",
              "error": "Sorry, please try again"
            }
          }
        }
      },
      "stripe": {
        "loading": "Loading…",
        "type": {
          "bancontact": {
            "identifier": "Bancontact",
            "name": {
              "placeholder": "Full name"
            }
          },
          "card": {
            "identifier": "Credit Card",
            "name": {
              "placeholder": "Name on the card"
            }
          },
          "ideal": {
            "identifier": "iDEAL"
          }
        }
      },
      "user": {
        "profile": {
          "actions": {
            "reset-password": "Reset Password",
            "sign-out": "Sign Out"
          },
          "anonymous": {
            "actions": {
              "link": "Secure your account"
            },
            "description": "Secure your current anonymous account with your email address and a password to return to your books later on.",
            "header": "Anonymous account"
          },
          "email": "Email",
          "header": {
            "title": "Your Profile"
          },
          "password": "Password",
          "reset-password": {
            "sent": "Reset password email has been sent"
          },
          "subscriptions": {
            "alerts": {
              "subscribed": "Thank you for subscribing to newsletter"
            },
            "dialogs": {
              "sign-out": {
                "cancel": "Cancel",
                "confirm": "Sign out",
                "message": "Are you sure you want to sign out?"
              },
              "unsubscribe": {
                "cancel": "Cancel",
                "confirm": "Unsubscribe",
                "message": "Are you sure you want to unsubscribe from newsletter?"
              }
            },
            "state": {
              "subscribed": "Subscribed",
              "unsubscribed": "Subscribe"
            },
            "subscription": {
              "newsletter": "Newsletter"
            },
            "title": "Email subscriptions"
          }
        }
      }
    },
    "dialog": {
      "apply-product-parameters": {
        "actions": {
          "apply": "Apply Changes",
          "cancel": "Discard"
        },
        "content": "You've made some changes which will affect your cover design.",
        "header": "Apply changes?"
      },
      "color-pages": {
        "actions": {
          "cancel": "Print all in black and white",
          "mark": "Mark pages"
        },
        "description": "Mark pages to be printed in color by selecting <span class=\"color\">color</span> or skip this step to print all pages in black and white.",
        "header": "Do you want to print any inner pages in color?"
      },
      "component-templates": {
        "actions": {
          "cancel": "Cancel",
          "choose": "Choose Template"
        },
        "header": "Choose a book cover template"
      },
      "prefilled-color-pages": {
        "actions": {
          "cancel": "Go to next step",
          "mark": "Mark pages"
        },
        "content": "Mark pages to be printed in <span class=\"color\">black and white</span> or <span class=\"color\">color</span>, or skip this step to print pages as they are identified.",
        "header": "We identified {count} {count, plural, one {page} other {pages}} in color"
      },
      "unlock-product-parameters": {
        "actions": {
          "cancel": "Cancel",
          "unlock": "Unlock Parameters"
        },
        "content": {
          "description": "You are trying to change one of the key parameters of your book. These parameters are crucial and define cover dimensions. You will need to adjust your cover once you change them.",
          "help": "Help Section",
          "more": "You can learn more about key parameters of your book in our <a href={externalChecklistURL} target=\"top\">{help}</a>."
        },
        "header": "Unlock Key Parameters?"
      },
      "upload-image": {
        "actions": {
          "cancel": "Cancel",
          "upload": "Upload"
        },
        "header": "Upload an image",
        "select": "Select an image",
        "uploading": "Uploading…"
      }
    },
    "email": {
      "order": {
        "client": {
          "description": "Happy you chose {appname} to print your book. We are jumping into action to make sure your order arrives in time. If you have any questions, feel free to contact us. Have a nice day!",
          "order": {
            "address": {
              "description": "We will ship your order to the following address",
              "header": "Shipment details"
            },
            "assets": {
              "components": {
                "content": "Inside",
                "cover": "Cover"
              },
              "description": "We are printing a book from the following files",
              "download-pdf": "Download PDF",
              "header": "Your files"
            },
            "date": "Order date",
            "delivery-date": "Expected delivery date",
            "discount": "Includes discount of",
            "header": {
              "item": "Item",
              "price": "Price"
            },
            "id": "Order id",
            "payment-method": {
              "header": "Payment method",
              "methods": {
                "bancontact": "Bancontact",
                "card": "Credit Card",
                "ideal": "iDEAL"
              }
            },
            "questions": {
              "description": "If you have any questions, feel free to contact us at {link}.",
              "header": "Questions"
            },
            "shipping": "Shipping",
            "title": "Your order",
            "total": "Total price",
            "vat": "Includes {percent}% VAT of"
          },
          "product": {
            "book": {
              "components": {
                "content": {
                  "header": "Inside",
                  "pages": {
                    "color": {
                      "indexes": "Colored pages",
                      "total": "Pages in color: {count} {count, plural, one {page} other {pages}}"
                    },
                    "total": "Pages"
                  },
                  "paper": "Material",
                  "size": "Size"
                },
                "cover": {
                  "finish": "Finish",
                  "header": "Cover",
                  "paper": "Material"
                }
              },
              "count": "Ordered amount",
              "header": "Book"
            },
            "custom": {
              "book": {
                "header": "Book"
              },
              "count": "Ordered amount",
              "poster": {
                "header": "Poster"
              },
              "sticker": {
                "header": "Sticker"
              }
            }
          },
          "subject": "Thank you for your order",
          "title": "Thank you"
        }
      },
      "password-reset": {
        "action": "Reset Password",
        "directions": "Click on \"Rest Password\" to reset your %APP_NAME% password for your {link} account.",
        "hello": "Hello,",
        "ignore": "If you didn’t ask to reset your password, you can ignore this email.",
        "subject": "Reset your password for %APP_NAME%",
        "team": "Your %APP_NAME% team",
        "thanks": "Thanks,"
      },
      "welcome": {
        "appname": "Welcome to {appname}!",
        "hello": "Hello,",
        "products": "You can always access your books at {products}.",
        "subject": "Welcome to {appname}"
      }
    },
    "input": {
      "color-picker": {
        "reset": "Reset"
      },
      "file": {
        "drag": "or drag it here",
        "selected": "Selected",
        "title": "Choose file"
      }
    },
    "locale": {
      "en-us": "en",
      "nl": "nl"
    },
    "model": {
      "alerts": {
        "mobile": {
          "label": "This tool is best used on desktop"
        }
      },
      "assets": {
        "pdf": {
          "runtime": {
            "color-types": {
              "bw": {
                "description": "Pages in Black & White",
                "label": "Black & White",
                "short": "b & w"
              },
              "color": {
                "description": "Full Color Pages",
                "label": "Full Color",
                "short": "color"
              }
            }
          }
        }
      },
      "product": {
        "definition": {
          "book": {
            "onboarding": {
              "message": "Creating book from \"{name}\""
            }
          }
        }
      },
      "sketch": {
        "base": {
          "stage": {
            "name": "Design",
            "node": {
              "area": {
                "identifier": {
                  "back": "Back cover",
                  "background": "Background",
                  "content": "Content",
                  "front": "Front cover",
                  "spine": "Spine"
                },
                "name": "Area"
              },
              "image": {
                "name": "Image"
              },
              "rect": {
                "name": "Rectangle"
              },
              "text": {
                "name": "Text"
              }
            }
          }
        },
        "strings": {
          "edit": "Edit",
          "loading-font": "Loading font…"
        }
      },
      "templates": {
        "book-cover": {
          "title": "Book Cover"
        }
      },
      "upload": {
        "errors": {
          "dimensions": "Uploaded PDF should be {dimensions} with space for {crop} trim box on all sides",
          "page-count": "Uploaded PDF should contain a single page",
          "trim-distance": "Upload PDF should be {dimensions} and have {crop} space around trim box"
        }
      },
      "users": {
        "user": {
          "cart": {
            "coupon": {
              "count": "This coupon can only be applied to 1 copy of the book",
              "internal": "This coupon is invalid",
              "items-count": "This coupon can only be applied to orders which consists of 1 product",
              "pages-250": "This coupon can only be applied to books with a maximum of 250 pages",
              "pages-400": "This coupon can only be applied to books with a maximum of 400 pages",
              "pages-color": "This coupon can only be applied to books printed in black and white",
              "pages-color-30": "This coupon can only be applied to books with maximum of 30 color pages"
            },
            "items": {
              "item": {
                "types": {
                  "book": {
                    "count": {
                      "description": "Number of books",
                      "value": "{count} {count, plural, one {Book} other {Books}}"
                    },
                    "plural": "Books",
                    "singular": "Book"
                  },
                  "poster": {
                    "count": {
                      "description": "Number of posters",
                      "value": "{count} {count, plural, one {Poster} other {Posters}}"
                    },
                    "plural": "Posters",
                    "singular": "Poster"
                  },
                  "sticker": {
                    "count": {
                      "description": "Number of stickers",
                      "value": "{count} {count, plural, one {Sticker} other {Stickers}}"
                    },
                    "plural": "Stickers",
                    "singular": "Sticker"
                  }
                }
              }
            }
          },
          "products": {
            "book": {
              "flow": {
                "content": "Mark Pages",
                "cover": "Create cover",
                "parameters": "Required Parameters",
                "source": "Upload PDF",
                "summary": "Summary"
              },
              "title": "Book"
            },
            "components": {
              "book": {
                "content": {
                  "page-count": "{pageCount} {pageCount, plural, one {page} other {pages}}",
                  "page-count-summary": "{pageCount} {pageCount, plural, one {page} other {pages}} in {colorType, select, bw {black and white} color {full color} other {unknown}}",
                  "title": "Content"
                },
                "cover": {
                  "description": {
                    "design": "Cover design created",
                    "invalid-upload": "Please check your uploaded cover design",
                    "missing": "Create your cover design",
                    "missing-upload": "Please upload your book cover design",
                    "valid-upload": "Cover design uploaded"
                  },
                  "title": "Cover"
                }
              }
            },
            "custom": {
              "flow": {
                "summary": "Summary"
              }
            },
            "product": {
              "pricing": {
                "description": {
                  "attention": "Attention",
                  "loading": "Loading…"
                }
              }
            }
          }
        }
      }
    },
    "route": {
      "account": {
        "errors": {
          "email-already-in-use": "The email address is already in use by another account.",
          "user-not-found": "There is no user record corresponding to this identifier. The user may have been deleted.",
          "weak-password": "Password should be at least 6 characters",
          "wrong-password": "The password is invalid or the user does not have a password."
        },
        "link": {
          "action": "Secure Account",
          "description": "Secure your current anonymous account with your email address and a password to return to your books later on.",
          "placeholder": {
            "email": "Your email address",
            "password": "Choose a password"
          },
          "title": "Secure your account"
        },
        "new": {
          "action": {
            "sign-in": "Sign In",
            "sign-up": "Sign Up"
          },
          "anonymous": "Continue anonymously",
          "description": "Please sign in or create an account to make sure your work is continuously saved and you can always access it. Accounts are always free.",
          "forgot": "Forgot your password?",
          "or": "or",
          "placeholder": {
            "email": "Your email address",
            "password": "Choose a password"
          },
          "title": "Sign up for an account"
        },
        "reset": {
          "action": "Reset password",
          "cancel": "Cancel",
          "or": "or",
          "placeholder": {
            "email": "Your email address"
          },
          "title": "Reset your password"
        },
        "restore": {
          "action": "Sign In",
          "forgot": "Forgot your password?",
          "no-account": "Don't have an account yet?",
          "placeholder": {
            "email": "Your email address",
            "password": "Choose a password"
          },
          "title": "Sign in"
        }
      },
      "backend": {
        "index": {
          "actions": {
            "localization": "Localization",
            "orders": "Orders",
            "stylebook": "Stylebook",
            "subscriptions": "Subscriptions",
            "templates": "Templates",
            "xml": "XML"
          },
          "title": "Backend"
        },
        "orders": {
          "range": {
            "index": {
              "header": "Orders",
              "orders": {
                "placeholder": "No orders"
              },
              "summary": {
                "products": {
                  "count": "{count} ordered",
                  "custom": "Custom {type}"
                },
                "total": {
                  "orders": "Total # of orders",
                  "products": "Total # of products sold",
                  "total": "Orders total (inc. VAT)"
                }
              }
            }
          }
        },
        "subscriptions": {
          "download": "Download",
          "loading": "Generating CSV…"
        },
        "templates": {
          "index": {
            "actions": {
              "new": "Create new template"
            },
            "placeholder": "No templates for this type created yet"
          },
          "type": {
            "new": {
              "actions": {
                "cancel": "Cancel",
                "save": "Save"
              },
              "label": {
                "description": "Description",
                "title": "Title"
              }
            },
            "template": {
              "edit": {
                "actions": {
                  "cancel": "Cancel",
                  "save": "Save"
                },
                "header": "Template Details",
                "label": {
                  "description": "Description",
                  "position": "Position",
                  "state": "State",
                  "title": "Title"
                }
              },
              "preview": {
                "edit": {
                  "actions": {
                    "cancel": "Cancel",
                    "preview": "Preview"
                  },
                  "label": {
                    "crop-size": "Crop size (mm)",
                    "page-height": "Page height (mm)",
                    "page-width": "Page width (mm)",
                    "spine-width": "Spine width (mm)"
                  },
                  "title": "Template Preview"
                }
              }
            }
          }
        },
        "xml": {
          "actions": {
            "fetch": "Fetch",
            "upload": "Place order"
          },
          "identifier": "Order identifier",
          "title": "XML Export",
          "upload": {
            "confirmation": {
              "cancel": "Cancel",
              "commit": "Place an order",
              "message": "Are you sure you want to place an order?"
            },
            "status": "Upload status"
          }
        }
      },
      "error": {
        "title": "Something went wrong"
      },
      "index": {
        "help": {
          "actions": {
            "calculate-price": "Calculate price",
            "preparation-steps": "Go to my steps"
          },
          "title": "Not ready to print your book yet?"
        },
        "upload": {
          "create-book": "Create your book",
          "error": {
            "action": "Bestand drukklaar maken",
            "not-a-pdf": "Please choose a PDF file"
          },
          "select-pdf": "Select a PDF file",
          "title": "Upload a PDF file to start"
        }
      },
      "maintenance": {
        "title": "Sorry, we'll be back in few minutes"
      },
      "missing": {
        "title": "Page was not found"
      },
      "onboarding": {
        "process": {
          "error": {
            "retry": "Try again",
            "title": "Sorry, something went wrong"
          },
          "step": {
            "processing": "Processing PDF pages…",
            "uploading": "Uploading PDF…"
          },
          "title": "{message}…"
        }
      },
      "standalone": {
        "add": {
          "token": {
            "error": "Sorry, something went wrong",
            "loading": "Adding products to cart…"
          }
        }
      },
      "users": {
        "user": {
          "cart": {
            "loading": "Loading cart"
          },
          "orders": {
            "order": {
              "index": {
                "done": "Done",
                "error": {
                  "support": "Please contact support at: <a href=\"mailto:{support}\">{support}</a>."
                },
                "header": {
                  "error": "Something went wrong.",
                  "failed": "Payment failed. Please try again.",
                  "pending": "Please wait while we process your payment…",
                  "success": "Success!"
                },
                "retry": "Try again",
                "success": {
                  "delivery": "Delivery is scheduled for: <span class=\"date\">{date}</span>",
                  "email": "In a couple of minutes you will receive an order confirmation email at <span class=\"email\">{email}</span> with all the details of your order",
                  "enjoy": "Enjoy your books!",
                  "identifier": "Your order id is: <span class=\"identifier\">{identifier}</span>",
                  "questions": "If you have any questions you can contact us at: <a href=\"mailto:{support}\">{support}</a>"
                }
              }
            }
          },
          "products": {
            "index": {
              "delete": {
                "cancel": "Cancel",
                "delete": "Delete this book",
                "message": "Are you sure you want to delete '{name}' book?"
              },
              "tabs": {
                "products": "Products",
                "profile": "Profile"
              }
            },
            "loading": "Loading products",
            "product": {
              "delete": {
                "title": "Deleting <span class=\"strong\">\"{name}\"</span>"
              },
              "index": {
                "actions": {
                  "delete": "Delete this product"
                },
                "errors": {
                  "pdf-load": "Error loading PDF."
                },
                "title": "Sorry, something went wrong"
              },
              "loading": "Loading product",
              "step": {
                "loading": "Loading step"
              }
            }
          }
        }
      }
    },
    "settings": {
      "book": {
        "paper": {
          "content": {
            "hvo-wit-100": {
              "description": "Premium",
              "name": "Wood Free, White, 100 grs"
            },
            "hvo-wit-120": {
              "description": "Extra heavy",
              "name": "Wood Free, White, 120 grs"
            },
            "hvo-wit-80": {
              "description": "Standard",
              "name": "Wood Free, White, 80 grs"
            },
            "hvo-wit-90": {
              "description": "Standard",
              "name": "Wood Free, White, 90 grs"
            },
            "machine-coated-115": {
              "description": "Heavy",
              "name": "Machine Coated, 115 grs"
            },
            "roman-90": {
              "description": "Standard",
              "name": "Roman, 90 grs"
            }
          },
          "cover": {
            "houtvrij-machine-coated-170": {
              "description": "Flexible",
              "name": "Wood Free Machine Coated, 170 gr"
            },
            "machine-coated-300": {
              "description": "Firm",
              "name": "Machine Coated, 300 gr"
            },
            "sulfaatkarton-250": {
              "description": "Firm",
              "name": "Sulfate Cardboard, 250 gr"
            }
          }
        },
        "size": {
          "large": "Large",
          "medium": "Medium",
          "small": "Small",
          "xl": "XL"
        }
      },
      "countries": {
        "be": "Belgium",
        "de": "Germany",
        "nl": "Netherlands"
      }
    },
    "stylebook": {
      "block": "Block",
      "button": "Button",
      "card": "Card",
      "cats-craddle": "Cat's Craddle",
      "checkbox": "Checkbox",
      "color-picker": "Color picker",
      "colors": "Colors",
      "container": "Container",
      "content": "content",
      "cover": "cover",
      "custom": "Custom",
      "default": "Default",
      "disabled": "Disabled",
      "display-titles": "Display titles",
      "dropdown": "Dropdown",
      "dummy": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      "file": "File",
      "font-awesome": "Font Awesome",
      "heading": "Heading",
      "headings": "Headings",
      "icons": "Icons",
      "info": "Info",
      "input": "Input",
      "invalid": "Invalid",
      "lead": "Lead",
      "many-items": "Many items",
      "medium": "Medium",
      "minimal": "Minimal",
      "normal": "Normal",
      "outline": "Outline",
      "padded": "Padded",
      "pair": "Pair",
      "pill": "Pill",
      "placeholder": "Placeholder",
      "progress-bar": "Progress Bar",
      "prominent": "Prominent",
      "regular": "Regular",
      "regular-with-icons": "Regular with Icons",
      "segmented-button": "Segmented button",
      "shape": "Shape",
      "sizes": "Sizes",
      "sizes-with-icons": "Sizes with icons",
      "slider": "Slider",
      "small": "Small",
      "small-with-icons": "Small with Icons",
      "summary": "summary",
      "textarea": "Textarea",
      "this-is-boekdrukken": "This is Boekdrukken",
      "tiniest": "Tiniest",
      "tiniest-with-icons": "Tiniest with Icon",
      "tiny": "Tiny",
      "tiny-with-icons": "Tiny with Icons",
      "title": "Title",
      "tools": "Tools",
      "typography": "Typography",
      "upload": "upload",
      "validation": "Validation",
      "value": "Value",
      "warning": "Regular",
      "wip": "WIP"
    }
  };
  _exports.default = _default;
});