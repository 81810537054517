define("boekdrukken/services/auth-requirements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    storeTransition: function storeTransition(transition) {
      this.transition = transition;
    },
    retryStoredTransition: function retryStoredTransition() {
      var transition = this.transition;

      if (transition) {
        this.transition = null;

        if (typeof transition === 'function') {
          transition();
        } else {
          transition.retry();
        }

        return true;
      }

      return false;
    },
    transitionTo: function transitionTo(transition) {
      var _this$router;

      this.storeTransition(transition);

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      (_this$router = this.router).transitionTo.apply(_this$router, args);
    },
    validateIsAnonymous: function validateIsAnonymous(user) {
      if (!user.isAnonymous) {
        return 'denied';
      }

      return true;
    },
    validateIsNotAnonymous: function validateIsNotAnonymous(user) {
      if (!user) {
        return 'sign-in';
      }

      if (user.isAnonymous) {
        return 'link';
      }

      return true;
    },
    validateIsAuthenticated: function validateIsAuthenticated(user) {
      if (!user) {
        return 'sign-in';
      }

      return true;
    },
    validateIsNotAuthenticated: function validateIsNotAuthenticated(user) {
      if (user) {
        return false;
      }

      return true;
    },
    validateIsNotAuthenticatedWithLink: function validateIsNotAuthenticatedWithLink(user) {
      if (user && user.isAnonymous) {
        return 'link';
      }

      return this.validateIsNotAuthenticated(user);
    },
    validateIsAdmin: function validateIsAdmin(user) {
      if (!user) {
        return 'sign-in';
      }

      if (!user.isAdmin) {
        return 'denied';
      }

      return true;
    },
    validateIsDevops: function validateIsDevops(user) {
      if (!user) {
        return 'sign-in';
      }

      if (!user.isDevops) {
        return 'denied';
      }

      return true;
    },
    validate: function validate(route, requirement) {
      if (!requirement) {
        return true;
      }

      var name = "validateIs".concat(Ember.String.classify(requirement));
      var fn = this[name];
      (false && !(!!fn) && Ember.assert("auth requirement '".concat(name, "' is not declared"), !!fn));
      var user = this.store.user;

      for (var _len2 = arguments.length, args = new Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
        args[_key2 - 2] = arguments[_key2];
      }

      var result = fn.call.apply(fn, [this, user].concat(args));
      return result;
    }
  });

  _exports.default = _default;
});