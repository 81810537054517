define("boekdrukken/models/sketch/base/-attrs", ["exports", "ember-cli-sketch/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.attr = void 0;
  Object.defineProperty(_exports, "guidelines", {
    enumerable: true,
    get: function get() {
      return _computed.guidelines;
    }
  });
  _exports.position = _exports.node = void 0;
  Object.defineProperty(_exports, "prop", {
    enumerable: true,
    get: function get() {
      return _computed.prop;
    }
  });
  _exports.size = void 0;

  var node = function node(opts) {
    return (0, _computed.node)(Ember.assign({
      identifier: 'boekdrukken'
    }, opts));
  };

  _exports.node = node;

  var _attrs2 = (0, _computed.attrs)(function (target) {
    return "doc.data.".concat(target);
  }),
      attr = _attrs2.attr,
      position = _attrs2.position,
      size = _attrs2.size;

  _exports.size = size;
  _exports.position = position;
  _exports.attr = attr;
});