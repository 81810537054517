define("boekdrukken/components/ui-block/products/list/product/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var tool = function tool(key) {
    return "block.products.list.product.tools.".concat(key);
  };

  var _default = Ember.Component.extend({
    classNameBindings: [':product', 'selectable::disabled'],
    selectable: Ember.computed.readOnly('product.isFinalizedOrError'),
    // isProcessingOrFinalized
    tools: Ember.computed(function () {
      return Ember.A([{
        id: 'default',
        title: tool('default'),
        hidden: true
      }, {
        id: 'delete',
        title: tool('delete'),
        icon: 'trash',
        action: this.delete
      }]);
    }).readOnly(),
    isBook: Ember.computed.equal('product.type', 'book'),
    actions: {
      tool: function tool(_ref) {
        var action = _ref.action;
        action && action();
      },
      select: function select() {
        if (!this.selectable) {
          return;
        }

        this.select();
      }
    }
  });

  _exports.default = _default;
});