define("boekdrukken/components/ui-block/pdf/page/color-types/segmented-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gh4JHlaY",
    "block": "{\"symbols\":[\"block\",\"type\"],\"statements\":[[4,\"ui-block/segmented-button\",null,[[\"size\"],[[24,[\"size\"]]]],{\"statements\":[[4,\"each\",[[24,[\"page\",\"colorTypes\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"segment\"]],\"expected `block.segment` to be a contextual component but found a string. Did you mean `(component block.segment)`? ('boekdrukken/components/ui-block/pdf/page/color-types/segmented-button/template.hbs' @ L3:C6) \"],null]],[[\"caption\",\"selected\",\"action\"],[[28,\"if\",[[28,\"eq\",[[24,[\"size\"]],\"tiniest\"],null],[28,\"t\",[[23,2,[\"short\"]]],null],[28,\"t\",[[23,2,[\"caption\"]]],null]],null],[28,\"eq\",[[23,2,[]],[24,[\"page\",\"colorType\"]]],null],[28,\"action\",[[23,0,[]],[24,[\"select\"]],[23,2,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-block/pdf/page/color-types/segmented-button/template.hbs"
    }
  });

  _exports.default = _default;
});