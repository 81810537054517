define("boekdrukken/util/throttle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // https://github.com/ampatspell/index65/blob/master/app/util/throttle.js
  var _default = function _default(array, max, fn) {
    array = Ember.A(array.slice());
    var deferred = Ember.RSVP.defer();
    var errors = [];
    var running = Ember.A();

    var error = function error(err) {
      return errors.push(err);
    };

    var push = function push(promise) {
      return running.pushObject(promise);
    };

    var remove = function remove(promise) {
      return running.removeObject(promise);
    };

    var invoke = function invoke(task) {
      var promise = fn(task);
      push(promise);
      promise.then(function () {
        remove(promise);
        enqueue();
      }, function (err) {
        remove(promise);
        error(err);
        enqueue();
      });
    };

    var done = function done() {
      if (errors.length) {
        var err = new Error("Throttle failed with ".concat(errors.length, " error").concat(errors.length === 1 ? '' : 's'));
        err.code = 'throttle';
        err.errors = errors;
        deferred.reject(err);
      } else {
        deferred.resolve();
      }
    };

    var enqueue = function enqueue() {
      var task = array.shift();

      if (task !== undefined) {
        invoke(task);
      } else if (running.length === 0) {
        done();
      }
    };

    for (var i = 0; i < max; i++) {
      enqueue();
    }

    return deferred.promise;
  };

  _exports.default = _default;
});