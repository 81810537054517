define("boekdrukken/components/ui-block/product/book/step/cover/design/dev/component", ["exports", "boekdrukken/util/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-block-product-book-step-cover-design-dev', 'isDevelopmentBuild:visible:hidden'],
    isDevelopmentBuild: (0, _computed.isDevelopmentBuild)(),
    component: null,
    actions: {
      remove: function remove() {
        this.component.removeDesign();
      }
    }
  });

  _exports.default = _default;
});