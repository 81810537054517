define("boekdrukken/services/product-definitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var definitions = ['book', 'custom'];

  var _default = Ember.Service.extend({
    models: Ember.inject.service(),
    all: Ember.computed(function () {
      var models = this.models;
      return Ember.A(definitions.map(function (id) {
        return models.create("product/definition/".concat(id), {
          id: id
        });
      }));
    }).readOnly(),
    available: Ember.computed.filterBy('all', 'isAvailable', true),
    _byId: function _byId(array, id, opts) {
      var _Ember$assign = Ember.assign({
        optional: false
      }, opts),
          optional = _Ember$assign.optional;

      var definition = array.findBy('id', id);
      (false && !(definition || optional) && Ember.assert("product definition '".concat(id, "' was not found"), definition || optional));
      return definition;
    },
    availableById: function availableById(id, opts) {
      return this._byId(this.available, id, opts);
    },
    byId: function byId(id, opts) {
      return this._byId(this.all, id, opts);
    }
  });

  _exports.default = _default;
});